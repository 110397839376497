import React, {Fragment} from "react";
import ColorTagInlineBadge from "../badge/ColorTagInlineBadge";
import _ from "lodash";
import SmallGaugeChart from "../charts/SmallGaugeChart";
import {getColorByScore} from "../../helpers/cve";
import PropTypes from "prop-types";


const CveScoreGauge = ({baseScore, cvssVersion, baseSeverity}) =>
  <Fragment>
    <div className="card__title">
      <h4 className="bold-text">
        CVE Score
        <span className={'float-right'}>
            <ColorTagInlineBadge tag={baseSeverity} color={'secondary'} size={'sm'}/>
          </span>
      </h4>
      <p className="subhead">{cvssVersion}</p>
    </div>

    {
      baseScore === 0 || _.isNull(baseScore) || _.isUndefined(baseScore) ?
        <SmallGaugeChart value={0} color={'#ffffff'} label={baseScore}/> :
        <SmallGaugeChart
          value={baseScore * 10}
          color={getColorByScore(baseScore)}
          label={baseScore}
        />
    }
  </Fragment>


CveScoreGauge.propTypes = {
  baseScore: PropTypes.number.isRequired,
  cvssVersion: PropTypes.string.isRequired,
  baseSeverity: PropTypes.string.isRequired
}

export default CveScoreGauge;
