import React, {useCallback, useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';
import {INDICATOR_TABLE_COLUMNS} from "../table/IndicatorsTable";
import PropTypes from "prop-types";
import TableWithDataHookPagination from "../table/TableWithDataHookPagination";
import LoadingSpinner from "../LoadingSpinner";
import paths from "../../../config/paths";
import {useHistory} from "react-router-dom";
import {formatIocType} from "../../helpers";


const _ = require('lodash')


const INDICATOR_TABLE_CONFIG = {
  withPagination: true,
  withHover: true,
}


const IndicatorsTabWithPaginatedTables = ({dataHook, filters, iocTypes}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeIocType, setActiveIocType] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!_.isEmpty(iocTypes)) {
      setActiveIocType(_.keys(iocTypes)[0]);
    }
  }, [iocTypes])

  const toggleTab = useCallback((tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveIocType(_.keys(iocTypes)[tab]);
    }
  }, [iocTypes, activeTab])

  if (activeIocType === "") {
    return <LoadingSpinner />
  }

  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        <Nav tabs>
          {
            _.toPairs(iocTypes).map(([iocType, count], index) =>
              <NavItem key={iocType}>
                <NavLink
                  className={classnames({active: activeTab === index})}
                  onClick={() => toggleTab(index)}
                  key={iocType}
                >
                  {formatIocType(iocType)} ({count})
                </NavLink>
              </NavItem>
            )
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            _.keys(iocTypes).map((iocType, index) =>
              <TabPane tabId={index} key={index}>
                <TableWithDataHookPagination
                  tableColumns={INDICATOR_TABLE_COLUMNS}
                  tableConfig={INDICATOR_TABLE_CONFIG}
                  useDataQuery={dataHook}
                  queryParams={{...filters, ...{"ioc_type": activeIocType}}}
                  totalItems={iocTypes[activeIocType]}
                  onRowClick={(__, row) => {
                    if (!_.isUndefined(row.original.value) && !_.isNull(row.original.value)) {
                      history.push(`${paths.investigatePath}?indicator=${row.original.value}`);
                    }
                  }}
                />
              </TabPane>
            )
          }
        </TabContent>
      </div>
    </div>
  )
}

IndicatorsTabWithPaginatedTables.propTypes = {
  filters: PropTypes.object.isRequired,
  dataHook: PropTypes.func.isRequired,
}

export default IndicatorsTabWithPaginatedTables;
