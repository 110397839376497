import {Card, CardBody, Col, Row} from "reactstrap";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import React from "react";
import PropTypes from "prop-types";


const FirstLastSeenRow = ({first_seen, last_seen}) => {

  if (!last_seen && !first_seen) {
    return (<></>)
  } else {
    return (
      <Row>
        <Col>
          <Card>
            <CardBody className={"pb-0"}>
              <div className="card__title">
                <h4 className={'bold-text'}>{formatDateUTCtoYearMonthDayTime(first_seen)}</h4>
                <p className={'subhead text-uppercase'}>First Seen</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody className={"pb-0"}>
              <div className="card__title">
                <h4 className={'bold-text'}>{formatDateUTCtoYearMonthDayTime(last_seen)}</h4>
                <p className={'subhead text-uppercase'}>Last Seen</p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}


FirstLastSeenRow.propTypes = {
  first_seen: PropTypes.string.isRequired,
  last_seen: PropTypes.string.isRequired,
}


export default FirstLastSeenRow;
