import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CustomizerProps, ThemeProps, RTLProps } from '../../shared/prop-types/ReducerProps';
import {Toaster} from "react-hot-toast";

// const wrapperClass = (customizer) => {
//   classNames({
//     wrapper: true,
//     'squared-corner-theme': customizer.squaredCorners,
//     'blocks-with-shadow-theme': customizer.withBoxShadow,
//     'top-navigation': customizer.topNavigation,
//   });
// };


const MainWrapper = ({
  theme, rtl, location, children
}) => {

  return (
    <div className={`${theme.className} ${rtl.direction}-support`} dir={rtl.direction}>
      <Toaster position="top-right" toastOptions={{
        className: 'toast-default'
      }} />
      {/*<div className={wrapperClass(customizer)}>*/}
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
};


MainWrapper.propTypes = {
  // customizer: CustomizerProps.isRequired,
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  // fetchAppConfigAction: PropTypes.func.isRequired,
  // isFetching: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};


const mapStateToProps = (state) => {
  return ({
    theme: state.theme,
    rtl: state.rtl,
    // customizer: state.customizer,
    // isFetching: state.appConfig.isFetching,
  });
};


export default withRouter(connect(mapStateToProps)(MainWrapper));
