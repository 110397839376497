import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const RuleTypesFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/rule_types`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useRuleTypesQuery = (fetcher = RuleTypesFetcher) => {
  return useQueryWithAuthentication(
    ['rule-types'],
    (accessToken) => fetcher(accessToken),
    {cacheTime: Infinity, staleTime: Infinity}
  )
}
