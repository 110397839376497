import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SubmitRetroHuntTaskFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/retrohunt/tasks`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useSubmitRetroHuntTask = (fetcher = SubmitRetroHuntTaskFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-retrohunt-tasks'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('add-retrohunt-tasks')}
  )
}
