import {useD3} from './useD3';
import React, {useState} from 'react';
import * as d3 from 'd3';
import PropTypes from "prop-types";
import {NODE_TOOLTIP_CLASS, SEC_INDICATOR} from "../../helpers/node-chart";


const NodeChart = ({data, getTooltipInfo, canIShowToolTip}) => {
  let nodes = [...data[0]];
  let links = [...data[1]];
  const width = 820;
  const height = 310;
  let linkElements, nodeElements, textElements;
  let linkGroup, nodeGroup, textGroup;
  let linkForce, simulation, dragDrop;
  let tooltip;
  let selectedId;
  let g;
  const [selectedNode, setSelectedNode] = useState(null);

  const selectNode = (event, node) => {
    if (selectedId === node.id && tooltip.style('display') === 'block' || !node) {
      selectedId = undefined;
      setSelectedNode(null);
      return tooltip.style('display', 'none');
    } else if (node && node.id != null) {
      selectedId = node.id;
      setSelectedNode(node);
      if (canIShowToolTip(node)) tooltip.style('display', 'block');
    }
  }

  const updateGraph = () => {

    // links
    linkElements = linkGroup.selectAll('line')
      .data(links, function (link) {
        return link.target.id + link.source.id
      })

    linkElements.exit().remove()

    const linkEnter = linkElements
      .enter().append('line')
      .attr('stroke-width', 1)
      .attr('stroke', 'white')

    linkElements = linkEnter.merge(linkElements)

    // nodes
    nodeElements = nodeGroup.selectAll('g')
      .data(nodes, function (node) {
        return node.id
      })


    nodeElements.exit().remove()

    const nodeEnter = nodeElements
      .enter()
      .append('g')
      .call(dragDrop)
      .on('click', function (event, node) {
        selectNode(event, node)
      })
      .on("mouseover", function (d, node) {
        if (canIShowToolTip(node)) d.target.style.cursor = 'pointer';
      })

    nodeEnter.append("circle")
      .attr("r", function (d) {
        return d.id == SEC_INDICATOR ? 15 : 10;
      })
      .attr("cx", 12)
      .attr("cy", 12)
      .attr("fill", '#333639')

    nodeEnter.append("path")
      .attr("fill", '#fff')
      .attr("d", function (d) {
        return d.icon ? d.icon : 'M14,7V9H13V15H14V17H10V15H11V9H10V7H14ZZ';
      })

    nodeElements = nodeEnter.merge(nodeElements)
 
    // texts
    textElements = textGroup.selectAll('text')
      .data(nodes, function (node) {
        return node.id
      })

    textElements.exit().remove()

    const textEnter = textElements
      .enter()
      .append('text')
      .text(function (node) {
        return node.label
      })
      .attr('font-size', 15)
      .attr('dx', 15)
      .attr('fill', "#fff")
      .attr('dy', 4);

    textElements = textEnter.merge(textElements)
  }

  const updateSimulation = () => {
    updateGraph()
    simulation.nodes(nodes).on('tick', () => {
      nodeElements
        .attr('x', function (node) {
          return node.x
        })
        .attr('y', function (node) {
          return node.y
        })
        .attr('transform', function (d) {
          return "translate(" + (d.x - 12) + "," + (d.y - 12) + ")";
        })
      textElements
        .attr('x', function (node) {
          return node.x
        })
        .attr('y', function (node) {
          return node.y
        })
      linkElements
        .attr('x1', function (link) {
          return link.source.x
        })
        .attr('y1', function (link) {
          return link.source.y
        })
        .attr('x2', function (link) {
          return link.target.x
        })
        .attr('y2', function (link) {
          return link.target.y
        })
    })

    simulation.force('link').links(links)
  }

  const zoom_actions = (event) => {
    g.attr("transform", event.transform)
  }

  const ref = useD3(
    (svg) => {

      document.addEventListener('click', function (e) {
        const container = document.querySelector(NODE_TOOLTIP_CLASS);
        // if the target of the click isn't the container nor a descendant of the container
        if (
          container && !container.isEqualNode(e.target) &&
          !document.querySelector('.nodes').contains(e.target) && !container.contains(e.target) && tooltip.style('display') === 'block'
        ) {
          tooltip.style('display', 'none');
        }
      });

      g = svg.append("g").attr("class", "everything");

      svg.attr('width', '100%').attr('height', '100%');

      linkGroup = g.append('g').attr('class', 'links')
      nodeGroup = g.append('g').attr('class', 'nodes')
      textGroup = g.append('g').attr('class', 'texts')

      linkForce = d3
        .forceLink()
        .id(function (link) {
          return link.id
        })
        .distance(function (link) {
          return link.distance ? link.distance : 125
        })
        .strength(function (link) {
          return link.strength
        })

      simulation = d3
        .forceSimulation()
        .force('link', linkForce)
        .force('charge', d3.forceManyBody().strength(-250))
        .force('center', d3.forceCenter(width / 2, height / 2))

      dragDrop = d3.drag().on('start', function (node) {
        node.fx = node.x
        node.fy = node.y
      }).on('drag', function (event, node) {
        simulation.alphaTarget(0.7).restart()
        node.fx = event.x
        node.fy = event.y
      }).on('end', function (event, node) {
        simulation.alphaTarget(0)
        node.fx = null
        node.fy = null
      })

      const zoom_handler = d3.zoom()
        .scaleExtent([0.5, 5])
        .on("zoom", zoom_actions);

      tooltip = d3.select(NODE_TOOLTIP_CLASS);

      d3.select("#node_zoom_in").on("click", function () {
        zoom_handler.scaleBy(svg.transition().duration(750), 1.2);
        tooltip.style('display', 'none');
      });

      d3.select("#node_zoom_out").on("click", function () {
        zoom_handler.scaleBy(svg.transition().duration(750), 0.8);
        tooltip.style('display', 'none');
      });

      zoom_handler.scaleTo(svg, 0.5)
      zoom_handler.translateBy(svg, 5, 5)
      zoom_handler(svg);

      updateSimulation()
     
     },
    [data.length]
  );

  return (
    <>
      <div
        className='tt_template'
        id="node_tool_tip"
      >
        {
          selectedNode && getTooltipInfo(selectedNode)
        }
      </div>
      <div className='btn_actions'>
        <button className='btn btn-secondary' id="node_zoom_in">+</button>
        <button className='btn btn-secondary' id="node_zoom_out">-</button>
      </div>
      <svg viewBox="0 0 900 350" ref={ref}></svg>
    </>
  );
}

NodeChart.propTypes = {
  data: PropTypes.shape().isRequired,
  getTooltipInfo: PropTypes.func.isRequired,
  canIShowToolTip: PropTypes.func.isRequired
}

export default NodeChart;