import React from 'react'
import PropTypes from "prop-types";
import getTooltipStyles from "../../helpers";
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {connect} from 'react-redux';
import LoadingSpinner from "../LoadingSpinner";


const VerticalBarChart = ({
  data,
  dataKey,
  labelDataKey,
  isLoading = false,
  isError = false,
  dir,
  height = 400,
  legendLabel = 'Hits',
  onBarClick = null,
  barColors = ["#40e5e8"],
  themeName
}) => {

  if (isError) {
    return <p>ERROR WHILE FETCHING DATA!</p>
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <ResponsiveContainer height={height}>
      <BarChart
        margin={{left: 50, right: 50}}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis
          type="category"
          dataKey={labelDataKey}
          tickLine={false}
        />
        <YAxis
          type="number"
        />
        <Tooltip
          {...getTooltipStyles(themeName, 'defaultItems')}
          formatter={(value, name, props) => [value, legendLabel,]}
        />
        <Bar
          dataKey={dataKey}
          barSize={20}
          onClick={(item, value) => {
            if (onBarClick) onBarClick(item, value)
          }}
        >
          {data.map((entry, index) =>
            <Cell cursor={'pointer'} key={`cell-${index}`} fill={barColors[index % barColors.length]}/>)
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

VerticalBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.array.isRequired,
  labelDataKey: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  height: PropTypes.number,
  legendLabel: PropTypes.string,
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
  onBarClick: PropTypes.func,
}

export default connect(state => ({themeName: state.theme.className}))(VerticalBarChart);