import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteUsersFetcher = (uid, accessToken) => {
  return axios.delete(`${apiUrlInternal}/users/${uid}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}

export const useDeleteUsers = (fetcher = DeleteUsersFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-user'],
    (accessToken, uid) => fetcher(uid, accessToken),
    {onSuccess: () => queryClient.invalidateQueries('delete-user')}
  )
}
