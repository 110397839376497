import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteResourceMonitorProjectFetcher = (accessToken, projectUid) => {
  return axios.delete(
    `${apiUrlInternal}/resource_monitor_projects/${projectUid}`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useDeleteResourceMonitorProject = (fetcher = DeleteResourceMonitorProjectFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-organization-resource-monitor-project'],
    (accessToken, projectUid) => fetcher(accessToken, projectUid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('delete-organization-resource-monitor-project');
      }
    }
  )
}
