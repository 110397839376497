import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";
import { addAuthToFetchOpts } from "../config/queryopts";

export const AddOrganizationLogoFetcher = (organizationUid, params,accessToken) => {
  return axios.post(`${apiUrlInternal}/organizations/${organizationUid}/logo`, params
  , addAuthToFetchOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0)).then(res => res.data);
}

export const useAddOrganizationLogoMutation = (fetcher = AddOrganizationLogoFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-organization-logo'],
    (accessToken,{organizationUid,params}) => fetcher(organizationUid,params,accessToken),
    {
        onSuccess: () => {
            queryClient.invalidateQueries('add-organization-logo')
            queryClient.invalidateQueries(['users-me'])
        }
    }
  )
}
