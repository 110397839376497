import {Col, Row} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";


const LegendItem = ({style}) => {
  return (
    <Row style={style} className={'align-items-center'}>
      <Col md={1} xs={1}><h5 className={'bold-text'}>TYPE</h5></Col>
      <Col md={4} xs={4}>
        <Row>
          <Col md={3}></Col>
          <Col md={9} className={'d-inline justify-content-start'}>
            <h5 className={'bold-text'}>VALUE</h5>
          </Col>
        </Row>
      </Col>
      <Col md={2} xs={2}><h5 className={'bold-text'}>LAST UPDATED</h5></Col>
      <Col md={1} xs={1}><h5 className={'bold-text'}>ACTORS</h5></Col>
      <Col md={2} xs={2}><h5 className={'bold-text'}>MALWARE FAMILIES</h5></Col>
      <Col md={2} xs={2}><h5 className={'bold-text'}>TAGS</h5></Col>
    </Row>
  )
}

LegendItem.propTypes = {
  style: PropTypes.shape().isRequired
}

export default LegendItem;
