import React, {Fragment} from "react";
import _ from "lodash";
import {AnalysisArrayTypes, getFormattedAnalysisName, getPostTitle} from "./contents";
import {BOT, SELLER, THREAD} from "./validators";
import PropTypes from "prop-types";
import HighlightSpan from "../components/span/HighlightSpan";
import {getBaseScore, getColorByScore} from "./cve";


export const getNotificationMessage = (notification) => {
  const content = notification.content;

  const getMessage = (type, content) => {
    return (<span>
      New <HighlightSpan text={getFormattedAnalysisName(type)}/> {content.title}.
    </span>)
  }

  if (notification.source === 'darknet') {
    const type = content.raw.type;

    if (type === BOT) {
      return (
        <span>
          New bot found for <HighlightSpan text={content.keyword.value}/> in <HighlightSpan
          text={content.raw.forum_name}/> forum.
        </span>
      )
    } else if (type === SELLER) {
      return (
        <span>
          New account selling for <HighlightSpan text={content.keyword.value}/> in <HighlightSpan
          text={content.raw.account_seller}/> forum.
        </span>
      )
    } else if (type === THREAD) {
      return (
        <span>
          Keyword <HighlightSpan text={content.keyword.value}/> found in <HighlightSpan text={content.raw.forum_name}/> forum.
        </span>
      )
    } else {
      return (
        <span>
          New match in <HighlightSpan
          text={'darksearch'}/> found with no information available for keyword <HighlightSpan
          text={content.keyword.value}/>
        </span>
      )
    }
  } else if (notification.source === 'botnet') {
    return (
      <span>
        New <HighlightSpan text={notification.source}/> found for <HighlightSpan text={content.keyword.value}/>.
        IP address <HighlightSpan text={content.ip}/> compromised by <HighlightSpan text={content.source}/>.
      </span>
    )
  } else if (notification.source === 'credential_exp') {
    return (
      <span>
        New credential compromised found for <HighlightSpan text={content.keyword.value}/> in <HighlightSpan
        text={content.url}/>
      </span>
    )
  } else if (notification.source === 'analysis') {
    return (
      <span>
        New <HighlightSpan text={'Analysis'}/> about actor{content?.actors.length > 1 ? 's' : ''} <HighlightSpan text={
        content.actors ? _.join(content.actors.map((actor) => actor.name.toUpperCase()), ', ') : 'Unknown'
      }/>. {content.title}.
      </span>
    )
  } else if (notification.source === 'post') {
    return (
      <span>
        New <HighlightSpan text={'Post'}/> about actor{content?.actors.length > 1 ? 's' : ''} <HighlightSpan text={
        content.actors ? _.join(content.actors.map((actor) => actor.name.toUpperCase()), ', ') : 'Unknown'
      }/>. {getPostTitle(content)}.
      </span>
    )
  } else if (notification.source === 'intel') {
    return (
      <span>
        New match found for keyword <HighlightSpan text={content.keyword.value}/> in
        {content.url ?
          <Fragment> url <HighlightSpan text={content.url}/></Fragment> :
          <Fragment> file <HighlightSpan text={content.name}/></Fragment>
        }
     </span>
    )
  } else if (notification.source === 'resource_monitor') {
    const [baseScore, ,] = getBaseScore(content.cve.nvd_info.impact);

    return (
      <span>Host <HighlightSpan text={content.host}/> is vulnerable to <span
        style={{color: getColorByScore(baseScore)}}>{content.cve.cve}</span></span>
    )
  } else if (notification.source === 'ransomware_tracker') {
    return (
      <span> New Ransomware Event: <HighlightSpan text={content.actor_name}/> attacks <HighlightSpan
        text={content.victim}/></span>
    )
  } else if (notification.source === 'news_feeds') {
    return (
      <span> New Feed: <HighlightSpan text={content.article_title}/></span>
    )
  } else if (notification.source === 'typosquatting') {
    return (
      <span> New fraud domain <HighlightSpan text={content.domain_name}/> for <HighlightSpan
        text={content.keyword.keyword}/></span>
    )
  } else if (AnalysisArrayTypes.map((t) => t.value).includes(notification.source)) {
    return getMessage(notification.source, content);
  } else if (notification.source === 'activity') {
    return (
        <span> New {content.category} Activity: <HighlightSpan text={content.title}/></span>    )
  }

  return notification.source;
}


export const NotificationSummary = ({source, total}) => {
  const getMessage = (type) => {
    return (<span>
      There {total > 1 ? 'are' : 'is'} <HighlightSpan text={total}/> new <HighlightSpan
      text={getFormattedAnalysisName(type) + `${total > 1 ? 's' : ''}`}/> published.
    </span>)
  }
  if (source === 'darknet') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan
        text={total}/> new result{total > 1 ? 's' : ''} for the <HighlightSpan
        text={'darksearch'}/> module.
      </span>
    )
  } else if (source === 'botnet') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan text={total}/> new <HighlightSpan
        text={`botnet${total > 1 ? 's' : ''}`}/> host{total > 1 ? 's' : ''} compromised.
      </span>
    )
  } else if (source === 'credential_exp') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan text={total}/> new <HighlightSpan
        text={`compromised credential${total > 1 ? 's' : ''}`}/>.
      </span>
    )
  } else if (source === 'analysis') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan text={total}/> new <HighlightSpan text={'analysis'}/> published.
      </span>
    )
  } else if (source === 'post') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan text={total}/> new <HighlightSpan
        text={`post${total > 1 ? 's' : ''}`}/> published.
      </span>
    )
  } else if (source === 'intel') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan
        text={total}/> new result{total > 1 ? 's' : ''} for the <HighlightSpan
        text={'intelligence'}/> module.
      </span>
    )
  } else if (source === 'resource_monitor') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan
        text={total}/> new result{total > 1 ? 's' : ''} for the <HighlightSpan
        text={'Vulnerability Monitor'}/> module.
      </span>
    )
  } else if (source === 'ransomware_tracker') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan
        text={total}/> new result{total > 1 ? 's' : ''} for the <HighlightSpan
        text={'Ransomware Tracker'}/> module.
      </span>
    )
  } else if (source === 'news_feeds') {
    return (
      <span>
        There {total > 1 ? 'are' : 'is'} <HighlightSpan
        text={total}/> new result{total > 1 ? 's' : ''} for the <HighlightSpan
        text={'News Feed'}/> module.
      </span>
    )
  } else if (AnalysisArrayTypes.map((t) => t.value).includes(source)) {
    return getMessage(source);
  } else if (source === 'typosquatting') {
    return (
      <span> There are <HighlightSpan text={`${total} new domain${total > 1 ? 's' : ''}`}/>.</span>
    )
  } else if (source === 'activity') {
    return (
        <span> There are <HighlightSpan text={`${total} new ${total > 1 ? 'activities' : 'activity'}`}/>.</span>
    )
  }
  return source;
}

NotificationSummary.propTypes = {
  total: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired
}
