import {Button, Col, Row} from "reactstrap";
import CloseIcon from "mdi-react/CloseIcon";
import ControlledSelectWithTitle from "../../ControlledSelectWithTitle";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useFieldArray} from "react-hook-form";
import FileRelationshipForm from "./FileRelationshipForm";


const RelationshipsForm = ({control, errors, watch, setValue}) => {
  const { fields: fieldsRelationships, append: appendRelationships, remove: removeRelationships } = useFieldArray(
    {control, name: "relationships"}
  );

  const relationshipOptions = [{value: 0, label: 'File'}];
  return (
    <Fragment>
      {
        fieldsRelationships.map((item, index) =>
          <Row className={'w-100'} key={item.id}>
            <Col md={3} lg={3} xl={2}>
              <Row>
                <Col md={2} className={'d-flex flex-column justify-content-center'}>
                  <CloseIcon
                    style={{fill: 'white', cursor: 'pointer'}}
                    onClick={() => removeRelationships(index)}
                  />
                </Col>
                <Col md={10}>
                  <ControlledSelectWithTitle
                    name={`relationships.${index}.type`} title={'Type'} control={control}
                    options={relationshipOptions} defaultValue={relationshipOptions[0]}
                    valueFn={(value) => relationshipOptions.find(c => c.value === value?.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={9} lg={9} xl={10} className={'d-flex'}>
              {
                watch(`relationships.${index}.type`)?.value === 0 ?
                  <FileRelationshipForm control={control} watch={watch} formIndex={index} errors={errors} setValue={setValue} /> :
                  <p>OPTION NOT VALID</p>
              }
            </Col>
          </Row>
        )
      }
      {
        errors.relationships && errors.relationships.message &&
        <span className="form__form-group-error">{errors.relationships.message}</span>
      }
      <Row className={'justify-content-end w-100'}>
        <Button outline color={'success'} onClick={() => appendRelationships({type: relationshipOptions[0]})}>
          Add relationship
        </Button>
      </Row>
    </Fragment>
  )
}


RelationshipsForm.propsType = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}


export default RelationshipsForm;
