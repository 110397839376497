import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const AddGeneAnalysisFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/genes_analysis`, params, addAuthToFetchOpts(accessToken, {}, 120000)).then(res => res.data);
}

export const useAddGeneAnalysisMutation = (fetcher = AddGeneAnalysisFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['add-gene-analysis'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('add-gene-analysis')}
  )
}
