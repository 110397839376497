import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const IndicatorTypesFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/indicator_types`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useIndicatorTypesQuery = (fetcher = IndicatorTypesFetcher) => {
  return useQueryWithAuthentication(
    ['indicator-types'],
    (accessToken) => fetcher(accessToken),
    {cacheTime: Infinity, staleTime: Infinity}
  )
}
