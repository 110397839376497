import { useStatsTagsMostCommon } from "../../../../queries/Stats";
import { Card, CardBody } from "reactstrap";
import React from "react";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import { useHistory } from "react-router-dom";
import paths from "../../../../config/paths";
import ThemedTagCloud from "../../../../shared/components/charts/ThemedTagCloud";
import PropTypes from "prop-types";
import {getCommonTags} from "../../../../shared/helpers";


const MostCommonTagsWordCloud = ({
  tagsLimit
}) => {
  const history = useHistory();
  const { data, isIdle, isLoading, isError } = useStatsTagsMostCommon(tagsLimit);

  return (
    <Card>
      <CardBody>
        <div className="card__title mb-0">
          <h4 className={'bold-text d-inline'}>MOST COMMON TAGS</h4>
        </div>
        {
          isError ? <p>ERROR WHILE FETCHING DATA!</p> :
            isIdle || isLoading ? <LoadingSpinner /> :
              <ThemedTagCloud
                words={getCommonTags(data.data).map((tag) => (
                  {
                    text: tag.tag.charAt(0).toUpperCase() + tag.tag.slice(1),
                    value: tag.num_indicators
                  }
                ))}
                onWordClick={(tag) => history.push(`${paths.indicatorPath}?tags=${tag}`)}
              />
        }
      </CardBody>
    </Card>
  )
}


MostCommonTagsWordCloud.propTypes = {
  tagsLimit: PropTypes.number.isRequired
}

export default MostCommonTagsWordCloud;