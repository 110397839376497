import {Card, CardBody} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {downloadFormats, downloadIndicators} from "../../helpers/download_file";
import DropdownMenuExportFormats from "../form/DropdownMenuExportFormats";
import LoadingSpinner from "../LoadingSpinner";
import IndicatorsTabWithPaginatedTables from "../tab/IndicatorsTabWithPaginatedTables";
import _ from "lodash";
import DropdownMenuExportAutogeneratedRules from "../form/DropdownMenuExportAutogeneratedRules";
import DropdownMenuExportSIEMRules from "../form/DropdownMenuExportSIEMRules";
import {SIEM_INDICATORS} from "../../helpers/siem";


const IndicatorsTabWithTablePaginationCard = ({
  dataHookCount,
  dataHook,
  uid,
  filters,
  title = 'Indicators',
  withExportButton = false,
  exportFilters = {},
  withExportdRules = false,
  customRuleText= ''
}) => {
  const {data, isIdle, isLoading, isError} = dataHookCount(uid);
  const [iocTypes, setIocTypes] = useState({});

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      const tmp = {};
      _.forEach(data.data, (ioc) => {
        if (ioc.num_indicators > 0) {
          tmp[ioc.type] = ioc.num_indicators;
        }
      });
      setIocTypes(tmp);
    }
  }, [isIdle, isLoading, isError, data])


  if (isIdle || isLoading) {
    return (
      <Card><CardBody>
        <div className="card__title"><h4 className={'bold-text d-inline'}>{title}</h4><LoadingSpinner/></div>
      </CardBody></Card>
      )
  }

  if (isError) {
    return (
      <Card><CardBody>
        <div className="card__title"><h4 className={'bold-text d-inline'}>{title}</h4><h3>Unable to load the content</h3></div>
      </CardBody></Card>
    )
  }

  if (_.sum(_.values(iocTypes)) === 0) {
    return <Fragment />
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>{title}</h4>
          {
            _.sum(_.values(iocTypes)) > 0 && withExportButton &&
              <DropdownMenuExportFormats
                downloadDataHook={downloadIndicators}
                downloadFormats={downloadFormats}
                filters={exportFilters}
              />
          }
          {
            (iocTypes.ipv4 || 0 + iocTypes.domain || 0) > 0 && withExportdRules &&
              <DropdownMenuExportAutogeneratedRules
                filters={exportFilters}
                totalIp={iocTypes.ipv4 || 0}
                totalDomain={iocTypes.domain || 0}
                customRuleText={customRuleText}
              />
          }
          {
            withExportdRules &&
              <DropdownMenuExportSIEMRules
                filters={exportFilters}
                totalIoc={_.sum(SIEM_INDICATORS.map((type) => iocTypes[type]))}
              />
          }
        </div>
        <IndicatorsTabWithPaginatedTables dataHook={dataHook} filters={filters} iocTypes={iocTypes} />
      </CardBody>
    </Card>
  )
}


IndicatorsTabWithTablePaginationCard.propsType = {
  dataHookCount: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  withExportButton: PropTypes.bool,
  title: PropTypes.string,
  exportFilters: PropTypes.object,
  withExportdRules: PropTypes.bool,
  customRuleText: PropTypes.string
}

export default IndicatorsTabWithTablePaginationCard;
