import React, {useEffect, useState} from "react";
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {useNotificationTypes, useNotificationUnreadSummaryByUser} from "../../queries/Notification";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import ErrorHandler from "../../shared/components/ErrorHandler";
import classnames from "classnames";
import UnreadNotificationTab from "./components/UnreadNotificationTab";
import ReadNotificationTab from "./components/ReadNotificationTab";
import _ from "lodash";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../shared/helpers";
import {useQueryClient} from "react-query";
import {useSetAllNotificationRead} from "../../mutations/SetAllNotificationRead";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const Notification = () => {
  const {data, isIdle, isLoading} = useCurrentUserQuery();
  const {data: dataNotTypes, isIdle: isIdleNotTypes, isLoading: isLoadingNotTypes} = useNotificationTypes();
  const [activeTab, setActiveTab] = useState(0);
  const [userUid, setUserUid] = useState('');
  const queryClient = useQueryClient();
  const mutation = useSetAllNotificationRead();
  const [sourcesRead, setSourcesRead] = useState([]);
  const [sourcesUnread, setSourcesUnread] = useState([]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const {
    data: dataNot,
    isIdle: isIdleNot,
    isLoading: isLoadingNot,
    isError: isErrorNot,
    error
  } = useNotificationUnreadSummaryByUser(data?.data?.uid);


  useEffect(() => {
    if (!isIdle && !isLoading && data) {
      setUserUid(data.data.uid);
    }
  }, [isLoading, isIdle, data])

  useEffect(() => {
    if (!isIdleNotTypes && !isLoadingNotTypes && dataNotTypes) {
      setSourcesRead(dataNotTypes.data);
    }
  }, [isIdleNotTypes, isLoadingNotTypes, dataNotTypes]);

  useEffect(() => {
    if (!isIdleNot && !isLoadingNot && dataNot) {
      setSourcesUnread(
        _.entries(dataNot.data.summary).filter(val => val[1] > 0).map(val => val[0])
      );
    }
  }, [isIdleNot, isLoadingNot, dataNot]);


  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        queryClient.invalidateQueries(['notifications', `user/${userUid}`, null]);
        queryClient.invalidateQueries(['notifications', `user/${userUid}/unread`, null]);
        queryClient.invalidateQueries(['notifications', `user/${userUid}/unread/summary`, null]);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])


  if (isErrorNot) {
    return <ErrorHandler error={error}/>
  }

  if (isIdle || isLoading || isIdleNot || isLoadingNot || isIdleNotTypes || isLoadingNotTypes) {
    return <LoadingSpinnerPage/>
  }

  return (
    <Page
      onlyOnline
    >
      <Container className={'container__notification'}>
        <Row>
          <Col md={9}>
            <h3 className="page-title">Notifications</h3>
          </Col>
        </Row>

        <div className="tabs tabs--bordered-bottom tabs__notification">
          {
            dataNot.data.total > 0 &&
            <button
              onClick={() => {
                toast.loading('Marking all notification as read');
                mutation.mutate(userUid);
              }}
              className={"topbar__collapse-button button__notification-mark-all-as-read"}
              type="button"
            >
              Mark all as read
            </button>
          }
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({active: activeTab === 0})}
                  onClick={() => toggleTab(0)}
                >Unread Notification ({dataNot.data.total})</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({active: activeTab === 1})}
                  onClick={() => toggleTab(1)}
                >All Notification</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={0}>
                <UnreadNotificationTab
                  user_uid={userUid}
                  notification_sources={sourcesUnread}
                />
              </TabPane>
              <TabPane tabId={1}>
                <ReadNotificationTab
                  user_uid={userUid}
                  notification_types={sourcesRead}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Container>
    </Page>
  )
}


export default Notification;
