import React from "react";
import PropTypes from "prop-types";
import {formatDateUTCtoMonthDayTime} from "../../../shared/helpers/date";
import {getBaseScore, getColorByScore} from "../../../shared/helpers/cve";


const ResourceMonitorResultCollapseHeader = ({vuln}) => {
  const cve_info = vuln.nvd_info;
  const [baseScore, cvssVersion, ] = getBaseScore(cve_info.impact);
  const color = getColorByScore(baseScore)

  return (
    <p>
      <span style={{color: color}}>{vuln.cve}</span> with {cvssVersion} score <span style={{color: color}}>{baseScore}</span>
      <span className={'float-right mr-2'}>
        {formatDateUTCtoMonthDayTime(vuln.created_dt)}
      </span>
    </p>
  )
}


ResourceMonitorResultCollapseHeader.propTypes = {
  vuln: PropTypes.object.isRequired
}


export default ResourceMonitorResultCollapseHeader;
