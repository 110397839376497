import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import ControlledSelectWithTitle from "../../ControlledSelectWithTitle";
import ControlledMultiSelectTextWithTitle from "../../ControlledMultiSelectTextWithTitle";


const _ = require('lodash');


const FileRelationshipForm = ({control, watch, errors, formIndex, setValue}) => {
  const indicators = watch('indicators');
  const hashesOptions = {
    'md5': _.filter(indicators, (val) => val.type?.label.includes('md5') && val.value),
    'sha1': _.filter(indicators, (val) => val.type?.label.includes('sha1') && val.value),
    'sha256': _.filter(indicators, (val) => val.type?.label.includes('sha256') && val.value),
  }

  for (const [key, value] of Object.entries(hashesOptions)) {
    const keyValues = _.concat(
      [hashesOptions[key].length === 0 ? `Insert a ${key.toUpperCase()}` : 'Select an option'],
      _.flatten(value.map((val) => val.value.split('\n').map((splittedVal) => splittedVal))))

    hashesOptions[key] = keyValues.map((label, index) => ({value: index, label: label}))
  }

  return (
    <Fragment>
      <Col md={12}>
        <Row>
          <Col md={3}>
            <ControlledSelectWithTitle
              name={`relationships.${formIndex}.md5`} title={'MD5'} control={control} isSearchable
              valueFn={(value) => {
                const currentValue = watch(`relationships.${formIndex}.md5`);
                if (_.find(errors?.indicators, (v1) => v1?.value?.indicator === currentValue.label)) {
                  setValue(`relationships.${formIndex}.md5`, hashesOptions.md5[0])
                }
                return hashesOptions.md5.length === 1
                  ? hashesOptions.md5[0] : hashesOptions.md5.find((c) => c.value === value?.value)
              }}
              options={hashesOptions.md5} isDisabled={hashesOptions.md5.length === 1}
              defaultValue={hashesOptions.md5[0]}
              error={errors?.relationships && errors.relationships[formIndex] ? errors.relationships[formIndex]?.md5 : null}
            />
          </Col>

          <Col md={3}>
            <ControlledSelectWithTitle
              name={`relationships.${formIndex}.sha1`} title={'SHA1'} control={control} isSearchable
              valueFn={(value) => {
                const currentValue = watch(`relationships.${formIndex}.sha1`);
                if (_.find(errors?.indicators, (v1) => v1?.value?.indicator === currentValue.label)) {
                  setValue(`relationships.${formIndex}.sha1`, hashesOptions.sha1[0])
                }
                return hashesOptions.sha1.length === 1
                  ? hashesOptions.sha1[0] : hashesOptions.sha1.find((c) => c.value === value?.value)
              }}
              options={hashesOptions.sha1} isDisabled={hashesOptions.sha1.length === 1}
              defaultValue={hashesOptions.sha1[0]}
              error={errors?.relationships && errors.relationships[formIndex] ? errors.relationships[formIndex]?.sha1 : null}
            />
          </Col>

          <Col md={3}>
            <ControlledSelectWithTitle
              name={`relationships.${formIndex}.sha256`} title={'SHA256'} control={control} isSearchable
              valueFn={(value) => {
                const currentValue = watch(`relationships.${formIndex}.sha256`);
                if (_.find(errors?.indicators, (v1) => v1?.value?.indicator === currentValue.label)) {
                  setValue(`relationships.${formIndex}.sha256`, hashesOptions.sha256[0])
                }
                return hashesOptions.sha256.length === 1
                  ? hashesOptions.sha256[0] : hashesOptions.sha256.find((c) => c.value === value?.value)
              }}
              options={hashesOptions.sha256} isDisabled={hashesOptions.sha256.length === 1}
              defaultValue={hashesOptions.sha256[0]}
              error={errors?.relationships && errors.relationships[formIndex] ? errors.relationships[formIndex]?.sha256 : null}
            />
          </Col>

          <Col md={3}>
            <ControlledMultiSelectTextWithTitle
              name={`relationships.${formIndex}.filenames`} title={'File names'} control={control} valueFn={(value) => value}
              error={errors?.relationships && errors.relationships[formIndex] ? errors.relationships[formIndex]?.filenames : null}
            />
          </Col>
        </Row>
        <Row className={'mb-3 justify-content-end'}>
          {
            errors.relationships && errors.relationships[formIndex] && errors.relationships[formIndex].form
            && <span className="form__form-group-error">{errors.relationships[formIndex].form.message}</span>
          }
        </Row>
      </Col>
    </Fragment>
  )
}

FileRelationshipForm.propsType = {
  control: PropTypes.shape().isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  formIndex: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
}

export default FileRelationshipForm;
