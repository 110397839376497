import React, { useEffect, useState } from "react";
import { useCveDetailQuery } from "../../../queries/CveDetails";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import CveDetails from "../../InvestigateDetail/components/IndicatorsDetailTypes/CveDetails";
import PropTypes from "prop-types";
import { Button, Col, Container } from "reactstrap";

const ResourseMonitorDetail = ({id,clearCve}) => {

  const {data, isIdle, isLoading,isError, error} = useCveDetailQuery(id);
  const [cveDetail, setCveDetail] = useState({});

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setCveDetail(data.data);
    }
  }, [isIdle, isLoading, isError, data])


  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (isLoading || isIdle || !cveDetail) {
    return <LoadingSpinner />
  }

  return (
    cveDetail && cveDetail.indicator ? 
    <>
      <h2>{id}</h2>
      <h3 
        className="page-subhead subhead cursor"
        style={{'cursor':'pointer','max-width':'120px'}}
        onClick={() => clearCve()}
      >
          Back to CVE list
    </h3>
    <CveDetails 
      showFooter={true} 
      indicator_details={cveDetail} 
    />  
    </>
    : 
    <p className="center"> data not found</p>
  );

}


ResourseMonitorDetail.propTypes = {
  id: PropTypes.string.isRequired,
  clearCve:PropTypes.func.isRequired
}

export default ResourseMonitorDetail;
