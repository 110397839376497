import TimeLineChartCard from "../../../shared/components/charts/TimeLineChartCard";
import React from "react";
import PropTypes from "prop-types";
import {getArrDateFromContexts} from "../../../shared/helpers/contents";


const IndicatorTimeLineHistogram = ({related_contexts}) =>
  <TimeLineChartCard
    title={'Indicator history'}
    dataName={'Indicator activities'}
    dataKey={"num_contents"}
    items={getArrDateFromContexts(related_contexts)}
    monthlyScale
  />


IndicatorTimeLineHistogram.propTypes = {
  related_contexts: PropTypes.array.isRequired
}


export default IndicatorTimeLineHistogram;
