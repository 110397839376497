import {addAuthToAxiosOpts} from '../config/queryopts'
import {useParallelQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const eventContentsIndicatorsFetcher = (accessToken, contentUid, ioc_type, limit = 0) => {
  const urlParams = new URLSearchParams();
  if (limit) {
    urlParams.set('limit', limit);
  }

  if (ioc_type) {
    urlParams.set('ioc_type', ioc_type);
  }

  return axios.get(`${apiUrlInternal}/contents/${contentUid}/indicators?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

export const useEventContentsIndicatorsQuery = (contentUid, types, limit, fetcher = eventContentsIndicatorsFetcher) => {
  return useParallelQueryWithAuthentication(
    types.map((ioc_type) => ({
      queryKey: ['event-contents', contentUid, ioc_type, limit],
      queryFn: (accessToken) => fetcher(accessToken, contentUid, ioc_type, limit)
    }))
  )
}
