import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteKeywordProjectFetcher = (accessToken, projectUid) => {
  return axios.delete(
    `${apiUrlInternal}/keyword_projects/${projectUid}`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useDeleteKeywordProject = (fetcher = DeleteKeywordProjectFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-organization-keyword-project'],
    (accessToken, projectUid) => fetcher(accessToken, projectUid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('delete-organization-keyword-project');
        queryClient.invalidateQueries('users-me');
      }
    }
  )
}
