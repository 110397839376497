import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import PropTypes from "prop-types";
import {useCookieAccessToken} from "../../../queries";


const DropdownMenuExportFormats = ({
  filters,
  downloadDataHook,
  downloadFormats
}) => {
  const { data: accessToken } = useCookieAccessToken()

  return (
    <UncontrolledDropdown className={'float-right'}>
      <DropdownToggle className="icon icon--right outline" outline color={'success'} style={{height: "48px"}}>
        <p>Export <ChevronDownIcon /></p>
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu__custom_small">
        {
          downloadFormats.map((format) =>
            <DropdownItem key={format} onClick={() => downloadDataHook(filters, format, accessToken)}>{format.toUpperCase()}</DropdownItem>
          )
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

DropdownMenuExportFormats.propType = {
  filters: PropTypes.array.isRequired,
  downloadDataHook: PropTypes.func.isRequired,
  downloadFormats: PropTypes.array.isRequired
}

export default DropdownMenuExportFormats;
