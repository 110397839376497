import {addAuthToAxiosOpts} from '../config/queryopts'
import {useParallelQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const actorsFetcher = (accessToken, uid) => {
  return axios.get(`${apiUrlInternal}/actors/${uid}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useActorsQuery = (actors, fetcher = actorsFetcher) => {
  return useParallelQueryWithAuthentication(
    actors.map((uid) => ({
      queryKey: ['actor', uid],
      queryFn: (accessToken) => fetcher(accessToken, uid),
      options: {cacheTime: Infinity, staleTime: Infinity}
    }))
  )
}
