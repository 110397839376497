import {Badge} from "reactstrap";
import React, {Fragment} from "react";
import PropTypes from "prop-types";


const NodeTags = ({node}) => {
  if (!(node.data.tags && node.data.tags.length > 0)) {
    return <Fragment/>
  }

  return (
    <>
      <p className='custom_card__container-subhead'>TAGS</p>
      <p className='custom_card__container-title'>
        {
          node.data.tags.map((t) => (
            <Badge color={'secondary'} className={'outline badge-sm mr-1'}>
              {t && t.name ? t.name : t}
            </Badge>
          ))
        }
      </p>
    </>
  )
}


NodeTags.propTypes = {
  node: PropTypes.shape().isRequired
}

export default NodeTags;
