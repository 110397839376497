import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import ChatBubble from './ChatBubble';
import ChatField from './ChatField';
import PropTypes from "prop-types";


const ChatInfo = ({
  messages,
  apiPath,
  onSent,
  register,
  control,
  canSendMessage = true,
  canUploadFile = true
}) => {
  return (
    <div className='card-header__ticket chart_data_info'>
      <div className="chat__dialog">
        <Scrollbar className="scroll chat__scroll" alwaysShowTracks>
          <div className="chat__dialog-messages-wrap">
            {messages === null || messages.length === 0
              ? (
                <div className="chat__dialog-messages">
                  <div className="chat__dialog-messages-empty">
                    <p>No messages</p>
                  </div>
                </div>
              ) : (
                <div className="chat__dialog-messages">
                  {messages.map(item => (
                    <ChatBubble
                      canUploadFile={canUploadFile}
                      key={item.id}
                      message={item}
                      active={true}
                      apiPath={apiPath}
                    />
                  ))}
                </div>
              )}
          </div>
        </Scrollbar>
        {canSendMessage &&
          <ChatField canUploadFile={canUploadFile} onFormSubmit={onSent} register={register} control={control}/>}
      </div>
    </div>
  );
};


ChatInfo.propTypes = {
  messages: PropTypes.shape().isRequired,
  apiPath: PropTypes.string.isRequired,
  onSent: PropTypes.func.isRequired,
  control: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
  canUploadFile: PropTypes.bool,
  canSendMessage: PropTypes.bool
}

export default ChatInfo;
