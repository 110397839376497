import {Controller} from "react-hook-form";
import CreatableSelect from "./CreatableSelect";
import React, {useState} from "react";
import PropTypes from "prop-types";


const _ = require('lodash');


const ControlledMultiSelectTextWithTitle = ({
  control,
  title,
  name,
  valueFn,
  rules = {},
  error = null,
  ...selectProps
}) => {
  const [inputValue, setInputValue] = useState('');

  const createOption = (label) => ({
    label,
    value: label,
  });


  const handleKeyDown = (e, onChange, value) => {
    if (!inputValue) return;
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        if (!_.find(value, (v) => v.value === inputValue)) {
          onChange(value ? value.concat([createOption(inputValue)]) : [createOption(inputValue)]);
          setInputValue('');
          e.preventDefault();
        }
    }
  }

  return (
    <div className={`form__form-group ${error ? "form__form-group-error-validation" : ""}`}>
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <div className='form__form-group-input-wrap'>
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field: {onChange, value, ref}}) => (
              <CreatableSelect
                components={{DropdownIndicator: null}}
                inputValue={inputValue}
                isMulti
                isClearable
                inputRef={ref}
                value={valueFn(value)}
                onChange={onChange}
                onKeyDown={(evt) => handleKeyDown(evt, onChange, value)}
                onInputChange={(input) => setInputValue(input)}
                menuIsOpen={false}
                placeholder={'Type a word and press enter'}
                {...selectProps}
              />
            )}
          />
          {error && <span className="form__form-group-error">{error.message}</span>}
        </div>
      </div>
    </div>
  )
}


ControlledMultiSelectTextWithTitle.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  valueFn: PropTypes.func,
  title: PropTypes.string.isRequired,
}


export default ControlledMultiSelectTextWithTitle;
