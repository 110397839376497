import DownloadOutlineIcon from "mdi-react/DownloadOutlineIcon";
import React from "react";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";


const DownloadContentIcon = ({content, filename, size = 24, style = {}}) =>
  <DownloadOutlineIcon
    onClick={() => fileDownload(content, filename)}
    size={size}
    style={Object.assign({}, {fill: 'white', padding: '2px'}, style)}
    className={'border-hover-white'}
  />



DownloadContentIcon.propTypes = {
  content: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  size: PropTypes.number,
  style: PropTypes.object,
}


export default DownloadContentIcon;
