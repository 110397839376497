import {Button, Col, Row} from "reactstrap";
import CloseIcon from "mdi-react/CloseIcon";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useFieldArray} from "react-hook-form";
import classNames from "classnames";


const ReferencesForm = ({register, control, errors}) => {
  const { fields: fieldsReferences, append: appendReferences, remove: removeReferences } = useFieldArray(
    {control, name: "references"}
  );

  return (
    <Fragment>
      {
        fieldsReferences.map((item, index) =>
          <Row className={'w-100'} key={item.id}>
            <Col>
              <Row>
                <Col md={1} className={'d-flex flex-column justify-content-center'}>
                  <CloseIcon
                    style={{fill: 'white', cursor: 'pointer'}}
                    onClick={() => removeReferences(index)}
                  />
                </Col>
                <Col md={11}>
                  <div className={classNames({
                    'form__form-group': true,
                    "form__form-group-error-validation": errors?.references && errors.references[index] && errors.references[index].value
                  })
                  }>
                    <span className="form__form-group-label">Value</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap">
                        <input {...register(`references.${index}.url`, {required: 'The value is required'})} />
                        {
                          errors?.references && errors.references[index] && errors.references[index].value
                          && <span className="form__form-group-error">{errors.references[index].value.message}</span>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }
      {
        errors.references && errors.references.message &&
        <span className="form__form-group-error">{errors.references.message}</span>
      }
      <Row className={'justify-content-end w-100'}>
        <Button outline color={'success'} onClick={() => appendReferences({url: ''})}>
          Add reference
        </Button>
      </Row>
    </Fragment>
  )
}


ReferencesForm.propsType = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}


export default ReferencesForm;
