import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const EditActivityFetcher = (accessToken, params, uid) => {
  return axios.put(
      `${apiUrlInternal}/activities/${uid}`,
      params,
      addAuthToFetchOpts(accessToken, {}, 0)
  ).then(res => res.data);
}

export const useEditActivityMutation = (fetcher = EditActivityFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['edit-activity'],
    (accessToken, {params, uid}) => fetcher(accessToken, params, uid),
    {onSuccess: (_, {uid}) => {
        queryClient.invalidateQueries('edit-activity');
        queryClient.invalidateQueries(['activities']);
        queryClient.invalidateQueries(['activities', uid, 'details']);
      }}
  )
}
