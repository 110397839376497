import {useActorDetailsQuery} from "./Actor";
import {useTagsQuery} from "./Tags";
import {useCountriesQuery} from "./Countries";
import {useSectorsListQuery} from "./SectorsList";
import {useCurrentUserQuery} from "./CurrentUser";


export const useAddActivityFormQuery = () => {
  return {
    'actors': useActorDetailsQuery(),
    'tags': useTagsQuery(),
    'countries': useCountriesQuery(),
    'sectors': useSectorsListQuery(),
    'currentUser': useCurrentUserQuery()
  }
}
