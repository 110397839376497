import React from "react";
import CarouselWithData from "./CarouselWithData";
import PropTypes from "prop-types";


const CarouselWithDataHook = ({
  useDataQuery,
  CarouselElement,
  queryParam = '',
  cardCount = 0
}) => {
  const { data, isIdle, isLoading, isError } = useDataQuery(queryParam);

  return <CarouselWithData
    contentsArray={cardCount ? data?.data?.slice(0, cardCount) : data?.data}
    CarouselElement={CarouselElement}
    isError={isError}
    isLoading={isLoading || isIdle}
  />
}

CarouselWithDataHook.propTypes = {
  CarouselElement: PropTypes.func.isRequired,
  useDataQuery: PropTypes.func.isRequired,
  queryParam: PropTypes.string,
  cardCount: PropTypes.number,
}


export default CarouselWithDataHook;