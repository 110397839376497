import React, {Component} from 'react';
import {connect, Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import PropTypes from 'prop-types';
import {QueryClient, QueryClientProvider} from 'react-query'
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import '../../scss/app.scss';
import {default as AppRouter} from './Router'
import store from '../../redux/store';
import sentryConfig from "../../config/sentry";
import SimpleReactLightbox from "simple-react-lightbox";
import {Auth0Provider} from '@auth0/auth0-react';
import auth0Config from './../../config/auth0'
import {createBrowserHistory} from "history";
import {ReactQueryDevtools} from "react-query/devtools";


export const history = createBrowserHistory();


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // stale time is set to max so that the app won't "soft refresh" randomly during use
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        console.error(error.message);
        return failureCount < 2 && (!error?.response?.status || error.response.status >= 500);
      },
      retryDelay: attemptIndex => Math.min(1000 * 5 * (attemptIndex + 1), 10000),
    },
  },
})


const ThemeComponent = ({children, themeName}) => {
  const theme = createTheme({
    overrides: {
      MuiDrawer: {
        paper: {
          width: 600
        }
      }
    },
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect(state => ({themeName: state.theme.className}))(ThemeComponent);


Sentry.init({
  dsn: sentryConfig.url,
  integrations: [new BrowserTracing()],
  environment: sentryConfig.env,

  // We recommend adjusting this value in production, or using tracesSampler for finer control
  tracesSampleRate: 1.0,
});


const Auth0ProviderWithCallback = ({children}) => {
  const onRedirectCallback = (appState) => {
    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={window.location.origin}
      audience={auth0Config.audience}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}


class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <SimpleReactLightbox>
          <QueryClientProvider client={queryClient}>
            {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
            <Auth0ProviderWithCallback>
                <ConnectedThemeComponent>
                  <Router history={history}>
                    <AppRouter />
                  </Router>
                </ConnectedThemeComponent>
            </Auth0ProviderWithCallback>
          </QueryClientProvider>
        </SimpleReactLightbox>
      </Provider>
    );
  }
}

export default App;
