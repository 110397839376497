import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteOrganizationFetcher = (uid, accessToken) => {
  return axios.delete(`${apiUrlInternal}/organizations/${uid}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}

export const useDeleteOrganization = (fetcher = DeleteOrganizationFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-organization'],
    (accessToken, uid) => fetcher(uid, accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('delete-organization');
        queryClient.invalidateQueries(['organizations', null]);
      }}
  )
}
