import React from "react";
import PropTypes from "prop-types";
import {getStatusColor} from "../../helpers/querydata/sandbox";
import ColorTagInlineBadge from "./ColorTagInlineBadge";


const SandboxScoreResultBadge = ({value}) => {
  return (
    value ?
      <ColorTagInlineBadge
        tag={value.replace('_', ' ')}
        color={getStatusColor(value)}
      />
      :
      <ColorTagInlineBadge tag={'N/A'} color={'primary'} />
  )
}


SandboxScoreResultBadge.propTypes = {
  value: PropTypes.string.isRequired
}

export default SandboxScoreResultBadge;
