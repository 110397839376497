import React, {useEffect} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useForm} from "react-hook-form";
import {useEditUserMutation} from "../../../mutations/UpdateUserMutation";
import RegisteredInputField from "../../../shared/components/form/RegisteredInputField";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import {useHistory} from "react-router-dom";
import TLPBadge from "../../../shared/components/badge/TLPBadge";
import env_const from "../../../config/env_const";
import { APP_AUTH0_PROVIDER, APP_LOCAL_PROVIDER } from "../../../shared/helpers/validators";


const ProfileOverview = ({currentUser, authUser}) => {
  const updateUserMutation = useEditUserMutation();
  const history = useHistory();

  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues: {
      name: authUser.name,
      email: authUser.email,
      organization: currentUser?.organization?.name ? currentUser.organization.name : 'None',
      role: currentUser?.role
    }
  });

  const onSubmit = data => {
    updateUserMutation.mutate({
      uid: currentUser?.uid,
      email: data.email,
      display_name: data.name,
      role: data.role,
      organization: currentUser?.organization,
    })

    toast.loading('Updating profile');
  }

  useEffect(() => {
    if (!updateUserMutation.isIdle && !updateUserMutation.isLoading) {
      toast.dismiss();
      if (updateUserMutation.isSuccess) {
        toast.success('Profile updated correctly');
        setTimeout(() => {
          toast.dismiss();
          history.go(0);
        }, 1000);
      } else if (updateUserMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(updateUserMutation)}.`);
      }
      updateUserMutation.reset();
    }
  }, [updateUserMutation.isIdle, updateUserMutation.isLoading, updateUserMutation.isError, updateUserMutation.isSuccess, updateUserMutation.error])
  
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Profile overview</h4>
          <span className={'float-right'}><TLPBadge tlp={currentUser.tlp.tlp.toUpperCase()} size={'sm'} /></span>
        </div>
        <form className="form form--vertical" onSubmit={handleSubmit(onSubmit)}>
          { env_const.react_app_auth_provider === APP_AUTH0_PROVIDER &&  <RegisteredInputField
            title={'Name'}
            name={'name'}
            register={register}
            errors={errors}
            disabled
            rules={{required: 'The name is required', pattern: { value: /^([a-zA-Z']+\s)*[a-zA-Z']+$/i, message: 'Name contains invalid characters'}}}
          /> }

          <RegisteredInputField
            title={'Email'}
            name={'email'}
            register={register}
            errors={errors}
            disabled
            rules={{required: 'The email is required', pattern: {value: /^\S+@\S+$/i, message: 'Insert a valid email'}}}
          />

          <RegisteredInputField
            title={'Role'}
            name={'role'}
            register={register}
            errors={errors}
            disabled
          />

          <RegisteredInputField
            title={'Organization'}
            name={'organization'}
            register={register}
            errors={errors}
            disabled
          />

          {/*<div className={'w-100 mt-3'}>*/}
          {/*  <Button color="success" type="submit" outline className={'float-right'}>Update</Button>*/}
          {/*</div>*/}
        </form>
      </CardBody>
    </Card>
  )
}


ProfileOverview.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  authUser: PropTypes.shape().isRequired
}

export default ProfileOverview;
