import React, {Fragment, useEffect, useState} from 'react';
import {Collapse} from 'reactstrap';
import {Link} from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import PropTypes from "prop-types";
import {useNotificationUnreadSummaryByUser} from "../../../queries/Notification";
import {useSetAllNotificationRead} from "../../../mutations/SetAllNotificationRead";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import {useQueryClient} from "react-query";
import _ from "lodash";
import paths from "../../../config/paths";
import {NotificationSummary} from "../../../shared/helpers/notification";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";


const TopbarNotification = ({user_uid}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {data, isIdle, isLoading, isError} = useNotificationUnreadSummaryByUser(user_uid);
  const queryClient = useQueryClient();
  const mutation = useSetAllNotificationRead();
  const [notifications, setNotifications] = useState({});

  const collapseNotification = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!isLoading && !isIdle && !isError && data) {
      setNotifications(data.data);
    }
  }, [data, isIdle, isLoading, isError])

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        queryClient.invalidateQueries(['notifications', `user/${user_uid}`, null]);
        queryClient.invalidateQueries(['notifications', `user/${user_uid}/unread`, null]);
        queryClient.invalidateQueries(['notifications', `user/${user_uid}/unread/summary`, null]);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  return (
    <div className="topbar__collapse">
      <button className="topbar__btn topbar__btn--new" type="button" onClick={collapseNotification}>
        <NotificationsIcon/>
        {notifications.total > 0 && <div className="topbar__btn-new-label">
          <div/>
        </div>}
      </button>
      {isCollapsed && <button className="topbar__back" type="button" onClick={collapseNotification}/>}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content"
      >
        {
          !user_uid ? <LoadingSpinner /> :
            <Fragment>
              <div className="topbar__collapse-title-wrap">
                <p className="topbar__collapse-title">
                  {notifications.total > 0 ? "Notifications" : "No new notifications"}
                </p>
                {
                  notifications.total > 0 &&
                  <button
                    className="topbar__collapse-button"
                    type="button"
                    onClick={() => {
                      toast.loading('Marking all notification as read');
                      mutation.mutate(user_uid);
                    }}
                  >
                    Mark all as read
                  </button>
                }
              </div>
              {
                notifications.total > 0 && _.entries(notifications.summary).map(
                  s =>
                    <div className="topbar__collapse-item" key={s[0]}>
                      <p className="topbar__collapse-message">
                        <NotificationSummary total={s[1]} source={s[0]} />
                      </p>
                    </div>
                )
              }
            </Fragment>
        }
        <Link className="topbar__collapse-link" to={paths.notificationPath} onClick={collapseNotification}>
          See all notifications
        </Link>
      </Collapse>
    </div>
  );
};


TopbarNotification.propTypes = {
  user_uid: PropTypes.string.isRequired
}

export default TopbarNotification;
