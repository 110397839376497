import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const OrganizationsIntelSearchFetcher = (uid, accessToken) => {
  return axios.get(`${apiUrlInternal}/organizations/${uid}/intel_search`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}


export const useOrganizationsIntelSearchQuery = (uid, fetcher = OrganizationsIntelSearchFetcher) => {
  return useQueryWithAuthentication(
    ['organizations-intel-search', uid], (accessToken) => fetcher(uid, accessToken)
  )
}
