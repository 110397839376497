import {Badge, Card, CardBody, Col, Row} from "reactstrap";
import React from "react";
import {getElementPropertyOrDefault} from "../../../shared/helpers";
import SpiderIcon from "mdi-react/SpiderIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";


const _ = require('lodash')

const GeneAnalysisResultsReport = ({geneAnalysisResultData}) => {
  const defaultNullFieldValue = '-'
  const mostPopulousRes = _.maxBy(geneAnalysisResultData.data.result, (data) => data.total_count);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <div className="custom_card__container">
              {
                geneAnalysisResultData.data.result.length > 0 ?
                  <Badge color={'danger'} className={'w-100 outline badge-lg'}>
                    <SpiderIcon color={"#ff4861"} className={'float-left'} />MALICIOUS
                  </Badge> :
                  <h1><Badge color={'secondary'} className={'w-100 outline badge-lg'}>
                    <MagnifyIcon color={'#fff'} className={'float-left'} />NO MATCHES
                  </Badge></h1>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <div className="custom_card__container">
              <p className="custom_card__container-subhead">FILENAME</p>
              <p className="custom_card__container-title">{getElementPropertyOrDefault(geneAnalysisResultData.data, 'filename', defaultNullFieldValue)}</p>
              <p className="custom_card__container-subhead">MD5</p>
              <p className="custom_card__container-title">{getElementPropertyOrDefault(geneAnalysisResultData.data, 'md5', defaultNullFieldValue)}</p>
              <p className="custom_card__container-subhead">SHA1</p>
              <p className="custom_card__container-title">{getElementPropertyOrDefault(geneAnalysisResultData.data, 'sha1', defaultNullFieldValue)}</p>
              <p className="custom_card__container-subhead">SHA256</p>
              <p className="custom_card__container-title">{getElementPropertyOrDefault(geneAnalysisResultData.data, 'sha256', defaultNullFieldValue)}</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="custom_card__container">
              <p className="custom_card__container-subhead">NUMBER FAMILIES</p>
              <p className="custom_card__container-title">{geneAnalysisResultData.data.result.length}</p>
              <p className="custom_card__container-subhead">NUMBER OF GENES</p>
              <p className="custom_card__container-title">{_.sumBy(geneAnalysisResultData.data.result, (data) => data.total_count)}</p>
              <p className="custom_card__container-subhead">MOST POPULOUS FAMILY</p>
              <p className="custom_card__container-title">
                {getElementPropertyOrDefault(mostPopulousRes, 'family', defaultNullFieldValue)}{mostPopulousRes && ` (${mostPopulousRes.total_count} hits)`}
              </p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default GeneAnalysisResultsReport;