import React from 'react';
import NodeChart from '../../../../shared/components/nodeChart/NodeChart';
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from 'reactstrap';
import MenuEntry from '../../../../shared/components/MenuEntry';
import {
  canIShowToolTip,
  getNodesInfo,
  SEC_COMMUNICATING_FILES,
  SEC_CONTACTED_DOMAINS,
  SEC_CONTACTED_IPS,
  SEC_CONTACTED_URLS,
  SEC_DROPPED_FILES,
  SEC_RELATED_INDICATORS_INNER
} from '../../../../shared/helpers/node-chart';
import TooltipFiles from './tooltips/TooltipFiles';
import TooltipInfo from './tooltips/TooltipInfo';
import TooltipHeader from './tooltips/TooltipHeader';


const NodeGraph = ({indicator_details}) => {

  const getTooltipData = (node) => {
    if ((node.type === SEC_COMMUNICATING_FILES || node.type === SEC_DROPPED_FILES) && node.level === 3) {
      return <TooltipFiles node={node}/>
    } else if (
      (node.type === SEC_CONTACTED_DOMAINS || node.type === SEC_CONTACTED_IPS || node.type === SEC_CONTACTED_URLS) && node.level === 3 ||
      node.type === SEC_RELATED_INDICATORS_INNER && node.level === 4) {
        return <TooltipInfo node={node}/>
    } else {
      return (
        <Card className={'h-auto'}>
          <CardBody>
            <TooltipHeader node={node}/>
            <Row>
              <Col md={12}>
                <div className="custom_card__container">
                  <MenuEntry title={'ID'} value={node.id}/>
                  <MenuEntry title={'LABEL'} value={node.label}/>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )
    }
  }

  return (
    <Card id='nodeGraph' className={'h-auto'}>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Related indicators graph</h4>
        </div>

        <NodeChart
          data={getNodesInfo(indicator_details)}
          getTooltipInfo={getTooltipData}
          canIShowToolTip={canIShowToolTip}
        />

      </CardBody>
    </Card>
  );
}

NodeGraph.propTypes = {
  indicator_details: PropTypes.shape().isRequired,
}


export default NodeGraph;