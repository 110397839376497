import _ from "lodash";
import {getPattern} from "./validators";
import {getCommonParamsWithValidation} from "./contents";
import {getCommonTags} from "./index";

export const activityCategories = [
    'Data Breach',
    'Data Leak',
    'Combo List',
    "Malware",
    "DDoS Attack",
    "Phishing",
    "Logs",
    "Defacement",
    "Alert",
    "Vulnerability",
    "Ransomware"
]

export const networkTypes = [
    'telegram','openweb', 'tor'
]

export const getActivityParamsWithValidation = (data) => {
    const params = getCommonParamsWithValidation(data);

    params.network = data.network_type?.value;

    if (data.activity_category) {
        params.category = data.activity_category.value;
    } else {
        params.errors['activity_category'] = {
            type: "manual",
            message: `Select a category`
        }
    }

    if (data.sectors) {
        params.sectors = data.sectors.map((item) => item.value);
    }

    if (data.countries) {
        params.targeted_countries = data.countries.map((item) => item.iso_code);
    }

    delete params.malware_families;

    const numberActors = data.actors.length;

    if (numberActors > 0 && !(numberActors === 1 && data.actors[0].label === 'Unknown')) {
        if (data.actors.filter((opt) => opt.label === 'Unknown').length > 0 && numberActors > 1) {
            params.errors['actors'] = {
                type: "manual",
                message: 'You cannot select Unknown actor with other well known.'
            };
        } else {
            params.actors = data.actors.map((opt) => opt.value);
        }
    } else {
        params.actors = [];
    }

    params.references = [];

    _.forEach(data.references, (reference, index) => {
            if (!getPattern('url').test(reference.url)) {
                params.errors[`references.${index}.value`] = {
                    type: "manual",
                    message: `Insert a valid URL for this reference`
                }
            } else {
                params.references.push(reference.url)
            }
        }
    )

    params.targeted_organizations = !!data.targetedOrganizations ? data.targetedOrganizations : []

    return params;
}

export const getActivityDefaultValue = (data, sectors) => {
    const sectorsList = sectors?.filter(s => data?.sectors?.includes(s.uid)) || []
    return {
        tlp: {value: data?.tlp?.id, label: data?.tlp?.tlp},
        description: data?.text,
        title: data?.title,
        network: data?.network,
        category: data?.category,
        created_dt: data?.created_dt,
        actors: data?.actors?.length > 0 ? data.actors?.map((actor) => ({
            value: actor.uid,
            label: actor.name
        })) : [{value: 0, label: 'Unknown'}],
        targeted_countries: data?.targeted_countries,
        sectors: sectorsList.map(
            (s) => ({
                value: s.uid,
                label: s.name
            })
        ),
        attachments: data?.attachments,
        report: data?.report_filename,
        report_id: data?.report_id,
        targeted_organizations: data?.targeted_organizations,
        tags: data?.tags.filter((tag) => getCommonTags(data?.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
            value: tag.name,
            label: tag.name
        })),
    };
}