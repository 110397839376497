import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import paths from "../../../config/paths";
import {ColorTagInlineBadgeList} from "../badge/BadgeLists";
import PropTypes from "prop-types";
import IndicatorTabPaginatedDetailsModal from "../modal/IndicatorsTab/Paginated";
import {getCommonTags} from "../../helpers";


const _ = require('lodash')


const IndicatorsTabRowsPaginated = ({indicators, activeIocType, contentUid, total}) => {
  return (
    <Fragment>
      {
        _.sortBy(indicators, (ioc) => ioc.value).map(
          (ioc) => getCommonTags(ioc.tags).length > 0 ?
            <Row className={'w-100'} key={ioc.value}>
              <Col md={8} xs={6} className={'text-break'}>
                <Link to={`${paths.investigatePath}?indicator=${ioc.value}`}>{ioc.value}</Link>
              </Col>
              <Col md={4} xs={6}>
                <ColorTagInlineBadgeList items={getCommonTags(ioc.tags)} />
              </Col>
              }
            </Row> :
            <Row className={'w-100'} key={ioc.value}>
              <Col md={12}>
                <Link to={`${paths.investigatePath}?indicator=${ioc.value}`}>{ioc.value}</Link>
              </Col>
            </Row>
        )
      }
      {
        indicators.length < total &&
        <IndicatorTabPaginatedDetailsModal
          iocType={activeIocType}
          contentUid={contentUid}
          total={total}
        />
      }
    </Fragment>
  )
}

IndicatorsTabRowsPaginated.propTypes = {
  indicators: PropTypes.array.isRequired,
  activeIocType: PropTypes.string.isRequired,
  contentUid: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}

export default IndicatorsTabRowsPaginated;
