import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import {getElementPropertyOrDefault} from "../../../../shared/helpers";
import WhoisDetailsModal from "../../../../shared/components/modal/WhoisDetailsModal";
import MenuEntry from "../../../../shared/components/MenuEntry";


const WhoisDetailsIP = ({whois}) => {
  const registrant = whois?.registrant;

  return (
    <Row>
      <Col>
        <Row>
          <Col md={7}>
            <div className="custom_card__container">
              <MenuEntry title={'IP'} value={getElementPropertyOrDefault(whois, 'ip', 'Unknown')} />
              <MenuEntry title={'REGISTRANT ORGANIZATION'} value={getElementPropertyOrDefault(registrant, 'organization', 'Unknown')} />
              <MenuEntry title={'REGISTRANT COUNTRY'} value={
                registrant && registrant.country ? `${registrant.country}${registrant.country_code ? `(${registrant.country_code})` : ''}`
                  : 'Unknown'
              } />
            </div>
          </Col>
          <Col md={5}>
            <div className="custom_card__container">
              <MenuEntry title={'CREATION DATE'} value={
                whois && whois.created_date ? formatDateUTCtoYearMonthDayTime(whois.created_date) : 'N/A'
              } />
              <MenuEntry title={'UPDATE DATE'} value={
                whois && whois.updated_date ? formatDateUTCtoYearMonthDayTime(whois.updated_date) : 'N/A'
              } />
              <MenuEntry title={'EXPIRES DATE'} value={
                whois && whois.expires_date ? formatDateUTCtoYearMonthDayTime(whois.expires_date) : 'N/A'
              } />
            </div>
          </Col>
        </Row>

        <WhoisDetailsModal whois={whois} />
      </Col>
    </Row>
  )
}


WhoisDetailsIP.propTypes = {
  whois: PropTypes.shape().isRequired
}


export default WhoisDetailsIP;
