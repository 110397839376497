import classNames from "classnames";
import _ from "lodash";


export const getBadgeClasses = (size) => classNames({
  outline: true,
  ['badge-' + size]: size
})

export const getIndStatsInfo = (inputData, type = 'data') => {
  const COLORS = {undetected: "#dddddd", harmless: "#4ce1b6", suspicious: "#f6da6e", malicious: "#ff4861"};
  const COLORS_BADGE = {undetected: "secondary", harmless: "success", suspicious: "warning", malicious: "danger"};

  const data = [];

  _.forEach(inputData, (count, value) =>
    data.push({
      name: value,
      value: count,
      fill: COLORS[value]
    })
  );

  const maximumRisk = _.maxBy(data, (v) => v.value).name;

  if (type === 'color') {
    return COLORS_BADGE[maximumRisk];
  }

  if (type === 'maximumRisk') {
    return maximumRisk;
  }

  if (type === 'data') {
    return data;
  }

}
