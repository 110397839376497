import React from "react";

export const renderLegendPierChart = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {payload.map((entry, index) => (
      <li key={`item-${index}`}>
        <span style={{ backgroundColor: entry.color }} />
        {entry.value.toUpperCase()}
      </li>
    ))}
  </ul>
);

export const stylePieChart = () => {
  return ({
    left: 0,
    width: 150,
    lineHeight: '24px',
    position: 'absolute',
  });
};

