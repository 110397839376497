import React, {Fragment} from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";


const DatePickerWithLabel = ({
  selected = moment().toDate(),
  onChange = (date) => true,
  maxDate = moment().toDate(),
  label = ''
}) => {

  return (
    <Fragment>
      {label && <span className="form__form-group-label ml-1">{label}</span>}
      <div className="form__form-group-field priority date-picker">
        <DatePicker
          className="form__form-group-datepicker"
          dateFormat="yyyy/MM/dd"
          selected={selected}
          onChange={onChange}
          maxDate={maxDate}
        />
      </div>
    </Fragment>
  )
}

DatePickerWithLabel.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date),
  selected: PropTypes.instanceOf(Date)
}


export default DatePickerWithLabel;
