import React, {Fragment} from "react";
import NistIcon from "../../../containers/InvestigateDetail/components/IndicatorsDetailTypes/CveDetails/components/NistIcon";
import {formatDateUTCtoYearMonthDay} from "../../helpers/date";
import moment from "moment";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  getColorByScore,
  hasPublicExploits,
  isHighRiskValue,
  isMediumRiskValue,
  splitCamelCaseTitle
} from "../../helpers/cve";
import MenuEntry from "../MenuEntry";


const HIGH_RISK_COLOR = '#d50000';
const MEDIUM_RISK_COLOR = '#ff9800';
const LOW_RISK_COLOR = '#b8e986';


const ColoredMenuEntry = ({title, value}) => {
  if (_.isNumber(value) && title !== 'Version') {
    return <MenuEntry title={title} value={value} key={title} valueStyle={{color: getColorByScore(value)}}/>
  } else if (isHighRiskValue(title, value)) {
    return <MenuEntry title={title} value={value} valueStyle={{color: HIGH_RISK_COLOR}} key={title}/>
  } else if (isMediumRiskValue(title, value)) {
    return <MenuEntry title={title} value={value} valueStyle={{color: MEDIUM_RISK_COLOR}} key={title}/>
  } else if (title === 'Version' || title === 'Vector String') {
    return <MenuEntry title={title} value={value} key={title}/>
  } else {
    return <MenuEntry title={title} value={value} key={title} valueStyle={{color: LOW_RISK_COLOR}}/>
  }
}

const CveDetailsContent = ({cve, cveItems}) => {
  const items = [];
  const hasExploits = hasPublicExploits(cve.references);

  _.forEach(cveItems, (value, title) => items.push([splitCamelCaseTitle(title), value]));

  return (
    <Fragment>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>CVE details</h4>
        <span className={'float-right'}>
          <NistIcon cve={cve.id}/>
        </span>
        <p className={'subhead'}>
          Published on: {formatDateUTCtoYearMonthDay(moment(cve.publishedDate))}
        </p>
      </div>

      <Row>
        <Col>
          <div className="custom_card__container">
            {hasExploits && <p className={'p__cve-public-exploit'} style={{color: HIGH_RISK_COLOR}}>A PUBLIC EXPLOIT EXISTS FOR THIS CVE</p>}
            {cve.description && <p>{cve.description}</p>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <div className="custom_card__container">
            {
              items.slice(0, Math.floor(items.length / 2)).map(([title, value]) =>
                <ColoredMenuEntry title={title} value={value} key={title}/>
              )
            }
          </div>
        </Col>

        <Col md={4}>
          <div className="custom_card__container">
            {
              items.slice(Math.floor(items.length / 2), items.length).map(([title, value]) =>
                <ColoredMenuEntry title={title} value={value} key={title}/>
              )
            }
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}


CveDetailsContent.propTypes = {
  cve: PropTypes.object.isRequired,
  cveItems: PropTypes.object.isRequired
}


export default CveDetailsContent;
