import React, {useCallback, useState, Fragment} from "react";
import PropTypes from "prop-types";
import ThemeModal from "../ThemeModal";
import {Col, Row} from "reactstrap";
import MenuEntry from "../MenuEntry";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import {getElementPropertyOrDefault} from "../../helpers";


const WhoisDetailsModal = ({whois}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);

  const registrant = whois?.registrant;

  return (
    <Fragment>
      <p
        className="chart__small_card__total-label-modal"
        onClick={() => toggleModal()}
      >Show more WHOIS information</p>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header-lg modal-dialog--dark'}
      >
        <div className="modal__header" key={"modal__header"}>WHOIS DETAILS</div>
        <div className="modal__body" key={"modal__body"}>
          <Row>
            <Col md={6}>
              <div  className="custom_card__container">
                <MenuEntry title={'IP'} value={getElementPropertyOrDefault(whois, 'ip', 'Unknown')} />
                <MenuEntry title={'CREATION DATE'} value={
                  whois && whois.created_date ? formatDateUTCtoYearMonthDayTime(whois.created_date) : 'N/A'
                } />
                <MenuEntry title={'UPDATE DATE'} value={
                  whois && whois.updated_date ? formatDateUTCtoYearMonthDayTime(whois.updated_date) : 'N/A'
                } />
                <MenuEntry title={'EXPIRES DATE'} value={
                  whois && whois.expires_date ? formatDateUTCtoYearMonthDayTime(whois.expires_date) : 'N/A'
                } />

                <p className="custom_card__container-subhead">NAME SERVER HOSTNAMES</p>
                {
                  !whois || !whois.name_servers_hostnames || whois.name_servers_hostnames.length === 0 ?
                    <p className="custom_card__container-title">{'Unknown'}</p>
                  : whois.name_servers_hostnames.map((domain) =>
                      <p className="custom_card__container-title">{domain}</p>
                    )
                }

                <MenuEntry title={'EMAIL PROVIDER'} value={getElementPropertyOrDefault(whois, 'email_provider', 'Unknown')} />
                <MenuEntry title={'EMAIL REGISTRANT'} value={getElementPropertyOrDefault(whois, 'email_registrant', 'Unknown')} />
                <MenuEntry title={'STATUS'} value={getElementPropertyOrDefault(whois, 'status', 'Unknown')} />
              </div>
            </Col>
            <Col md={6}>
              <div  className="custom_card__container">
                <MenuEntry title={'REGISTRANT NAME'} value={getElementPropertyOrDefault(registrant, 'name', 'Unknown')} />
                <MenuEntry title={'REGISTRANT ORGANIZATION'} value={getElementPropertyOrDefault(registrant, 'organization', 'Unknown')} />
                <MenuEntry title={'REGISTRANT COUNTRY'} value={
                  registrant && registrant.country ? `${registrant.country}${registrant.country_code ? `(${registrant.country_code})` : ''}`
                    : 'Unknown'
                } />
                <MenuEntry title={'REGISTRANT CITY'} value={getElementPropertyOrDefault(registrant, 'city', 'Unknown')} />
                <MenuEntry title={'REGISTRANT STATE'} value={getElementPropertyOrDefault(registrant, 'state', 'Unknown')} />
                <MenuEntry title={'REGISTRANT POSTAL CODE'} value={getElementPropertyOrDefault(registrant, 'postal_code', 'Unknown')} />

                <p className="custom_card__container-subhead">REGISTRANT STREETS</p>
                <p className="custom_card__container-title">{getElementPropertyOrDefault(registrant, 'street1', 'Unknown')}</p>
                {registrant?.street2 && <p className="custom_card__container-title">{registrant.street2}</p>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div  className="custom_card__container">
                <p className="custom_card__container-subhead">RAW TEXT</p>
                <p>{getElementPropertyOrDefault(registrant, 'raw_text', 'N/A')} </p>
              </div>
            </Col>
          </Row>

        </div>
      </ThemeModal>
    </Fragment>
  )
}


WhoisDetailsModal.propTypes = {
  whois: PropTypes.shape().isRequired,
}


export default WhoisDetailsModal;
