import React, {Fragment, useState} from 'react';
import {Col, Progress, Row, Collapse} from "reactstrap";
import SpiderIcon from "mdi-react/SpiderIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";


const CollapsibleGeneDetail = ({geneData}) => {
    const [collapse, setCollapse] = useState(false);
    const toggle = () => {
        setCollapse(prevState => !prevState);
    };

    const RightIcon = () => <ChevronRightIcon onClick={toggle} className={'float-right icon__collapse__gene-detail'} size={32} style={{fill: '#ffffff'}} />
    const DownIcon = () => <ChevronDownIcon onClick={toggle} className={'float-right icon__collapse__gene-detail'} size={32} style={{fill: '#ffffff'}} />

    const [view, setView] = useState({ styleClass: 'closed', icon: <RightIcon /> });

    const onEntering = () => setView({ styleClass: 'opening', icon: <DownIcon /> });
    const onEntered = () => setView({ styleClass: 'opened', icon: <DownIcon /> });
    const onExiting = () => setView({ styleClass: 'closing', icon: <RightIcon />});
    const onExited = () => setView({ styleClass: 'closed', icon: <RightIcon /> });

    return (
      <Fragment>
            <div className={`gene-detail-container collapse__wrapper ${view.styleClass}`}  dir="ltr">
                <div className="gene-detail-icon">
                    <SpiderIcon color={"#ff4861"} />
                </div>
                <div className="gene-detail-content">
                    <Row style={{paddingTop:"10px"}}>
                        <Col md={1}>
                            <p style={{fontSize:"15px",lineHeight:"1.6em"}}>{geneData.family}</p>
                        </Col>
                        <Col md={10} style={{top:"-5px",paddingBottom:"11px"}}>
                            <div className="progress-wrap progress-wrap--pink progress-wrap--rounded">
                                <p style={{textAlign:"right"}} className="progress__label">{geneData.percentage * 100}%</p>
                                <Progress value={geneData.percentage * 100}/>
                            </div>
                        </Col>
                        <Col md={1} style={{top:"-3px"}}>
                            {view.icon}
                        </Col>
                    </Row>
                </div>

            </div>
            <Collapse
                isOpen={collapse}
                onEntering={onEntering}
                onEntered={onEntered}
                onExiting={onExiting}
                onExited={onExited}>
                {geneData.genes_with_count.map(data => (
                    <div className={`gene-detail-container`}  dir="ltr">
                        <div className="gene-detail-icon">
                            <SpiderIcon/>
                        </div>
                        <div className="gene-detail-content">
                            <Row style={{paddingTop:"10px"}}>
                                <Col md={1}/>
                                <Col md={2}>
                                    <p style={{fontSize:"15px",lineHeight:"1.6em"}}>{data.name}</p>
                                </Col>
                                <Col md={8} style={{top:"-5px"}}>
                                    <div className="progress-wrap progress-wrap--pink progress-wrap--rounded">
                                        <p style={{textAlign:"right"}} className="progress__label">
                                            {(data.value * 100 / geneData.total_count).toFixed(2)}%
                                        </p>
                                        <Progress value={data.value *100 / geneData.total_count}/>
                                    </div>
                                </Col>
                                <Col md={1}/>

                            </Row>
                        </div>

                    </div>

                ))}

            </Collapse>
      </Fragment>

    );
};

export default CollapsibleGeneDetail;
