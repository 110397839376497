import PropTypes from "prop-types";
import React from "react";
import {Card, CardBody} from "reactstrap";
import CveScoreGauge from "../../../../../../shared/components/cve/CveScoreGauge";


const CveScoreGaugeCard = ({baseScore, cvssVersion, baseSeverity}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <CveScoreGauge
        baseScore={baseScore}
        cvssVersion={cvssVersion}
        baseSeverity={baseSeverity}
      />
    </CardBody>
  </Card>


CveScoreGaugeCard.propTypes = {
  baseScore: PropTypes.number.isRequired,
  cvssVersion: PropTypes.string.isRequired,
  baseSeverity: PropTypes.string.isRequired
}


export default CveScoreGaugeCard;
