import React from "react";
import {Card, CardBody} from "reactstrap";
import ControlledFilepond from "../../ControlledFilepond";
import PropTypes from "prop-types";
import FileRelationshipForm from "./FileRelationshipForm";


const ReportUploadCard = ({control, defaultValues}) => {
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Upload report file</h4>
        </div>
        <ControlledFilepond
          control={control}
          name={'report'}
          allowMultiple={false}
          allowRevert={false}
          maxFiles={1}
          instantUpload={false}
          defaultValue={defaultValues?.report}
          labelIdle={'Drag & Drop your report or <span class="filepond--label-action">Browse</span>'}
        />
      </CardBody>
    </Card>
  )
}

FileRelationshipForm.propsType = {
  control: PropTypes.shape().isRequired,
}

export default ReportUploadCard;
