import React from 'react';
import PropTypes from "prop-types";
import {useEventContentsIndicatorsQuery} from "../../../queries/EventContentsIndicators";
import LoadingSpinner from "../LoadingSpinner";
import IndicatorsTabPaginated from "./IndicatorsTabPaginated";


const _ = require('lodash');


const IndicatorsTypeTab = ({contentUid, types, withTable = false}) => {
  const queries = useEventContentsIndicatorsQuery(contentUid, types, 5);

  if (queries.some((q) => q.isLoading || q.isIdle)) {
    return <LoadingSpinner />
  }

  const indicators = {};
  _.forEach(queries, (q) => {
    if (q.data.data.length > 0) {
      indicators[q.data.data[0].type] = {
        total: q.data.metadata.total,
        data: q.data.data
      }
    }
  })
  return <IndicatorsTabPaginated indicators={indicators} withTable={withTable} contentUid={contentUid} />
}

IndicatorsTypeTab.propTypes = {
  contentUid: PropTypes.string.isRequired,
  types: PropTypes.string.isRequired,
  withTable: PropTypes.bool
}

export default IndicatorsTypeTab;
