import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import ErrorHandler from "../../shared/components/ErrorHandler";
import env_const from "../../config/env_const";
import NotAllowed401 from "../DefaultPage/401";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";
import {useActivityDetailsQuery} from "../../queries/Activities";
import {getActivityDefaultValue} from "../../shared/helpers/activity";
import ActivityForm from "../../shared/components/form/events-form/ActivityForm";
import {useEditActivityMutation} from "../../mutations/UpdateActivity";
import {useSectorsListQuery} from "../../queries/SectorsList";


const ActivityEdit = () => {
  const {uid} = useParams();
  const {data: response, isError, isLoading, isIdle, error} = useActivityDetailsQuery(uid);
  const dataSectors = useSectorsListQuery()

  const defaultValues = useMemo(
      () => !!response?.data ? getActivityDefaultValue(response?.data, dataSectors?.data?.data) : {},
      [response, dataSectors]
  );

  if (isLoading || isIdle) {
    return <LoadingSpinnerPage/>
  }

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (env_const.is_clone && !response?.data?.is_private) {
    return <Page><NotAllowed401 /></Page>
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
    >
      <ActivityForm
        defaultValues={defaultValues}
        mutationFn={useEditActivityMutation}
        uid={uid}
        editForm
      />
    </Page>
  )
}

export default ActivityEdit;
