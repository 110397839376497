import React, {useMemo} from 'react';
import PieChartMultipleItems from "../../../shared/components/charts/PieChartMultipleItems";
import EmptyPieChart from "../../../shared/components/charts/EmptyPieChart";


const PIE_COLORS = [
    '#4ce1b6', '#70bbfd', '#ff4861',
    '#c88ffa', '#40e5e8', '#f6da6e'
]

const geneAnalysisPieChartConfig = {
    height: 250,
    cy: 100,
    innerRadius: 60,
    outerRadius: 70,
    classNamePie: "dashboard__chart-pie--gene-detail"
}

const GenePercentagesPieChart = ({geneAnalysisResultData}) => {

    const data = useMemo(() =>
        geneAnalysisResultData.map((val, index) => ({
            name: val.family,
            value: val.percentage*100,
            fill: PIE_COLORS[index],
        })), [geneAnalysisResultData]
    )

    if (data.length === 0) {
        return (
            <EmptyPieChart
                pieChartConfig={geneAnalysisPieChartConfig}
                valueLabel={'N/A'}
            />
        )
    }

    return (
        <PieChartMultipleItems
            data={data}
            title={"Malware families"}
            height={250}
            cy={100}
            innerRadius={60}
            outerRadius={70}
            classNamePie={"dashboard__chart-pie--gene-detail"}
        />
    )
}


GenePercentagesPieChart.propTypes = {};

export default GenePercentagesPieChart;
