import React from "react";
import PropTypes from "prop-types";
import TimeLineItem from "../../../shared/components/TimeLineItem";
import paths from "../../../config/paths";
import AlphaPIcon from "mdi-react/AlphaPIcon";
import AlphaAIcon from "mdi-react/AlphaAIcon";
import ContentDetailsModal from "../../../shared/components/modal/ContentDetailsModal";
import {useAnalysisDetailsQuery} from "../../../queries/AnalysisDetails";
import {usePostDetailsQuery} from "../../../queries/PostDetails";
import {formatDateUTCtoYearMonthDay} from "../../../shared/helpers/date";
import {getPostTitle} from "../../../shared/helpers/contents";


const ContentTimeLine = ({contents}) =>
  <div className="timeline">
    {
      contents.map((content, index) =>
        <TimeLineItem
          title={content.type === 'analysis' ? content.title : getPostTitle(content)}
          date={formatDateUTCtoYearMonthDay(content.created_dt)}
          link={`${content.type === 'analysis' ? paths.analysisPath : paths.postPath}/${content.uid}`}
          icon={content.type === 'analysis' ? <AlphaAIcon size={48} /> : <AlphaPIcon size={48} />}
        >
          <ContentDetailsModal
            uid={content.uid}
            queryFetcher={content.type === 'analysis' ? useAnalysisDetailsQuery : usePostDetailsQuery}
            title={content.title}
          />
        </TimeLineItem>
      )
    }
  </div>


ContentTimeLine.propTypes = {
  contents: PropTypes.array.isRequired,
}

export default ContentTimeLine;