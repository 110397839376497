import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const MitreTTPSFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/mitre_ttps`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useMitreTTPSQuery = (fetcher = MitreTTPSFetcher) => {
  return useQueryWithAuthentication(
    ['mitre-ttps'],
    (accessToken) => fetcher(accessToken),
    {cacheTime: Infinity, staleTime: Infinity}
  )
}
