import PropTypes from "prop-types";
import {useActorIndicatorsByTypeQuery} from "../../../queries/Actor";


export const useSearchActorIndicatorDictPagination = (
  {uid, ioc_type}, page = null, limit = null
) => {
  return useActorIndicatorsByTypeQuery(uid, ioc_type, limit, page);
}


useSearchActorIndicatorDictPagination.propTypes = {
  uid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  page: PropTypes.number,
  limit: PropTypes.number,
};
