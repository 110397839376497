import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const MalwareFamilyDetailsFetcher = (accessToken, uid = null) => {
  const uri = (uid) ? `/${uid}` : '';
  return axios.get(`${apiUrlInternal}/malware_families${uri}`, addAuthToAxiosOpts(accessToken, {}, 1200000)).then(res => res.data);
}

export const useMalwareFamilyDetailsQuery = (uid = null, fetcher = MalwareFamilyDetailsFetcher) => {
  return useQueryWithAuthentication(['malware-family', uid],
    (accessToken) => fetcher(accessToken, uid))
}

useMalwareFamilyDetailsQuery.propTypes = {
  uid: PropTypes.string.isRequired,
  include_indicators: PropTypes.bool,
};
