import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SubmitTakeDownMessageFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/takedown_tickets/${params.uid}/messages/send`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useAddTakeDownTicketMessageMutation = (fetcher = SubmitTakeDownMessageFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-takdown-ticket-message'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries('add-takdown-ticket-message')
        queryClient.invalidateQueries(['takedown-tickets', `/${params.uid}/messages`])
        queryClient.invalidateQueries(['tickets', ''])
      }
    }
  )
}
