import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {getBaseScore, getColorByScore} from "../../../shared/helpers/cve";
import _ from "lodash";


const CveResults = ({resource_monitor_results, customHandle}) => {

  const getCveInfo = (item, type = 'color') => {
    let score = 0;
    let color = '#fff';
    let version = '';
    if (item && item.cve && item.cve.nvd_info && item.cve.nvd_info.impact) {
      const [baseScore, cvssVersion] = getBaseScore(item.cve.nvd_info.impact);
      color = getColorByScore(baseScore);
      score = baseScore;
      version = cvssVersion;
    }
    return type === 'color' ? color : type === 'score' ? score : version;
  }

  if (resource_monitor_results && resource_monitor_results.length === 0) {
    return (
      <Container>
        <Row>
          <Col>
            <h2 className={"h2_investigate-title"}>No Vulnerabilities Found</h2>
            <h3 className={"h3_investigate-subtitle"}>
              The vulnerability exposure module did not found any vulnerabilities on your resources. <br/>
              Insert new resources in the "Attack Surface Intelligence" in your profile section.
            </h3>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return (
      <div className="cve-results-wrapper">
        {resource_monitor_results && _.orderBy(
          resource_monitor_results,
          (val) => {
            const [baseScore, ,] = getBaseScore(val.cve.nvd_info.impact);
            return baseScore;
          },
          'desc'
        ).map((item) =>
          <Card>
            <CardBody
              onClick={() => customHandle(item.cve?.cve)}
              className="cve-results shadow-none"
            >
              <p>
                <span style={{'color': getCveInfo(item)}}>{item.cve?.cve} </span>
                <span>with {getCveInfo(item, 'version')} score </span>
                <span style={{'color': getCveInfo(item)}}>{getCveInfo(item, 'score')}</span>
                <span> on host <b>{item.resource_monitor.value}</b></span>
              </p>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}


CveResults.propTypes = {
  resource_monitor_results: PropTypes.arrayOf(PropTypes.object).isRequired,
  customHandle: PropTypes.func.isRequired
}


export default CveResults;
