import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {SRLWrapper} from "simple-react-lightbox";
import PropTypes from "prop-types";

const defaultLightboxOptions = {
    buttons: {
        backgroundColor: "rgba(42,42,49,1)",
        iconColor: "rgba(37, 147, 184, 0.7)"
    },
    settings: {
        overlayColor: "rgba(42,42,49,0.9)",
        transitionSpeed: 1000,
        transitionTimingFunction: "linear"
    },
    thumbnails: {
        thumbnailsSize: ["120px", "100px"],
        thumbnailsOpacity: 0.4
    },
    caption: {
        captionColor: "rgba(37, 147, 184, 1)"
    },
    progressBar: {
        size: "4px",
        backgroundColor: "rgba(37, 147, 184, 1)",
        fillColor: "#AF9AB2"
    }
};

const defaultSliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
};

const CarouselGallery = ({lightboxOptions = defaultLightboxOptions, sliderSettings = defaultSliderSettings, children}) => {
    let imageElements = React.Children.toArray(children)
    return (
          <SRLWrapper options={lightboxOptions}>
            <Slider {...sliderSettings}>
                {imageElements}
            </Slider>
          </SRLWrapper>
    );
}

CarouselGallery.propTypes = {
    children: PropTypes.node.isRequired
};

export default CarouselGallery;