import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const CurrentUserFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/users/me`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useCurrentUserQuery = (fetcher = CurrentUserFetcher) => {
  return useQueryWithAuthentication(
    ['users-me'], (accessToken) => fetcher(accessToken), {staleTime: Infinity}
  )
}
