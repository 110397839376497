import React from "react";
import PropTypes from 'prop-types';
import {Badge, Col} from "reactstrap";
import DarkSearchResults from "./DarkSearchResults";
import CredentialExposureResults from "./CredentialExposureResults";
import BotnetResults from "./BotnetResults";
import IntelxResults from "./IntelxResults";
import _ from "lodash";


const DarkSearchDomainResults = ({
  dataCredential,
  dataBotnet,
  dataDarkSearch,
  dataIntelligenceStats,
  keyword,
  isLoadingResults
}) => {
  let totalIntelx = 0;
  const filteredDataBotnet = dataBotnet.data.total > 0 ? dataBotnet.data.events.filter((e) => e.isp && e.ip && e.ip !== 'NULL') : [];
  _.forEach(dataIntelligenceStats?.data, (value, ) => {totalIntelx += value});

  if ((dataDarkSearch.data.total + dataCredential.data.total + filteredDataBotnet.length + totalIntelx === 0) && !isLoadingResults) {
    return <Col><Badge color={'secondary'} className={'w-100 outline badge-lg'}>NO RESULTS</Badge></Col>
  }

  return (
    <Col>
      {
        dataCredential.data.total > 0 &&
        <CredentialExposureResults credentials={dataCredential.data.credentials} />
      }
      {
        filteredDataBotnet.length > 0 &&
        <BotnetResults events={dataBotnet.data.events.filter((e) => e.isp && e.ip && e.ip !== 'NULL')} />
      }
      {
        dataDarkSearch.data.total > 0 &&
          <DarkSearchResults
           bots={dataDarkSearch.data.bots}
           threads={dataDarkSearch.data.threads}
           sellers={dataDarkSearch.data.sellers}
          />
      }
      {
        totalIntelx > 0 &&
          <IntelxResults
            stats={dataIntelligenceStats.data}
            keyword={keyword}
          />
      }
    </Col>
  )
}


DarkSearchDomainResults.propTypes = {
  dataCredential: PropTypes.object.isRequired,
  dataBotnet: PropTypes.object.isRequired,
  dataDarkSearch: PropTypes.object.isRequired,
  dataIntelligenceStats: PropTypes.object.isRequired,
  keyword: PropTypes.string.isRequired,
  isLoadingResults: PropTypes.bool.isRequired
}


export default DarkSearchDomainResults;
