import React from "react";
import PropTypes from "prop-types";
import ChemicalWeaponIcon from "mdi-react/ChemicalWeaponIcon";
import CardWithIcon from "../CardWithIcon";
import {ColorTagInlineBadgeList} from "../../badge/BadgeLists";


const AssociatedMalwareCard = ({associatedMalware,updateFilters}) => {
  if (!associatedMalware) {
    associatedMalware = []
  }

  return (
    <CardWithIcon
      title={"ASSOCIATED MALWARE"}
      content={
        associatedMalware.length > 0 ?
          <ColorTagInlineBadgeList type="associated_malwares" onTagClick={updateFilters} items={associatedMalware.map((item) => ({name: item}))} size={'sm'} /> :
          <p className="p__custom_card__container-title">NOT AVAILABLE</p>
      }
      icon={<ChemicalWeaponIcon/>}
    />

  )
}

AssociatedMalwareCard.propTypes = {
  associatedMalware: PropTypes.array.isRequired,
  updateFilters:PropTypes.func.isRequired,
}

export default AssociatedMalwareCard;
