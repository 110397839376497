import {Badge, Button, Col, Row} from "reactstrap";
import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {ColorTagInlineBadgeList} from "../../../../shared/components/badge/BadgeLists";
import _ from "lodash";
import {getCommonTags} from "../../../../shared/helpers";
import TLPBadge from "../../../../shared/components/badge/TLPBadge";
import {getTlpColor} from "../../../../shared/helpers/tlp";
import {useHistory} from "react-router-dom";
import RightIcon from "mdi-react/ChevronRightIcon";
import {AnalysisArrayTypes, getAnalysisNameByValue, getAnalysisPathByValue, getFormattedAnalysisName } from "../../../../shared/helpers/contents";


const DifferentAnalysisContent = ({content, type}) => {
  const commonTags = useMemo(() => getCommonTags(content.tags), [content.tags]);
  const history = useHistory();
 
  return (
    <Row className={'row__notification-content'}>
      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Description</h5>
          {
            content.text
          }
        </Col>
        <Col md={1} className={'justify-content-end col__notification-rules'}>
          {
            content.rules?.length > 0 &&
            <h5 className={'h5__notification-content-bdg'}>
              <Badge
                style={{width: "70px"}}
                color={content?.tlp?.tlp ? getTlpColor(content.tlp.tlp) : 'danger'}
                className={'outline'}
              >
                {content.rules?.length} RULE{content.rules?.length > 1 && 'S'}
              </Badge>
            </h5>
          }
          {
            content.report_id &&
            <h5 className={'h5__notification-content-bdg'}>
              <Badge
                style={{width: "70px"}}
                color={content?.tlp?.tlp ? getTlpColor(content.tlp.tlp) : 'danger'}
                className={'outline'}>
                REPORT
              </Badge>
            </h5>
          }
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Tags</h5>
          <ColorTagInlineBadgeList items={commonTags}/>
        </Col>
        <Col md={6}>
          <h5 className="subhead__events">TLP</h5>
          <TLPBadge tlp={content.tlp.tlp}/>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            onClick={() => history.push(`${getAnalysisPathByValue(getAnalysisNameByValue(type))}/${content.uid}`)}
            outline
            className={'btn__notification-content'}
          >Go to the {getFormattedAnalysisName(type)} <RightIcon /></Button>
        </Col>
      </Row>
    </Row>
  )
}


DifferentAnalysisContent.propTypes = {
  content: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}


export default DifferentAnalysisContent;
