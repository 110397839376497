import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const AnalysisDetailsFetcher = (accessToken, uid = null, include_indicators = false) => {
  const uri = (uid) ? `/${uid}` : '';
  return axios.get(`${apiUrlInternal}/analyses${uri}${(include_indicators) ? '?include_indicators=true' : ''}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useAnalysisDetailsQuery = (uid = null, include_indicators = false, fetcher = AnalysisDetailsFetcher) => {
  return useQueryWithAuthentication(['analysis-details', uid, include_indicators],
    (accessToken) => fetcher(accessToken, uid, include_indicators))
}

useAnalysisDetailsQuery.propTypes = {
  uid: PropTypes.string.isRequired,
  include_indicators: PropTypes.bool,
};
