import React from 'react'
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Card, CardBody} from "reactstrap";
import HorizontalBarChart from "./HorizontalBarChart";


const HorizontalBarChartCard = ({
  data,
  dataKey,
  labelDataKey,
  title,
  subtitle = "",
  isLoading = false,
  isError = false,
  dir,
  onBarClick = null,
  barColors = ["#40e5e8"],
  domain = [0, 'auto'],
  themeName
}) => {

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">{title}</h4>
          <h4 className="subhead">{subtitle ? subtitle : ""}</h4>
        </div>
        <HorizontalBarChart
          data={data}
          dataKey={dataKey}
          labelDataKey={labelDataKey}
          isLoading={isLoading}
          isError={isError}
          dir={dir}
          onBarClick={onBarClick}
          barColors={barColors}
          themeName={themeName}
          domain={domain}
        />
      </CardBody>
    </Card>

  )
}

HorizontalBarChartCard.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.array.isRequired,
  labelDataKey: PropTypes.array.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
  onBarClick: PropTypes.func,
  domain: PropTypes.array
}

export default connect(state => ({themeName: state.theme.className}))(HorizontalBarChartCard);