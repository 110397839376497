import React from "react";
import PropTypes from "prop-types";
import {getTlpColor} from "../../../helpers/tlp";
import {ColorTagInlineBadgeList} from "../../badge/BadgeLists";
import {getBotnetTags, getCampaignTags, getCommonTags} from "../../../helpers";
import { useHistory } from "react-router-dom";
import paths from "../../../../config/paths";


const ContentCardFooter = ({tags, tlp}) => {
  if (!tags) {
    tags = [];
  }
  const history = useHistory();
  tags = getCommonTags(tags).concat(getBotnetTags(tags)).concat(getCampaignTags(tags));
  const updateFilters = (type,val) =>{
    const urlParams = new URLSearchParams();
    urlParams.set(type, val);
    history.push(`${paths.contentsPath}?${urlParams.toString()}`);
  }
  return (
    <div className={'mt-5'}>
      <p>
        <ColorTagInlineBadgeList type="tags" onTagClick={(val)=>{ updateFilters('tags',val) }} items={tags} color={getTlpColor(tlp)} />
      </p>
    </div>
  )
}


ContentCardFooter.propTypes = {
  tags: PropTypes.array.isRequired,
  tlp: PropTypes.string.isRequired,
}

export default ContentCardFooter;
