import {Col, Row} from "reactstrap";
import MenuEntry from "../../../../shared/components/MenuEntry";
import {getElementPropertyOrDefault} from "../../../../shared/helpers";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import PropTypes from "prop-types";
import React from "react";
import {BOT, SELLER, THREAD} from "../../../../shared/helpers/validators";
import CountryBadge from "../../../../shared/components/badge/CountryBadge";
import ThemedClampLines from "../../../../shared/components/clamp-lines/ThemedClampLines";


const DarknetNotificationContent = ({darknet}) => {
  const content = darknet.raw;

  if (content.type === BOT) {
    return (
      <Row>
        <Col md={7}>
          <div className="custom_card__container">
            <MenuEntry title={'FORUM'} value={getElementPropertyOrDefault(content, 'forum_name', 'N/A')}/>
            <MenuEntry title={'URL'} value={getElementPropertyOrDefault(content, 'url', 'N/A')}/>
            <MenuEntry title={'OS'} value={getElementPropertyOrDefault(content, 'bot_os', 'N/A')}/>
          </div>
        </Col>
        <Col md={5}>
          <div className="custom_card__container">
            <MenuEntry title={'COUNTRY'}
                       value={content.bot_country ? <CountryBadge countryCode={content.bot_country}/> : 'N/A'}/>
            <MenuEntry title={'INSTALLED ON'}
                       value={content.bot_installed ? formatDateUTCtoYearMonthDayTime(content.bot_installed) : 'N/A'}/>
            <MenuEntry title={'SEEN ON'}
                       value={content.created_dt ? formatDateUTCtoYearMonthDayTime(content.created_dt) : 'N/A'}/>
          </div>
        </Col>
      </Row>
    )
  } else if (content.type === THREAD) {
    return (
      <Row>
        <Col md={7}>
          <div className="custom_card__container">
            <MenuEntry title={'AUTHOR'} value={getElementPropertyOrDefault(content, 'forum', 'N/A')}/>
            <MenuEntry title={'URL'} value={getElementPropertyOrDefault(content, 'url', 'N/A')}/>
          </div>
        </Col>
        <Col md={5}>
          <div className="custom_card__container">
            <MenuEntry title={'FORUM'} value={getElementPropertyOrDefault(content, 'forum_name', 'N/A')}/>
            <MenuEntry title={'SEEN ON'}
                       value={content.created_dt ? formatDateUTCtoYearMonthDayTime(content.created_dt) : 'N/A'}/>
          </div>
        </Col>
        <Col md={12}>
          <div className="custom_card__container">
            <MenuEntry title={'THREAD NAME'} value={getElementPropertyOrDefault(content, 'thread_name', 'N/A')}/>
          </div>
        </Col>
        <Col md={12}>
          <div className="custom_card__container">
            <p className="custom_card__container-subhead">{'THREAD'}</p>
            {
              !content.post_content ? 'N/A' :
                <div style={{maxWidth: '600px'}}><ThemedClampLines text={content.post_content} maxLines={20}/></div>
            }
          </div>
        </Col>
      </Row>
    )
  } else if (content.type === SELLER) {
    return (
      <Row>
        <Col md={7}>
          <div className="custom_card__container">
            <MenuEntry title={'FORUM'} value={getElementPropertyOrDefault(content, 'forum_name', 'N/A')}/>
            <MenuEntry title={'SELLER'} value={getElementPropertyOrDefault(content, 'account_seller', 'N/A')}/>
          </div>
        </Col>
        <Col md={5}>
          <div className="custom_card__container">
            <MenuEntry title={'COUNTRY'} value={
              !!content.account_country && content.account_country.length === 2 && !['ww', 'en'].includes(content.account_country.toLowerCase()) ?
                <CountryBadge countryCode={content.account_country}/> : 'N/A'
            }
            />
            <MenuEntry title={'SEEN ON'}
                       value={content.created_dt ? formatDateUTCtoYearMonthDayTime(content.created_dt) : 'N/A'}/>
          </div>
        </Col>
        <Col md={12}>
          <div className="custom_card__container">
            <p className="custom_card__container-subhead">{'THREAD'}</p>
            {
              !content.account_description ? 'N/A' :
                <div style={{maxWidth: '600px'}}><ThemedClampLines text={content.account_description} maxLines={20}/>
                </div>
            }
          </div>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row>
        <Col><h5>No more information available</h5></Col>
      </Row>
    )
  }
}


DarknetNotificationContent.propTypes = {
  darknet: PropTypes.object.isRequired
}


export default DarknetNotificationContent;
