import React, {Fragment,useCallback} from 'react';
import PropTypes from "prop-types";
import {Button, ButtonToolbar, Modal} from 'reactstrap';
import {TableDetails as DefaultTableDetails} from "../../components/table/TableDetails";
import ThemeModal from "../../components/ThemeModal";

const _ = require('lodash')

const DetailsModal = ({item, showModal, toggleModal, title, TableDetailsComponent = DefaultTableDetails, lightModal = false,...props}) => {
    const closeModal = useCallback(() => {toggleModal(false)}, [])
    const modalContent = <Fragment>
        <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeModal} />
            <h4 className="text-modal  modal__title">{title}</h4>
        </div>

        <div className="modal__body">
            <TableDetailsComponent item={item}/>
        </div>

        <ButtonToolbar className="modal__footer">
            <Button outline className="rounded" onClick={closeModal}>Close</Button>
        </ButtonToolbar>
    </Fragment>
    return (
        lightModal ?
            <Modal
                isOpen={showModal}
                toggle={toggleModal}
                modalClassName={'ltr-support'}
                className={'modal-dialog--header modal-dialog--dark'}
            >
                {modalContent}
            </Modal>
            :
            <ThemeModal
                isOpen={showModal}
                toggle={toggleModal}
                modalClassName={'ltr-support'}
                className={'modal-dialog--header modal-dialog--dark'}
            >
                {modalContent}
            </ThemeModal>
    );
};

DetailsModal.propTypes = {
    item: PropTypes.shape().isRequired,
    toggleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    lightModal: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default DetailsModal;
