import {Card, CardBody} from "reactstrap";
import classNames from "classnames";
import React, {useState} from "react";
import LoadingSpinner from "../../../LoadingSpinner";
import PropTypes from "prop-types";
import { useMitreTTPSQuery } from "../../../../../queries/MitreTTPS";


const _ = require('lodash');


const computeMitreTable = (mitreTable) => {
  const techniqueTable = [];

  _.forEach(mitreTable, (tactic, tacticIndex) => {
    techniqueTable[tacticIndex] = []
    _.forEach(tactic.techniques, (technique) => {
      techniqueTable[tacticIndex].push({name: technique.name, uid: technique.uid});
    })
    techniqueTable[tacticIndex] = techniqueTable[tacticIndex].sort();
  });

  return techniqueTable;
}


const MitreTableSelectionCard = ({setSelection, defaultMitreValues = []}) => {
  const [ttpsSelected, setTtpsSelected] = useState(defaultMitreValues ? defaultMitreValues : []);
  const {
    data: dataMitre,
    isIdle: isIdleMitre,
    isLoading: isLoadingMitre,
    isError: isErrorMitre
  } = useMitreTTPSQuery();


  if (isLoadingMitre || isIdleMitre) {
    return <Card><CardBody><LoadingSpinner/></CardBody></Card>;
  }

  if (isErrorMitre) {
    return <Card><CardBody><h4>Unable to load the content</h4></CardBody></Card>;
  }

  const mitreTable = computeMitreTable(dataMitre.data);
  const mitre = dataMitre.data;

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Mitre TTPS</h4>
        </div>
        <div className={'mitre__wrapper'}>
          <div className={'mitre__table_row'}>
            {
              mitre.map((tactic) =>
                <div className={'mitre__tactic-item-legend'} key={tactic.uid}>
                  <p key={tactic.uid}>{tactic.name}</p>
                </div>
              )
            }
          </div>
          {
            _.range(_.max(mitreTable.map((techs) => techs.length))).map((techIndex) =>
              <div className={'mitre__table_row'} key={techIndex}>
                {
                  mitre.map((tactic, tacticIndex) => mitreTable[tacticIndex][techIndex] ?
                    <div
                      className={classNames({
                        'mitre__tactic-item-pointer': true,
                        'mitre__tactic-item-selected': ttpsSelected.includes(mitreTable[tacticIndex][techIndex].uid)
                      })}
                      onClick={() => {
                        const uid = mitreTable[tacticIndex][techIndex].uid;
                        if (!ttpsSelected.includes(uid)) {
                          setTtpsSelected(prevTtpsSelected => _.concat(prevTtpsSelected.concat([uid])));
                          setSelection(prevTtpsSelected => _.concat(prevTtpsSelected.concat([uid])));
                        } else {
                          setTtpsSelected(prevTtpsSelected => _.without(prevTtpsSelected, uid));
                          setSelection(prevTtpsSelected => _.without(prevTtpsSelected, uid));
                        }
                      }}
                    ><p>{mitreTable[tacticIndex][techIndex].name}</p></div> :
                    <div className={'mitre__tactic-item-empty'}/>
                  )
                }
              </div>
            )
          }
        </div>
      </CardBody>
    </Card>
  )
}


MitreTableSelectionCard.propTypes = {
  setSelection: PropTypes.func.isRequired
}

export default MitreTableSelectionCard;
