import React from "react";
import CardWithIcon from "../CardWithIcon";
import TargetAccountIcon from "mdi-react/TargetAccountIcon";


const ActorUnknownCard = () => {
  return (
    <CardWithIcon
      title={'ACTOR'}
      icon={<TargetAccountIcon />}
      content={<p className="p__custom_card__container-title">Unknown</p>}
    />
  )
}

export default ActorUnknownCard;
