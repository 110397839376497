import axios from "axios";
import {useQueryClient} from "react-query";
import { useMutationWithAuthentication } from "..";
import { apiUrlInternal } from "../../config/api";
import { addAuthToFetchOpts } from "../../config/queryopts";


export const EditActorFetcher = (accessToken, params, uid) => {
    return axios.put(`${apiUrlInternal}/actors/${uid}`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useEditActorMutation = (fetcher = EditActorFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['edit-actor'],
    (accessToken, {params, actorId}) => fetcher(accessToken, params, actorId),
    {onSuccess: (_, {actorId}) => {
        queryClient.invalidateQueries('edit-actor');
        queryClient.invalidateQueries(['actor', 'detail', actorId]);
        queryClient.invalidateQueries(['actor', 'detail', null]);
      }
    }
  )
}
