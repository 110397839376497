import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const CveDetailFetcher = (uid, accessToken) => {
  return axios.get(
    `${apiUrlInternal}/investigate/cve/${uid}`,
    addAuthToAxiosOpts(accessToken, {})
  ).then(res => res.data);
}


export const useCveDetailQuery = (uid, fetcher = CveDetailFetcher) => {
  return useQueryWithAuthentication(
    ['cve-detail', uid],
    (accessToken) => fetcher(uid, accessToken),
    {staleTime: Infinity}
  )
}
