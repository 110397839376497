import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {Badge, Button, Col, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import RightIcon from "mdi-react/ChevronRightIcon";
import _ from "lodash";
import {ColorTagInlineBadgeList, CountryBadgeList} from "../../../../shared/components/badge/BadgeLists";
import {useSectorsListQuery} from "../../../../queries/SectorsList";
import {getCommonTags} from "../../../../shared/helpers";
import TLPBadge from "../../../../shared/components/badge/TLPBadge";
import {getTlpColor} from "../../../../shared/helpers/tlp";
import paths from "../../../../config/paths";


const ActivityNotificationContent = ({content}) => {
  const history = useHistory();
  const {data: dataSectors, isIdle, isLoading, isError} = useSectorsListQuery();
  const commonTags = useMemo(() => getCommonTags(content.tags), [content.tags]);

  const sectorLabels = isError || !content.sectors || isLoading || isIdle ? [] :
      content.sectors.map((sector) => ({name: _.find(dataSectors.data, (item) => item.uid === sector || item.uid === sector.uid).name}
      ));
  const targetedOrganizationsLabels = useMemo(() => content?.targeted_organizations.map((organization) => ({
    name: organization.name
  }) || []), [content?.targeted_organizations]);

  return (
    <Row className={'row__notification-content'}>
      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Targeted sectors</h5>
          {
            sectorLabels.length > 0 ?
              <ColorTagInlineBadgeList key={`${content.name}+${content.category}`} items={sectorLabels} color={'dark-blue'}/> :
              <h5>NO TARGETED SECTORS AVAILABLE</h5>
          }
        </Col>
        <Col md={5}>
          <h5 className="subhead__events">Targeted Organizations</h5>
          {
            targetedOrganizationsLabels.length > 0 ?
                <ColorTagInlineBadgeList key={`${content.name}+${content.category}`} items={targetedOrganizationsLabels} color={'dark-blue'}/> :
                <h5>NO TARGETED ORGANIZATIONS</h5>
          }
        </Col>
        <Col md={1} className={'justify-content-end col__notification-rules'}>
          {
            content?.report_id &&
            <h5 className={'h5__notification-content-bdg'}>
              <Badge
                style={{width: "70px"}}
                color={content?.tlp?.tlp ? getTlpColor(content.tlp.tlp) : 'danger'}
                className={'outline'}>
                REPORT
              </Badge>
            </h5>
          }
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Targeted countries</h5>
          {
            content?.targeted_countries?.length > 0 ? <CountryBadgeList countries={content.targeted_countries.map(el => el.iso_code)}/> :
              <h5>NO TARGETED COUNTRIES</h5>
          }
        </Col>
        <Col md={6}>
          <h5 className="subhead__events">Actors</h5>
          {
            content?.actors?.length > 0 ?
                <ColorTagInlineBadgeList items={content.actors}/> :
                <h5>UNKNOWN</h5>
          }
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Tags</h5>
          <ColorTagInlineBadgeList items={commonTags}/>
        </Col>
        <Col md={3}>
          <h5 className="subhead__events">TLP</h5>
          <TLPBadge tlp={content.tlp.tlp}/>
        </Col>
        {
          content.network &&
            <Col md={3}>
              <h5 className="subhead__events">Network</h5>
              <TLPBadge tlp={content.network}/>
            </Col>
        }
      </Row>

      <Row>
        <Col>
          <Button
            onClick={() => history.push(`${paths.activitiesPath}/${content.uid}`)}
            outline
            className={'btn__notification-content'}
          >Go to the {content?.category} <RightIcon /> details</Button>
        </Col>
      </Row>
    </Row>
  )
}


ActivityNotificationContent.propTypes = {
  content: PropTypes.object.isRequired
}


export default ActivityNotificationContent;
