import React, {Fragment} from "react";
import PropTypes from "prop-types";
import ThemedTagCloud from "../charts/ThemedTagCloud";
import {countByField} from "../../helpers";


const _ = require('lodash')


const CredentialPasswordWordCloud = ({credentials}) => {
  const MAX_WORDS = 30;
  const passwordCredential = countByField(
    credentials.filter((val) => !_.isUndefined(val.password) && val.password !== ''),
    (val) => val.password
  );

  const passwordCredentialArray = _.orderBy(_.keys(passwordCredential).map((password, index) => ({
    text: password,
    value: passwordCredential[password]
  })), (password) => password.value).slice(0, MAX_WORDS);

  return (
    <Fragment>
      <div className="card__title">
        <h4 className="bold-text">MOST COMMON PASSWORD</h4>
      </div>
      <div style={{height: '240px'}}>
        <ThemedTagCloud
          words={passwordCredentialArray}
          maxWordSize={20}
          minWordSize={10}
        />
      </div>
    </Fragment>
  )
}


CredentialPasswordWordCloud.propTypes = {
  credentials: PropTypes.array.isRequired
}


export default CredentialPasswordWordCloud;
