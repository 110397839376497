import PropTypes from "prop-types";
import {FloatingMenu,MainButton,ChildButton,Directions} from 'react-floating-button-menu';
import React, { useCallback, useState } from "react";
import PlusIcon from 'mdi-react/PlusIcon';
import { Tooltip } from 'reactstrap';

const ThemedFabMenu = ({actions}) => {
  const [open,setOpen] = useState(false);
  const [tooltipId, setTooltipId] = useState();
  const toggleTooltip = useCallback((ev) => { setTooltipId(prev => parseInt(ev.target.id.split('-')[1])) }, [tooltipId, setTooltipId]);
  return (
    <div style={{position: 'fixed', padding: '20px', margin: '20xpx',bottom:'0px',right:'0px','z-index':'9999'}}>
        { actions.map((a,index) => <Tooltip
            placement="left"
            isOpen={tooltipId === index ? true : false}
            target={'content-'+index}
            toggle={toggleTooltip}
        > {a.text}</Tooltip>
        )}
    <FloatingMenu
    slideSpeed={500}
    direction={Directions.Up}
    spacing={8}
    isOpen={open}
    >
        <MainButton
            iconResting={<PlusIcon />}
            iconActive={<PlusIcon />}
            background="#48c9cc"
            onClick={ () => { setOpen(!open); open ? setTooltipId(prev => -1) : setTooltipId(prev => 0) } }
            size={56}
        />
        {
            actions.map((action,index) =>
                <ChildButton
                    icon={ action.icon }
                    background="#48c9cc"
                    direction={Directions.Left}
                    size={40}
                    style={{color:'#fff'}}
                    id={'content-'+index}
                    onClick={() => { action.onClick() }}
                />
            )
        }
    </FloatingMenu>
    </div>
  );
}


ThemedFabMenu.propTypes = {
  actions: PropTypes.array.isRequired
}

export default ThemedFabMenu;
