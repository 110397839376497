import React from "react";
import 'filepond/dist/filepond.min.css'
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import MonacoYaraEditor from "../editor/MonacoYaraEditor";


const ControlledMonacoYaraEditor = ({
  control,
  name,
  rules = {},
  ...monacoEditorProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({field: {onChange}}) => (
        <div>
          <MonacoYaraEditor
            onChange={onChange}
            {...monacoEditorProps}
          />
        </div>)}
    />
  )
}

ControlledMonacoYaraEditor.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape(),
}


export default ControlledMonacoYaraEditor;
