import {useMutation} from "react-query";
import {useCookieAccessToken} from "../queries";


export const useMutationWithAuthentication = (mutationKey, mutationFnWithToken, options = null) => {
  /*
  Check isIdle before isLoading since it will be true while getting the accessToken.
  For more info refer to "Dependent Queries" on react-query docs.
   */
  const { data: accessToken } = useCookieAccessToken()
  options = (options) ? options : {};
  options.enabled = (options.hasOwnProperty('enabled')) ? options.enabled && !!accessToken : !!accessToken;
  return useMutation(mutationKey, (params) => mutationFnWithToken(accessToken, params), options);
}
