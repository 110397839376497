import PropTypes from "prop-types";
import React from "react";
import {Card, CardBody} from "reactstrap";
import BtcReportTimeLine from "./BtcReportTimeLine";


const BtcReportTimeLineCard = ({reports}) =>
  <Card className={'h-auto'}>
    <CardBody className={'card__body-content-timeline'}>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>Recent abuse activities</h4>
      </div>
      <BtcReportTimeLine reports={reports} />
    </CardBody>
  </Card>


BtcReportTimeLineCard.propTypes = {
  reports: PropTypes.array.isRequired,
}

export default BtcReportTimeLineCard;