import React from "react";
import PropTypes from "prop-types";
import {CardBody, Col, Row} from "reactstrap";
import RetroHuntTaskYaraModal from "./RetroHuntTaskYaraModal";
import {useRetroHuntTaskMatches} from "../../../queries/RetroHunt";
import CopyIconToClipboard from "../../../shared/components/icons/CopyIconToClipboard";
import TableWithDataHookPagination from "../../../shared/components/table/TableWithDataHookPagination";
import {apiUrlInternal} from "../../../config/api";
import DownloadFileIcon from "../../../shared/components/icons/DownloadFileIcon";


const _ = require('lodash');


const RetroHuntTaskBody = ({task}) => {
  const tableColumns = [
    {
      Header: 'SHA256', accessor: 'sha256', Cell: ({value, row}) => <p>
        <CopyIconToClipboard value={value}/>
        {
          row.original.path && !_.isUndefined(row.original.ordinal) &&
            <DownloadFileIcon
              url={`${apiUrlInternal}/retrohunt/download?uid=${task.uid}&file_path=${row.original.path}&ordinal=${row.original.ordinal}`}
              filename={value}
            />
        }
        <span className={'ml-3'}>{value}</span>
      </p>
    }
  ];

  const tableConfig = {
    withPagination: true,
  }

  return (
    <CardBody>
      <Row>
        <Col md={12} xs={12}>
          <RetroHuntTaskYaraModal yara={task.yara} ruleName={task.rule_name}/>
          <TableWithDataHookPagination
            useDataQuery={useRetroHuntTaskMatches}
            tableColumns={tableColumns}
            queryParams={task.uid}
            tableConfig={tableConfig}
            totalItems={task.files_matched}
          />
        </Col>
      </Row>
    </CardBody>
  )
}

RetroHuntTaskBody.propTypes = {
  task: PropTypes.shape().isRequired
}

export default RetroHuntTaskBody;