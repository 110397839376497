import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";


const RegisteredInputField = ({register, errors, name, title, rules = {}, disabled = false, placeholder = ''}) => {
  let errorMessage = null;
  if (name.includes('.')) {
    const fields = name.split('.')
    errorMessage = errors;
    _.forEach(fields, (f) => errorMessage = errorMessage && errorMessage[f] ? errorMessage[f] : null)
    if (errorMessage) {
      errorMessage = errorMessage.message;
    }
  } else if (errors[name]) {
    errorMessage = errors[name].message;
  }

  return (
    <div className={classNames({'form__form-group': true, "form__form-group-error-validation": errorMessage !== null})}>
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap">
          <input placeholder={placeholder} {...register(name, rules)} disabled={disabled} />
          {errorMessage && <span className="form__form-group-error">{errorMessage}</span>}
        </div>
      </div>
    </div>
  )
}


RegisteredInputField.propTypes = {
  register: PropTypes.func.isRequired,
  rules: PropTypes.shape(),
  errors: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string
}


export default RegisteredInputField;
