import PropTypes from "prop-types";
import {useIndicatorDetailsQuery} from "../../../queries/IndicatorDetails";


export const useSearchIndicatorQueryWithDict = ({
  search_term = '', tags= [], types= [], start = '', end = '', actor = '',
   order_by = '', next = null, limit = null
}) => {
  return useIndicatorDetailsQuery(true, search_term, tags, types, start, end, actor, order_by, next, limit);
}


useSearchIndicatorQueryWithDict.propTypes = {
  search_term: PropTypes.string.isRequired
};
