import PassiveDnsTable from "../../../PassiveDnsTable";
import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";


const PassiveDomainTableCard = ({passive_dns, indicator}) =>
  <Card>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>Passive Domain Resolution</h4>
      </div>

      <PassiveDnsTable
        resolutions={passive_dns.resolutions}
        indicator={indicator}
      />
    </CardBody>
  </Card>


PassiveDomainTableCard.propTypes = {
  passive_dns: PropTypes.object.isRequired,
  indicator: PropTypes.string.isRequired
}


export default PassiveDomainTableCard;
