import React from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import {useActorContentQuery} from "../../../queries/Actor/index";
import ContextsTimeline from "../../../shared/components/timeline/ContextsTimeline";


const ActorContentTimeline = ({actorUid}) => {
  const { data, isIdle, isLoading, isError } = useActorContentQuery(actorUid);

  if (isIdle || isLoading) {
    return <LoadingSpinner />
  }

  if (isError || data.data.length === 0) {
    return <h4>NO RECENT ACTIVITY</h4>
  }

  return <ContextsTimeline contexts={data.data} collectionFilters={{actor: actorUid}} />
}

ActorContentTimeline.propType = {
  uid: PropTypes.string.isRequired
}

export default ActorContentTimeline;
