import React, {useEffect} from "react";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import toast from "react-hot-toast";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {formatDateUTCtoYearMonthDayTime} from "../../shared/helpers/date";
import TableWithData from "../../shared/components/table/TableWithData";
import {useOrganizationsQuery} from "../../queries/Organizations";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {useDeleteOrganization} from "../../mutations/DeleteOrganization";
import {useUserDetailsQuery} from "../../queries/Users";
import {useQueryClient} from "react-query";
import _ from "lodash";
import paths from "../../config/paths";
import {useHistory} from "react-router-dom";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import {PLATFORM_FEATURE_ORGANIZATIONS} from "../../shared/helpers/features";
import {getErrorMessageFromResponse} from "../../shared/helpers";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
}


const OrganizationsList = () => {
  const mutation = useDeleteOrganization();
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataOrganizations,
    isIdle: isIdleOrganizations,
    isLoading: isLoadingOrganizations,
    isError: isErrorOrganizations,
    error: errorOrganizations,
  } = useOrganizationsQuery();
  const {
    data: dataUsers,
    isIdle: isIdleUsers,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    error: errorUsers
  } = useUserDetailsQuery();

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        queryClient.invalidateQueries(['organizations', null]);
      } else if (mutation.isError) {
        toast.error(getErrorMessageFromResponse(mutation));
        mutation.reset();
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])

  if (isLoadingCurrentUser || isIdleCurrentUser) {
    return <LoadingSpinnerPage/>
  }

  if ((isErrorOrganizations && !isIdleOrganizations && !isLoadingOrganizations) ||
    (isErrorUsers && !isIdleUsers && !isLoadingUsers)) {
    return <ErrorHandler error={errorOrganizations || errorUsers}/>
  }

  const tableColumns = [
    {Header: 'Created date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
    {Header: 'Name', accessor: 'name'},
    {Header: 'Status', accessor: 'active', Cell: ({value}) => value ? 'Active' : 'Blocked'},
    {
      Header: 'Number account', accessor: 'uid', Cell: ({value}) =>
        dataUsers.data.filter((user) => user?.organization?.uid === value).length
    },
    {
      Header: 'Intelligence Search',
      accessor: 'max_intelligence_search',
      Cell: ({value}) => value.toString()
    },
    {
      Header: 'AI detection Search',
      accessor: 'max_ai_detection_search',
      Cell: ({value}) => value.toString()
    },
    {
      Header: 'Max Attack Surface Intelligence Engines',
      accessor: 'max_project_resource_monitor',
      Cell: ({value}) => value.toString()
    },
    {Header: 'Max assets per ASI Engine', accessor: 'max_resources_to_monitor', Cell: ({value}) => value.toString()},
    {
      Header: 'Max Identity Intelligence Engines',
      accessor: 'max_project_keyword',
      Cell: ({value}) => value.toString()
    },
    {Header: 'Max Assets per II Engine', accessor: 'max_keywords_to_monitor', Cell: ({value}) => value.toString()},
    {
      Header: 'Max Typosquatting Engines',
      accessor: 'max_project_typosquatting',
      Cell: ({value}) => value.toString()
    },
    {
      Header: 'Max assets per Typosquatting Engine',
      accessor: 'max_typosquatting_assets_to_monitor',
      Cell: ({value}) => value.toString()
    },
    {
      Header: 'Custom Logo', accessor: 'has_custom_logo',
      Cell: ({value}) => value ? 'YES' : 'NO'
    },
    {Header: 'Max Be My Analyst Tickets', accessor: 'be_my_analyst_ticket', Cell: ({value}) => value.toString()},
    {Header: 'Max Takedown Tickets', accessor: 'takedown_ticket', Cell: ({value}) => value.toString()},
    {Header: 'Be My Analyst Coins', accessor: 'be_my_analyst_coins', Cell: ({value}) => value ? value.toString() : 0},
    {Header: 'Activation date', accessor: 'activation_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
  ];

  if (hasUserEditorPermissionOnFeature(dataCurrentUser.data.modules, PLATFORM_FEATURE_ORGANIZATIONS)) {
    tableColumns.unshift({
      id: 'Delete', Header: 'Edit', accessor: 'uid', Cell: ({value, row}) => (
        <>
          <PencilOutlineIcon size={24} style={{fill: '#ffffff', margin: '0'}} onClick={(e) => {
            e.stopPropagation();
            history.push(`${paths.listOrganizationPath}/edit/${value}`);
          }} className={'border-hover-white mr-3 editIcon'}/>
        </>
      )
    })
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_ORGANIZATIONS}
      onlyOnline
    >
      <Container>
        <Row className={'div__sticky-top'}>
          <Col md={9}>
            <h3 className="page-title">Manage Organizations</h3>
          </Col>
          {
            hasUserEditorPermissionOnFeature(dataCurrentUser.data.modules, PLATFORM_FEATURE_ORGANIZATIONS)
            && <Col md={3} className={'justify-content-end d-flex'}>
              <Button outline onClick={() => {
                history.push(`${paths.listOrganizationPath}/add`);
              }} color={'success'}>Add Organization</Button>
            </Col>}
        </Row>

        <Card>
          <CardBody>
            <TableWithData
              data={dataOrganizations?.data}
              tableColumns={tableColumns}
              tableConfig={tableConfig}
              isLoading={isIdleOrganizations || isLoadingOrganizations || isIdleUsers || isLoadingUsers}
            />
          </CardBody>
        </Card>
      </Container>
    </Page>
  )
}


export default OrganizationsList;
