import React, {Fragment, useCallback, useState} from "react";
import {Button, Popover, PopoverBody, PopoverHeader} from "reactstrap";


const TopBarDemoTooltip = () => {
  const [IsOpenedPopover, setIsOpenedPopover] = useState(false);
  const handleOpenPopover = useCallback(() => setIsOpenedPopover(!IsOpenedPopover), [IsOpenedPopover]);

  return (
    <Fragment>
      <Button
        id="PopoverDemo"
        onClick={handleOpenPopover}
        outline
        color={'warning'}
        className="button-tooltip-demo"
      >
        DEMO VERSION
      </Button>
      <Popover
        placement="bottom"
        isOpen={IsOpenedPopover}
        target="PopoverDemo"
        toggle={handleOpenPopover}
        innerClassName={'popover-demo'}
      >
        <PopoverHeader>Demo Version</PopoverHeader>
        <PopoverBody>
          You are in demo mode.
          The APIs are disabled and the platform will show only events and indicators from the last 7 days.
        </PopoverBody>
      </Popover>
    </Fragment>
  )
}


export default TopBarDemoTooltip;
