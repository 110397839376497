import PropTypes from "prop-types";
import React from "react";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import TableWithData from "../../../shared/components/table/TableWithData";
import {Card, CardBody} from "reactstrap";


const tableColumns = [
  { Header: 'Seen on', accessor: 'tstamp', Cell: ({value}) => value ? formatDateUTCtoYearMonthDayTime(value) : 'N/A'},
  { Header: 'Attacker IP', accessor: 'actorip' },
  { Header: 'Cookie ID', accessor: 'cookie_id' }
];


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
  isSortable: true,
  manualPageSize: [5],
}


const CounterAttackTableCard = ({attacker_activities, extraColumns = []}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <div className="card__title">
        <h4 className="bold-text">Counter intelligence</h4>
        <p className="subhead">The table shows the IPs from where the attacker connects to the C2</p>
      </div>

      <TableWithData
        data={attacker_activities}
        tableColumns={tableColumns.concat(extraColumns)}
        tableConfig={tableConfig}
      />
    </CardBody>
  </Card>


CounterAttackTableCard.propTypes = {
  attacker_activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  extraColumns: PropTypes.arrayOf(PropTypes.object)
}


export default CounterAttackTableCard;
