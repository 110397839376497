import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const ResetUserPasswordFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/users/me/password_reset`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useResetUserPasswordMutation = (fetcher = ResetUserPasswordFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['reset-user-password'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('users-me')}
  )
}
