import React, {useState, Fragment} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';
import IndicatorsTable from "../table/IndicatorsTable";
import IndicatorsTabRows from "./IndicatorsTabRows";
import PropTypes from "prop-types";
import {formatIocType} from "../../helpers";


const _ = require('lodash')


const IndicatorsTab = ({indicators, withTable = false}) => {
  const [activeTab, setActiveTab] = useState(0);
  const MAX_ROWS = 5;

  const uniqueIndicatorsType = _.uniqBy(indicators, (ioc) => ioc.type).map(ioc => (ioc.type)).sort();
  let structuredDisplayIndicators = uniqueIndicatorsType.reduce(
    (acc, cur) => ({...acc, [cur]: indicators.filter((val) => val.type === cur).slice(0, MAX_ROWS)}), {}
  )
  let structuredIndicators = uniqueIndicatorsType.reduce(
    (acc, cur) => ({...acc, [cur]: indicators.filter((val) => val.type === cur)}), {}
  )

  const [activeIocType, setActiveIocType] = useState(Object.keys(structuredDisplayIndicators)[0]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveIocType(Object.keys(structuredDisplayIndicators)[tab])
    }
  };

  if (indicators.length === 0) {
    return <Fragment />
  }

  if (!activeIocType || !Object.keys(structuredDisplayIndicators).includes(activeIocType)) {
    setActiveIocType(Object.keys(structuredDisplayIndicators)[0])
  }

  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        <Nav tabs>
          {
            _.keys(structuredDisplayIndicators).map((iocType, index) =>
              <NavItem key={iocType}>
                <NavLink
                  className={classnames({active: activeTab === index})}
                  onClick={() => toggleTab(index)}
                  key={iocType}
                >
                  {formatIocType(iocType)} ({structuredIndicators[iocType].length})
                </NavLink>
              </NavItem>
            )
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            _.keys(structuredDisplayIndicators).map((iocType, index) =>
              <TabPane tabId={index} key={index}>
                {
                  withTable ?
                    <IndicatorsTable
                      indicators={structuredIndicators[iocType]}
                    /> :
                    <IndicatorsTabRows
                      structuredIndicators={structuredIndicators[activeIocType]}
                      structuredDisplayIndicators={structuredDisplayIndicators[iocType]}
                      activeIocType={activeIocType}
                      maxRows={MAX_ROWS}
                    />
                }
              </TabPane>
            )
          }
        </TabContent>
      </div>
    </div>
  )
}

IndicatorsTab.propTypes = {
  indicators: PropTypes.array.isRequired,
  withTable: PropTypes.bool
}

export default IndicatorsTab;
