import React from "react";
import PropTypes from "prop-types";
import TimeLineChartCard from "../../../../../../shared/components/charts/TimeLineChartCard";
import moment from "moment";


const MalwareFamilyTimeLineCard = ({family_info}) =>
  <TimeLineChartCard
    title={`Malware ${family_info.family_name} trend`}
    items={family_info.hits}
    dataName={'Malware family activities'}
    dateTimeField={'seen_dt'}
    beginDate={moment(family_info.hits[0].seen_dt)}
    height={300}
    scale={'log'}
    monthlyScale
  />


MalwareFamilyTimeLineCard.propTypes = {
  family_info: PropTypes.array.isRequired
}


export default MalwareFamilyTimeLineCard;
