import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {useQueryWithAuthentication} from "../index";
import PropTypes from "prop-types";
import {addAuthToAxiosOpts} from "../../config/queryopts";


export const CredentialExposureFetcher = (query, accessToken, query_type = null, size = 2000) => {
  const urlParams = new URLSearchParams();
  if (query_type) {
    urlParams.set('query_type', query_type);
  }
  urlParams.set('query', query);
  urlParams.set('size', size.toString());

  return axios.get(
    `${apiUrlInternal}/credentials_exposure?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {}, 60000 * 10)
  ).then(res => res.data);
}

export const useCredentialExposureQuery = (query = null, query_type = null, fetcher = CredentialExposureFetcher) => {
  return useQueryWithAuthentication(
    ['credential-exposure', query], (accessToken) => fetcher(query, accessToken, query_type),
    {staleTime: Infinity, enabled: !!query, retry: false, refetchOnWindowFocus: false}
  )
}

useCredentialExposureQuery.propTypes = {
  query: PropTypes.string.isRequired,
}
