import {Col, Row} from "reactstrap";
import MenuEntry from "../../../../shared/components/MenuEntry";
import {getElementPropertyOrDefault} from "../../../../shared/helpers";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import PropTypes from "prop-types";
import React from "react";
import CountryBadge from "../../../../shared/components/badge/CountryBadge";


const BotNetNotificationContent = ({botnet}) => {
  return (
    <Row>
      <Col md={7}>
        <div className="custom_card__container">
          <MenuEntry title={'IP'} value={botnet.ip} />
          <MenuEntry title={'ISP'} value={botnet.isp} />
          <MenuEntry title={'COUNTRY'} value={
            botnet.geoip?.value?.country_code2 ? <CountryBadge countryCode={botnet.geoip.value.country_code2} /> : 'N/A'}
          />
          <MenuEntry title={'COLLECTED DATE'} value={
            botnet.date_collect ? formatDateUTCtoYearMonthDayTime(botnet.date_collect) : 'N/A'
          } />
          <MenuEntry title={'CREATED DATE'} value={
            botnet.created_dt ? formatDateUTCtoYearMonthDayTime(botnet.created_dt) : 'N/A'
          } />
        </div>
      </Col>
      <Col md={5}>
        <div className="custom_card__container">
          <MenuEntry title={'Hostname'} value={getElementPropertyOrDefault(botnet, 'prefix', 'N/A')} />
          <MenuEntry title={'OS'} value={getElementPropertyOrDefault(botnet, 'system', 'N/A')} />
          <MenuEntry title={'User'} value={getElementPropertyOrDefault(botnet, 'username', 'N/A')} />
          <MenuEntry title={'Password'} value={getElementPropertyOrDefault(botnet, 'password', 'N/A')} />
        </div>
      </Col>
    </Row>
  )
}

BotNetNotificationContent.propTypes = {
  botnet: PropTypes.object.isRequired
}


export default BotNetNotificationContent;
