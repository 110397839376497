import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Card, Collapse} from "reactstrap";
import classNames from "classnames";
import {useSectorsListQuery} from "../../../queries/SectorsList";
import ContentHeader from "./ContentHeader";
import ContentBody from "./ContentBody";
import {useEventContentsIndicatorsCountQuery} from "../../../queries/EventContentsIndicatorsCount";


const _ = require('lodash')


const ContentDetailCard = ({ content, isEditable = false, updateFilters = () => {}}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: dataSectors, isIdle, isLoading, isError } = useSectorsListQuery();
  const {data: dataTypes, isIdle: isIdleTypes, isLoading: isLoadingTypes, isError: isErrorTypes} = useEventContentsIndicatorsCountQuery(content.uid);
  const sectorLabels = isError || !content.sectors || isLoading || isIdle ? [] :
    content.sectors.map((sectorUid) => ({id:sectorUid,name: _.find(dataSectors.data, (item) => item.uid === sectorUid).name}));

  const types = isErrorTypes || isIdleTypes || isIdleTypes || !dataTypes ? [] :
    dataTypes.data.filter((ioc) => ioc.num_indicators > 0).map((ioc) => ioc.type);

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Card>
      <div className={classNames({ ['card-body__tlp-' + content.tlp.tlp]: true })}>
        <ContentHeader
          content={content}
          isLoadingIdle={isIdle || isLoading || isLoadingTypes || isIdleTypes}
          sectorLabels={sectorLabels}
          toggle={toggle}
          updateFilters={updateFilters}
          isEditable={isEditable}
        />
        <Collapse isOpen={isOpen}>
          <ContentBody content={content} types={types} isOpen={isOpen} updateFilters={updateFilters} />
        </Collapse>
      </div>
    </Card>
  )
}

ContentDetailCard.propTypes = {
  content: PropTypes.shape().isRequired,
  isEditable: PropTypes.bool,
  updateFilters:PropTypes.func
};

export default ContentDetailCard;
