import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import EyeIcon from "mdi-react/EyeIcon";


const RegisteredPasswordField = ({register, errors, name, title, rules = {}}) => {
  const [isPasswordKeyShown, setIsPasswordKeyShown] = useState(false);

  return (
    <div className={classNames({'form__form-group': true, "form__form-group-error-validation": errors[name]})}>
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap">
          <input
            name={name} type={isPasswordKeyShown ? 'text' : 'password'}
            {...register(name, rules)}
          />
          {errors[name] && <span className="form__form-group-error">{errors[name].message}</span>}
        </div>
        <button
          className={`form__form-group-button${isPasswordKeyShown ? ' active' : ''}`} type="button" onClick={() => setIsPasswordKeyShown(!isPasswordKeyShown)}
        ><EyeIcon/>
        </button>
      </div>
    </div>
  )
}


RegisteredPasswordField.propTypes = {
  register: PropTypes.func.isRequired,
  rules: PropTypes.shape(),
  errors: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}


export default RegisteredPasswordField;
