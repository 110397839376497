import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


const _ = require('lodash');


export const CloseTakeDownFetcher = (accessToken, params) => {
  return axios.put(
    `${apiUrlInternal}/takedown_tickets/close/${params.uid}?closed_as=${params.closed_as}`,
    {},
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res);
}

export const useCloseTakedownTicketMutation = (fetcher = CloseTakeDownFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['close-takedown-ticket'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: (_, params) => {
        queryClient.invalidateQueries('close-takedown-ticket')
        queryClient.invalidateQueries(['takedown-tickets', `/${params.uid}`])
        queryClient.invalidateQueries(['tickets', ''])
      }
    }
  )
}

