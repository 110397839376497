import _ from "lodash";
import {getPattern} from "../../../shared/helpers/validators";


export const ALLOWED_ATTACHMENT_FORMATS = [
  'zip',
  'rar',
  'eml',
  'msg',
  'png',
  'jpeg',
  'jpg'
]

export const isFilesValid = (data) => {
  return data.every(element => {
    const extension = element.filename.split('.').pop();
    return ALLOWED_ATTACHMENT_FORMATS.includes(extension);
  });

}

export const isIndicatorValid = (indicator, indicator_type) =>
  indicator && indicator.length > 0 && indicator_type && indicator_type.value && getPattern(indicator_type.value).test(indicator)

export const getTicketStatus = (status) => {
  if (status === 'closed_as_fp') {
    return 'CLOSED AS FALSE POSITIVE';
  } else if (status === 'closed_taken_down') {
    return 'CLOSED AS TAKEN DOWN';
  } else if (status === 'request_sent') {
    return 'REQUEST SENT';
  } else {
    return _.toUpper(status);
  }
}

export const isTakeDownTicketClosed = (status) => ['closed_as_fp', 'closed_taken_down'].includes(_.lowerCase(status))
