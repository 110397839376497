import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import TableWithDataHook from "../../../shared/components/table/TableWithDataHook";
import {useSandboxTasksFetcherQuery} from "../../../queries/Sandbox/SandboxTasks";
import {formatDateUTCtoMonthDayYearTimeFile} from "../../../shared/helpers/date";
import SandboxScoreResultBadge from "../../../shared/components/badge/SandboxScoreResultBadge";
import toast from "react-hot-toast";
import {useDeleteSandbox} from '../../../mutations/DeleteSandbox';
import DeleteIconModal from '../../../shared/components/modal/DeleteIconModal';
import {useQueryClient} from "react-query";
import {getErrorMessageFromResponse} from "../../../shared/helpers";


const _ = require('lodash')

const tableConfig = {
  withPagination: true,
  withHover: true,
  withSearchEngine: true,
}

const TasksTable = ({onRowClick, ...props}) => {
  const mutation = useDeleteSandbox();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        queryClient.invalidateQueries(['sandbox-tasks', null]);
        toast.success('Task deleted successfully');
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        mutation.reset();
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])

  const tableColumns = [
    {Header: 'Filename', accessor: 'filename', className: "truncated-columns"},
    {Header: 'SHA1', accessor: 'sha1', className: "truncated-columns"},
    {Header: 'Status', accessor: 'status'},
    {Header: 'Score', accessor: 'score', Cell: ({value}) => value ? value.toFixed(2) : <></>},
    {Header: 'Result', accessor: 'score_label', Cell: ({value}) => <SandboxScoreResultBadge value={value}/>},
    {Header: 'Creation Date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoMonthDayYearTimeFile(value)},
    {
      Header: 'Actions', accessor: 'uid', Cell: ({value, row}) => (
        <DeleteIconModal
          onDelete={() => {
            toast.loading('Deleting Task');
            mutation.mutate(value);
          }}
          message={`Do you really want to delete the task ?`}
        />
      )
    },
  ];

  const getRowDataId = useCallback((row, i) => (row.original.uid), []);

  return (
    <TableWithDataHook
      useDataQuery={useSandboxTasksFetcherQuery}
      tableColumns={tableColumns}
      tableConfig={tableConfig}
      getRowDataId={getRowDataId}
      onRowClick={onRowClick}
      dataAccessor={(data) => _.orderBy(data.data, ['created_dt'], ['desc'])}
      {...props}
    />
  );
}

TasksTable.propTypes = {
  onRowClick: PropTypes.func,
};

export default TasksTable;
