import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

import getTooltipStyles from '../../../shared/helpers';
import LoadingSpinner from "../LoadingSpinner";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Card, CardBody} from "reactstrap";


const style = () => {
  return ({
    left: 0,
    // width: 150,
    lineHeight: '24px',
    position: 'absolute',
  });
};

const renderLegend = ({payload, onClick}) => {
  const classes = classNames({
    'dashboard__chart-legend-with-hover': onClick !== null,
  });
  return (
    <ul className={"dashboard__chart-legend"}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`}
            onClick={() => {
              if (onClick) {
                onClick(entry.value)
              }
            }} className={classes}
        >
          <span style={{backgroundColor: entry.color}}/>{entry.value}
        </li>
      ))}
    </ul>
  );
}

const PieChartMultipleItems = ({
  title,
  data,
  subtitle = "",
  isLoading = false,
  isError = false,
  onLegendClick = null,
  themeName,
  classNamePie = 'dashboard__chart-pie--commerce',
  cy = 180,
  innerRadius = 100,
  outerRadius = 130,
  height = 360,
  rtl,
}) => {
  const [coordinates, setCoordinates] = useState({x: 0, y: 0});

  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({x: rtl === 'ltr' ? e.tooltipPosition.x / 10 : e.tooltipPosition.x, y: e.tooltipPosition.y});
    }
  };

  const classes = classNames({
    [classNamePie]: true,
    'dashboard__chart-pie': true
  });


  return (
    <Fragment>
      <div className="card__title">
        <h4 className="bold-text">{title}</h4>
        <p className="subhead">{subtitle ? subtitle : ""}</p>
      </div>
      {
        isError ? <p>ERROR WHILE FETCHING DATA!</p> :
          isLoading && <LoadingSpinner/> ||
          <ResponsiveContainer className={classes} height={height}>
            <PieChart className="dashboard__chart-pie-container">
              <Tooltip
                position={coordinates}
                {...getTooltipStyles(themeName)}
                formatter={(value, name, props) => [value, name.toUpperCase(),]}
              />
              <Pie
                data={data}
                dataKey="value"
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                label
                isAnimationActive={false}
                onMouseMove={onMouseMove}
              />
              <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style()} content={renderLegend}
                      onClick={onLegendClick}/>
            </PieChart>
          </ResponsiveContainer>
      }
    </Fragment>
  );
};

PieChartMultipleItems.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool
};


export default connect(state => ({themeName: state.theme.className, rtl: state.rtl,}))(PieChartMultipleItems);
