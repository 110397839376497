import PieChartMultipleItems from "../../../../shared/components/charts/PieChartMultipleItems";
import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";


const VulnRisksPieChartCard = ({
  severities
}) =>
  <Card>
    <CardBody>
      <PieChartMultipleItems
        data={severities}
        title={"CVE risks"}
        classNamePie={'dashboard__chart-pie--sandbox-types'}
      />
    </CardBody>
  </Card>


VulnRisksPieChartCard.propTypes = {
  severities: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default VulnRisksPieChartCard;
