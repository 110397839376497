import React from 'react';
import {Card, CardBody, Col, Row} from "reactstrap"
import MenuEntry from "../../../../../shared/components/MenuEntry"
import {
  SEC_CONTACTED_DOMAINS,
  SEC_CONTACTED_IPS,
  SEC_CONTACTED_URLS,
  SEC_RELATED_INDICATORS_INNER
} from "../../../../../shared/helpers/node-chart"
import PropTypes from "prop-types";
import TooltipHeader from './TooltipHeader';
import NodeTags from "../../../../../shared/components/badge/NodeTags";
import NodeStats from "../../../../../shared/components/card/NodeStats";


const TooltipInfo = ({node}) => {
  return (
    <Card className={'h-auto'}>
      <CardBody>
        <TooltipHeader node={node}/>
        <Row>
          <Col md={6}>
            <div className="custom_card__container">
              {
                node.type === SEC_CONTACTED_DOMAINS && <>
                  <MenuEntry title={'REGISTRAR'} value={node.data.registrar}/>
                  <MenuEntry title={'VALUE'} value={node.data.value}/> </>
              }
              {
                node.type === SEC_CONTACTED_URLS &&
                <MenuEntry title={'VALUE'} value={node.data.value}/>
              }
              {
                node.type === SEC_CONTACTED_IPS && <>
                  <MenuEntry title={'AS OWNER'} value={node.data.as_owner}/>
                  <MenuEntry title={'ASN'} value={node.data.asn}/>
                  <MenuEntry title={'COUNTRY'} value={node.data.country}/>
                </>
              }
              {
                node.type === SEC_RELATED_INDICATORS_INNER && <>
                  <MenuEntry title={'TYPE'} value={node.data.type}/>
                  <MenuEntry title={'UID'} value={node.data.uid}/>
                  <MenuEntry title={'VALUE'} value={node.data.value}/>
                </>
              }
            </div>
          </Col>
          <Col md={6}>
            <div className="custom_card__container">
              <NodeTags node={node} />
              {node.type !== SEC_RELATED_INDICATORS_INNER && <NodeStats node={node} />}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

TooltipInfo.propTypes = {
  node: PropTypes.shape().isRequired,
}

export default TooltipInfo;