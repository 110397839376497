import React from "react";
import {Card, CardBody} from "reactstrap";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import TableWithData from "../../../../shared/components/table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import {useSandboxTasksReported} from "../../../../queries/Sandbox";
import PropTypes from "prop-types";
import SandboxScoreResultBadge from "../../../../shared/components/badge/SandboxScoreResultBadge";


const tableColumns = [
  { Header: 'Filename', accessor: 'filename', className: "truncated-columns" },
  { Header: 'Score', accessor: 'score' },
  { Header: 'Result', accessor: 'score_label', Cell: ({value}) => <SandboxScoreResultBadge value={value} />},
  { Header: 'Date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value) },
];


const tableConfig = {
  withPagination: false,
  withHover: true
}


const LastFileSandboxTable = ({tasksLimit = 10, ...props}) => {
  const history = useHistory();
  const { data, isIdle, isLoading, isError } = useSandboxTasksReported(tasksLimit);

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Last analyzed files</h4>
        </div>
        <TableWithData
          data={data?.data}
          isLoading={isLoading || isIdle}
          tableColumns={tableColumns}
          tableConfig={tableConfig}
          isError={isError}
          onRowClick={(index, row) => history.push(`${paths.sandboxTasksPath}/${row.original.uid}`)}
          {...props}
        />
      </CardBody>
    </Card>
  )
}

LastFileSandboxTable.propTypes = {
  tasksLimit: PropTypes.number
}

export default LastFileSandboxTable;
