import React from "react";
import PropTypes from "prop-types";
import ChemicalWeaponIcon from "mdi-react/ChemicalWeaponIcon";
import CardWithIcon from "../CardWithIcon";
import {ColorTagInlineBadgeWithLinkList} from "../../badge/BadgeLists";
import paths from "../../../../config/paths";


const MalwareFamiliesTagsCard = ({malwareFamilies = []}) => {
  if (!malwareFamilies) {
    malwareFamilies = []
  }

  return (
    <CardWithIcon
      title={"MALWARE FAMILIES"}
      content={
        malwareFamilies.length > 0 ?
          <ColorTagInlineBadgeWithLinkList
            items={malwareFamilies}
            size={'sm'}
            url={paths.malwareFamilyBasePath}
          /> :
          <p className="p__custom_card__container-title">NOT AVAILABLE</p>
      }
      icon={<ChemicalWeaponIcon/>}
    />

  )
}

MalwareFamiliesTagsCard.propTypes = {
  malwareFamilies: PropTypes.array.isRequired
}

export default MalwareFamiliesTagsCard;
