import React, {Fragment, useCallback, useState} from 'react';
import {Col, Row,  Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import BehaviouralProcessTree from "./BehaviouralProcessTree";
import TableWithData from "../../../shared/components/table/TableWithData";

const _ = require('lodash')



function addPidAsProperty(array) {
    if(array && array.length>0) {
        array.forEach(function fn(t) {
            if (t.hasOwnProperty("pid")) {
                _.assign(t, {attributes: {pid: t.pid}})
            }
            if (Array.isArray(t.children)) {
                addPidAsProperty(t.children)
            }
        })
    }
}

const SandboxTaskBehaviouralSection = ({taskId}) => {
    const [treeOrientation, setTreeOrientation] = useState("horizontal");
    const switchOrientation = useCallback(() => (treeOrientation=="horizontal") ? setTreeOrientation("vertical"): setTreeOrientation("horizontal"), [treeOrientation]);
    const { data, isIdle, isLoading, isError,error } = useSandboxTaskDetailsQuery(taskId);

    if (isLoading || isIdle) {
        return <LoadingSpinner />
    }

    if (isError || !data.data) {
        return <ErrorHandler error={error} />
    }

    addPidAsProperty(data.data.report.behavior.processtree)
    const processTree = {
        name:'Root',
        children: data.data.report.behavior.processtree
    }

    const executedCommandsTableColumns = [
        { Header: 'Commands', accessor: (row) => row  }
    ];

    const summary = data.data.report.behavior.summary;

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">Process tree</h5>

                                </div>
                                <span className={'float-right'}>
                                    <div role="group" className="btn-group--icons btn-group" dir="ltr">
                                        <button onClick={switchOrientation} className="icon btn btn-outline-secondary">
                                            <p><span className="lnr lnr-sync" style={{paddingRight: '6px'}}/>Tree orientation</p>
                                        </button>
                                    </div>
                                </span>
                            </div>
                            <BehaviouralProcessTree data={processTree} orientation={treeOrientation}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">Executed commands</h5>
                                </div>
                            </div>
                            {
                              summary && summary.executed_commands && summary.executed_commands.length > 0 &&
                                <TableWithData
                                  data={summary.executed_commands}
                                  tableColumns={executedCommandsTableColumns}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

SandboxTaskBehaviouralSection.propTypes = {
    taskId: PropTypes.string.isRequired,
}

export default SandboxTaskBehaviouralSection;
