import {ColorTagInlineBadgeList} from "../badge/BadgeLists";
import React from "react";
import PropTypes from "prop-types";


const CveReferenceRows = ({refs}) =>
  <div className={'card__body-cve-references'}>
    {
      refs.map((ref) =>
        <div className={'div__cve-references-row'} key={ref.url}>
          <a href={ref.url}>{ref.url}</a>
          <span className={'float-right'}>
                <ColorTagInlineBadgeList items={ref.tags.map((tag) => ({name: tag}))}/>
              </span>
        </div>
      )
    }
  </div>


CveReferenceRows.propTypes = {
  refs: PropTypes.arrayOf(PropTypes.object).isRequired
}


export default CveReferenceRows;
