import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const TagsFetcher = (accessToken, search_term = null) => {
  const urlParams = new URLSearchParams();
  if (search_term) {
    urlParams.set('search_term', search_term);
  }

  return axios.get(`${apiUrlInternal}/tags`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useTagsQuery = (search_term = null, fetcher = TagsFetcher) => {
  return useQueryWithAuthentication(
    ['tags', search_term],
    (accessToken) => fetcher(accessToken, search_term),
    {cacheTime: Infinity, staleTime: Infinity}
  )
}

useTagsQuery.propTypes = {
  search_term: PropTypes.string.isRequired,
};
