const COLORS = [
  '#ffffff', '#ffeb3b', '#ffc107',
  '#ff9800', '#ff5722', '#d50000',
]


export const getColorByScore = (score) => {
  score = Math.min(score, 100);

  return COLORS[Math.ceil((score - 50) / 10)];
}


export const getProportionalScore = (score) => {
  score = Math.min(score, 100);
  const finalScore = Math.ceil((Math.ceil((score - 50) / 10) + 1) * 100 / 6);

  return Math.min(finalScore, 100);
}


export const getRiskLabelByScore = (score) => {
  const s = Math.ceil((score - 50) / 10)
  switch (s) {
    case 0:
      return 'LOW'
    case 1:
    case 2:
    case 3:
      return 'MEDIUM'
    default:
      return 'HIGH'
  }
}

export const getRiskLabelByScoreWithCritical = (score) => {
  const s = Math.ceil((score - 50) / 10);
  switch (s) {
    case 0:
      return 'LOW'
    case 1:
    case 2:
    case 3:
      return 'MEDIUM'
    case 4:
      return 'HIGH'
    default:
      return 'CRITICAL'
  }
}