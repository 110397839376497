import React from "react";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import PropTypes from "prop-types";
import CardWithIcon from "../CardWithIcon";


const MotivationCard = ({motivations,updateFilters}) => {
  if (!motivations) {
    motivations = []
  }

  return (
    <CardWithIcon
      title={"GROUP'S MOTIVATION"}
      content={motivations.length > 0 ?
        motivations.map((mot) => <p className="cursor" onClick={() => { updateFilters({'type':'motivations','name':mot}) }} key={mot}>{mot}</p>) :
        <p className="p__custom_card__container-title">NOT AVAILABLE</p>
      }
      icon={<EyeOutlineIcon/>}
    />
  )
}

MotivationCard.propTypes = {
  motivations: PropTypes.array.isRequired
}

export default MotivationCard;
