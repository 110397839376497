import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Card, Collapse} from "reactstrap";
import classNames from "classnames";
import {useSectorsListQuery} from "../../../queries/SectorsList";
import ActivityHeader from "./ActivityHeader";
import ActivityBody from "./ActivityBody";

const _ = require('lodash')

const ActivityDetailCard = ({
    activity,
    isEditable = false,
    updateFilters = () => {}
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {data: dataSectors, isIdle, isLoading, isError} = useSectorsListQuery();

    const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    return (
        <Card>
            <div className={classNames({['card-body__tlp-' + activity.tlp.tlp]: true})}>
                <ActivityHeader
                    activity={activity}
                    isLoadingIdle={isIdle || isLoading }
                    toggle={toggle}
                    updateFilters={updateFilters}
                    isEditable={isEditable}
                />
                <Collapse isOpen={isOpen}>
                    <ActivityBody activity={activity} isOpen={isOpen} updateFilters={updateFilters}/>
                </Collapse>
            </div>
        </Card>
    )
}

ActivityDetailCard.propTypes = {
    activity: PropTypes.shape().isRequired,
    updateFilters: PropTypes.func
};

export default ActivityDetailCard;
