import PropTypes from "prop-types";
import {Button, Col, Row} from "reactstrap";
import ControlledSelectWithTitle from "../../ControlledSelectWithTitle";
import ControlledCreatableSelectWithTitle from "../../ControlledCreatableSelectWithTitle";
import ReactCountryFlag from "react-country-flag";
import React, {Fragment, useState} from "react";
import {getCountryNameOrCodeShort} from "../../../../helpers/country-data";
import {confidenceLabels} from "../../../../helpers/confidence";
import {TLP_LABELS} from "../../../../helpers/tlp";
import classNames from "classnames";
import {useAddContentFormQuery} from "../../../../../queries/AddContentForm";
import ReferencesForm from "./ReferencesForm";
import RegisteredCheckBoxField from "../../RegisteredCheckBoxField";
import {AnalysisTypes, getTagOptionsByTypes} from "../../../../helpers/contents";
import {categoryCountries, getCountriesByCategory} from "../../../../helpers/categoryCountries";
import moment from "moment";
import DatePickerWithLabel from "../../DatePickerWithLabel";
import env_const from "../../../../../config/env_const";
import CampaignControlledSelect from "../../components/CampaignControlledSelect";
import TagControlledSelect from "../../components/TagControlledSelect";
import BotnetControlledSelect from "../../components/BotnetControlledSelect";
import {activityCategories, networkTypes} from "../../../../helpers/activity";


const _ = require('lodash')


const BasicInformationForm = ({
    control,
    errors,
    register,
    watch,
    defaultValues,
    isPost = false,
    isCollection = false,
    editForm = false,
    isFlashAlert = false,
    isActivity = false,
    setValue,
    getValues,
    setCategory
}) => {
    let isErrorIdleLoading = false;
    const {
        countries: {data: dataCountries, ...countriesProps},
        tags: {data: dataTags, ...tagsProps},
        currentUser: {data: dataCurrentUser, ...currentUserProps},
        actors: {data: dataActors, ...actorsProps},
        sectors: {data: dataSectors, ...sectorsProps}
    } = useAddContentFormQuery();

    const [createdDate, setCreatedDate] = useState(
        defaultValues?.created_dt ? moment(defaultValues.created_dt).toDate() : moment().toDate()
    );

    const [categoryArr, setCategoryArr] = useState([]);

    _.forEach([countriesProps, tagsProps, currentUserProps, actorsProps, sectorsProps], (props) => {
        isErrorIdleLoading |= props.isError || props.isLoading || props.isIdle;
    })

    if (isErrorIdleLoading) {
        return <Fragment/>
    }

    const countryOptions = dataCountries.data.map((country) => ({
        value: country.name,
        label: getCountryNameOrCodeShort(country.iso_code),
        iso_code: country.iso_code
    }));
    const sectorOptions = dataSectors.data.map((sector) => ({value: sector.uid, label: sector.name}));
    const actorOptions = [{value: 0, label: 'Unknown'}].concat(dataActors.data.map((actor) => ({
        value: actor.uid,
        label: actor.name
    })));
    const confidenceOptions = confidenceLabels.map((confidence, index) => ({value: index, label: confidence}));
    const tlpOptions = TLP_LABELS.slice(
        0, _.max([dataCurrentUser.data.tlp.id + 1, dataCurrentUser.data.private_tlp.id + 1])
    ).map((tlp, index) => ({
        value: index,
        label: tlp
    }));
    const activityCategoryOptions = activityCategories.map((category) => ({
        value: category,
        label: category
    }))
    const networkOptions = networkTypes.map((network) => ({
        value: network,
        label: network
    }))
    const [commonTagOptions, malwareFamilyTagOptions, botnetOptions, campaignOptions] = getTagOptionsByTypes(dataTags.data);

    const updateCountriesData = (c) => {
        let countries = getCountriesByCategory(c) && getCountriesByCategory(c)[0] ? getCountriesByCategory(c)[0].data : [];
        let prevCountries = getValues('countries');

        if (categoryArr.includes(c.value)) {
            setCategoryArr(categoryArr.filter(item => item !== c.value));

            if (countries.length > 0) {
                countries.forEach((i) => {
                    if (prevCountries && prevCountries.length > 0) {
                        prevCountries = prevCountries.filter((tc) => tc.value !== i.value)
                    }
                })
            }
        } else {
            setCategoryArr(oldArray => [...oldArray, c.value]);
            if (prevCountries && prevCountries.length > 0) {
                countries.forEach((i) => {
                    if (!prevCountries.includes(i.value)) {
                        prevCountries.push(i);
                    }
                });
            } else {
                prevCountries = countries;
            }
        }
        setValue('countries', prevCountries);

    }

    const isButtonOutline = (c) => !categoryArr.includes(c.value);

    const hasContentMalwareFamily = () => {
        return !isFlashAlert && !isActivity;
    }

    const hasContentBotnetsFamily = () => {
        return !isFlashAlert && !isActivity;
    }

    const hasContentCampaignsFamily = () => {
        return !isFlashAlert && !isActivity;
    }

    const hasContentSectors = () => {
        return !isCollection;
    }

    const hasContentActors = () => {
        return true;
    }

    const hasContentConfidence = () => {
        return !isFlashAlert && !isActivity;
    }

    const hasContentTargetedCountries = () => {
        return !isCollection;
    }

    const hasContentType = () => {
        return !isFlashAlert && !isCollection && !isPost && !isActivity;
    }

    const hasContentWeeklyReport = () => {
        return !isFlashAlert && !isCollection && !isPost && !isActivity;
    }

    const hasContentReferences = () => {
        return !isCollection;
    }

    const hasActivityCategory = () => {
        return isActivity;
    }

    const hasNetworkType = () => {
        return isActivity;
    }

    const hasNotify = () => {
        return !isCollection && !isActivity;
    }

    setValue('created_dt', createdDate);

    return (
        <Fragment>
            <Row className={'w-100'}>
                <Col md={6} xs={12}>
                    {
                        !isPost && !isCollection &&
                        <div className={classNames({
                            'form__form-group': true,
                            "form__form-group-error-validation": errors.title
                        })}>
                            <span className="form__form-group-label">{'Title'}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-input-wrap">
                                    <input {...register('title', {required: 'The title is required'})} />
                                    {errors.title &&
                                        <span className="form__form-group-error">{errors.title.message}</span>}
                                </div>
                            </div>
                        </div>
                    }
                    {
                        hasActivityCategory() &&
                        <ControlledSelectWithTitle
                            name={`activity_category`}
                            title={'Category'}
                            control={control}
                            valueFn={(value) => value}
                            options={activityCategoryOptions}
                            onChangeCustomHandle={(value) => !!setCategory ? setCategory(value.value) : null}
                            isDisabled={editForm}
                            defaultValue={
                                activityCategoryOptions?.filter(el => el.value === defaultValues.category)[0]
                            }
                        />
                    }
                    {
                        hasNetworkType() &&
                        <ControlledSelectWithTitle
                            name={`network_type`}
                            title={'Network Type'}
                            control={control}
                            valueFn={(value) => value}
                            options={networkOptions}
                            defaultValue={
                                networkOptions?.filter(el => el.value === defaultValues.network)[0]
                            }
                            isClearable
                        />
                    }
                    {
                        hasContentSectors() &&
                        <ControlledSelectWithTitle
                            name={'sectors'} title={'Sectors'} control={control}
                            isSearchable isMulti isClearable
                            valueFn={(value) => sectorOptions.find((c) => c.value === value?.value)}
                            defaultValue={defaultValues.sectors ? defaultValues.sectors .map(
                    (value) =>
                      value.name ? value.name :
                        sectorOptions.find((c) => c.value === value.value)
                  )
                  : []}
                            options={sectorOptions}
                        />
                    }
                    <TagControlledSelect
                        control={control}
                        commonTagOptions={commonTagOptions}
                        name={'tags'}
                        isClearable={false}
                    />
                    {
                        hasContentMalwareFamily() &&
                        <ControlledSelectWithTitle
                            name={`malware_families`} title={'Malware Families'} control={control}
                            isClearable isSearchable isMulti
                            valueFn={(value) => value}
                            options={malwareFamilyTagOptions}
                        />
                    }
                    {
                        hasContentBotnetsFamily() &&
                        <BotnetControlledSelect
                            control={control}
                            botnetOptions={botnetOptions}
                            name={'botnet'}
                        />
                    }
                    {
                        hasContentType() &&
                        <ControlledCreatableSelectWithTitle
                            name={`type`} title={'Type'} control={control}
                            isSearchable
                            defaultValue={defaultValues?.analysis_type ? AnalysisTypes.find(
                                (a) => a.value === defaultValues.analysis_type
                            ) : AnalysisTypes[0]}
                            valueFn={(value) => value} options={AnalysisTypes}
                        />
                    }
                    <DatePickerWithLabel
                        label={'Report Date'}
                        selected={createdDate}
                        onChange={(date) => {
                            setValue('created_dt', date);
                            setCreatedDate(date);
                        }}
                    />
                </Col>
                <Col md={6} xs={12}>
                    {
                        hasContentActors() &&
                        <ControlledSelectWithTitle
                            name={'actors'} title={'Actors'} control={control} errors={errors}
                            isSearchable isMulti isClearable withError
                            defaultValue={defaultValues?.actors ? defaultValues.actors.map(
                                (actor) => actorOptions.find((a) => a.value === actor.value)
                            ) : []}
                            options={actorOptions} rules={{required: 'Select an actor'}}
                            valueFn={(value) => actorOptions.find((c) => c.value === value?.value)}
                        />
                    }
                    <Row className={'mx-0'}>
                        {
                            hasContentConfidence() &&
                            <Col md={6} xs={6} className={'px-0'}>
                                <ControlledSelectWithTitle
                                    name={'confidence'} title={'Confidence'} control={control}
                                    errors={errors}
                                    defaultValue={confidenceOptions[0]} options={confidenceOptions} withError
                                    isDisabled={!watch('actors') || watch('actors')?.length === 0 || watch('actors')[0]?.label === 'Unknown'}
                                    valueFn={(value) =>
                                        (!watch('actors') || watch('actors')?.length === 0 || watch('actors')[0]?.label === 'Unknown') ?
                                            {label: 'N/A', value: -1} :
                                            confidenceOptions.find((c) => c.value === value?.value)}
                                />
                                {errors.confidence &&
                                    <span className="form__form-group-error">{errors.confidence.message}</span>}
                            </Col>
                        }
                        <Col md={6} xs={6} className={'px-0'}>
                            <ControlledSelectWithTitle
                                name={'tlp'} title={'TLP'} control={control} errors={errors}
                                rules={{required: true}} defaultValue={tlpOptions[0]}
                                options={tlpOptions} withError
                                valueFn={(value) => tlpOptions.find((c) => c.value === value?.value)}
                            />
                            {errors.tlp && <span className="form__form-group-error">{errors.tlp.message}</span>}
                        </Col>
                    </Row>
                    {
                        hasContentTargetedCountries() &&
                        <ControlledSelectWithTitle
                            name={'countries'} title={'Targeted countries'} control={control}
                            isSearchable isMulti isClearable
                            valueFn={(value) => value}
                            options={countryOptions}
                            defaultValue={defaultValues.targeted_countries ? defaultValues.targeted_countries.map(
                                (countryCode) => countryOptions.find((c) => c.iso_code === countryCode)
                            ) : []}
                            getOptionLabel={option =>
                                <p><ReactCountryFlag countryCode={option.iso_code} className={'mr-1'}
                                                     svg/>{option.label}</p>
                            }
                        />
                    }
                    {
                        hasContentTargetedCountries() &&
                        <div className="category_coutries_list">
                            {
                                categoryCountries.map((item) =>
                                    <Button
                                        outline={isButtonOutline(item)}
                                        color={'success'}
                                        size={'sm'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            updateCountriesData(item)
                                        }}
                                    >{item.label}</Button>
                                )
                            }
                        </div>
                    }
                    {
                        hasContentCampaignsFamily() &&
                        <CampaignControlledSelect
                            campaignOptions={campaignOptions}
                            control={control}
                            name={`campaigns`}
                        />
                    }
                </Col>
            </Row>
            {
                hasNotify() && !editForm && !env_const.is_clone &&
                <Row className={'my-2'}>
                    <Col md={12}>
                        <div className="form__form-group form__form-group-field">
                            <RegisteredCheckBoxField
                                name={'notify'}
                                value={watch('notify')}
                                onChange={() => {}}
                                defaultChecked={false}
                                label={"Send an email to customer about this content"}
                                register={register}
                            />
                        </div>
                    </Col>
                </Row>
            }

            {
                !env_const.is_clone && hasContentWeeklyReport() &&
                <Row className={'w-100 my-2'}>
                    <Col md={12}>
                        <div className="form__form-group form__form-group-field">
                            <RegisteredCheckBoxField
                                name={'is_weekly_report'}
                                value={watch('is_weekly_report')}
                                defaultChecked={defaultValues.is_weekly_report}
                                onChange={() => {}}
                                label={"Weekly report"}
                                register={register}
                            />
                        </div>
                    </Col>
                </Row>
            }

            {
                hasContentReferences() &&
                <ReferencesForm register={register} control={control} errors={errors}/>
            }
        </Fragment>
    )
}


BasicInformationForm.propsType = {
    control: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    defaultValues: PropTypes.shape().isRequired,
    isCollection: PropTypes.bool,
    editForm: PropTypes.bool,
    isPost: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    setCategory: PropTypes.func
}


export default BasicInformationForm;
