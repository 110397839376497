import React from "react";
import PropTypes from "prop-types";
import TimeLineItem from "../../../../../../shared/components/TimeLineItem";
import {formatDateUTCtoYearMonthDay} from "../../../../../../shared/helpers/date";
import ThemedClampLines from "../../../../../../shared/components/clamp-lines/ThemedClampLines";
import AlphaRIcon from "mdi-react/AlphaRIcon";
import AlphaSIcon from "mdi-react/AlphaSIcon";
import AlphaMIcon from "mdi-react/AlphaMIcon";
import AlphaTIcon from "mdi-react/AlphaTIcon";
import AlphaOIcon from "mdi-react/AlphaOIcon";


const getIcon = (abuseType) => {
  const ICON_SIZE = 48;

  if (abuseType === "ransomware") {
    return <AlphaRIcon size={ICON_SIZE} />;
  } else if (abuseType === "darknet market") {
    return <AlphaMIcon size={ICON_SIZE} />;
  } else if (abuseType === "tumbler") {
    return <AlphaTIcon size={ICON_SIZE} />;
  } else if (abuseType === "scam") {
    return <AlphaSIcon size={ICON_SIZE} />;
  } else {
    return <AlphaOIcon size={ICON_SIZE} />;
  }
}


const BtcReportTimeLine = ({reports}) =>
  <div className="timeline">
    {
      reports.map((report, index) =>
        <TimeLineItem
          title={report.abuse_type}
          date={formatDateUTCtoYearMonthDay(report.created_dt)}
          icon={getIcon(report.abuse_type)}
          key={index}
        >
          <p>
            <ThemedClampLines
              text={report.description}
              lines={6}
            />
          </p>
        </TimeLineItem>
      )
    }
  </div>


BtcReportTimeLine.propTypes = {
  reports: PropTypes.array.isRequired,
}

export default BtcReportTimeLine;
