import React from "react";
import 'filepond/dist/filepond.min.css'
import {useController} from "react-hook-form";
import PropTypes from "prop-types";


const ControlledTextAreaWithTitle = ({
  control,
  name,
  rules,
  title,
  textAreaClassNames,
  cardTitle
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: "",
  });

  return (
    <div className={`form__form-group ${invalid ? "form__form-group-error-validation" : ""}`}>
      { cardTitle ? <div className="card__title">
        <h4 className={'bold-text d-inline'}>{title}</h4>
      </div> : <span className="form__form-group-label">{title}</span>}
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap">
          <textarea className={textAreaClassNames} {...inputProps} />
          {invalid && <span className="form__form-group-error">{error.message}</span>}
        </div>
      </div>
    </div>
  );
}

ControlledTextAreaWithTitle.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rules: PropTypes.shape(),
}


export default ControlledTextAreaWithTitle;