import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteTakeDownFetcher = (uid, accessToken) => {
  return axios.delete(`${apiUrlInternal}/takedown_tickets/${uid}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}

export const useDeleteTakedownTicket = (fetcher = DeleteTakeDownFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-takedown-ticket'],
    (accessToken, uid) => fetcher(uid, accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('delete-takedown-ticket')
        queryClient.invalidateQueries(['takedown-tickets', ''])
     }}
  )
}