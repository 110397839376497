import {flatten, getBotnetTags, getCampaignTags, getCommonTags, getMalwareFamilyTags} from "./index";
import {getPattern} from "./validators";
import {confidenceLabels} from "./confidence";
import * as Sentry from "@sentry/react";
import { analysisPath, flashAlertPath, postPath} from "../../config/paths";
import env_const from "../../config/env_const";

const _ = require('lodash');

export const getAnalysisPathByValue = (type) => {
    switch (type) {
        case 'post':
            return postPath;
        case 'flashalert':
            return flashAlertPath;
        default:
            return analysisPath;
    }

}

export const getPostTitle = (post) => {
    if (post.text) {
        return post.text;
    }

    const malwareFamiliesTags = getMalwareFamilyTags(post.tags).map((tag) => tag.name);
    if (post.actors && malwareFamiliesTags.length > 0) {
        return `New post on ${actorsToString(post.actors)} using ${_.join(malwareFamiliesTags, ', ')}`;
    } else if (post.actors) {
        return `New post on ${actorsToString(post.actors)}`;
    } else {
        return 'New post';
    }
}


export const getFlashAlertParamsWithValidation = (data) => {
    const params = getCommonParamsWithValidation(data);
    if (data.sectors) {
        params.sectors = data.sectors.map((item) => item.value);
    }

    if (data.countries) {
        params.targeted_countries = data.countries.map((item) => item.iso_code);
    }

    const numberActors = data.actors.length;

    if (numberActors > 0 && !(numberActors === 1 && data.actors[0].label === 'Unknown')) {
        if (data.actors.filter((opt) => opt.label === 'Unknown').length > 0 && numberActors > 1) {
            params.errors['actors'] = {
                type: "manual",
                message: 'You cannot select Unknown actor with other well known.'
            };
        } else {
            params.actors = data.actors.map((opt) => opt.value);
        }
    } else {
        params.actors = [];
    }

    params.references = [];

    _.forEach(data.references, (reference, index) => {
            if (!getPattern('url').test(reference.url)) {
                params.errors[`references.${index}.value`] = {
                    type: "manual",
                    message: `Insert a valid URL for this reference`
                }
            } else {
                params.references.push(reference.url)
            }
        }
    )

    return params;
}

export const getCommonParamsWithValidation = (data) => {
    const params = {
        tlp: data.tlp.value,
        text: data.description,
        title: data.title,
        created_dt: data.created_dt,
        errors: {}
    }

    if (data.malware_families) {
        params.malware_families = data.malware_families.map((item) => item.value);
    }

    if (data.tags) {
        params.tags = data.tags.map((item) => item.value);
    }

    params.notify = data.notify;

    return params;
}


export const getCollectionParamsWithValidation = (data) => {
    const params = {
        tlp: data.tlp.value,
        description: data.description,
        indicators: [],
        errors: {},
        created_dt: data.created_dt.toISOString()
    }

    if (data.indicators?.length > 0) {
        _.forEach(data.indicators, (indicator, index) => {
            if (indicator.type.label.includes('Bulk')) {
                const type = indicator.type.label.replace('Bulk ', '');
                const s = new Set();

                _.forEach(indicator.value.split('\n'), (ioc) => {
                    // Remove indicators inside the same bulk
                    ioc = ioc.trim();

                    if (s.has(ioc)) {
                        return;
                    }

                    s.add(ioc);
                    if (!getPattern(type).test(ioc)) {
                        Sentry.captureException(`${ioc} is not a valid ${type}`);
                        console.log(`${ioc} is not a valid ${type}`);
                        params.errors[`indicators.${index}.value`] = {
                            type: "manual",
                            message: `Insert some valid ${type} indicators`
                        };
                    } else {
                        params.indicators.push({
                            type: type,
                            tags: indicator.tags ? indicator.tags.map((tag) => tag.label) : [],
                            malware_families: indicator.malware_families ? indicator.malware_families.map((tag) => tag.label) : [],
                            botnets: indicator.botnets ? indicator.botnets.map((tag) => tag.label) : [],
                            campaigns: indicator.campaigns ? indicator.campaigns.map((tag) => tag.label) : [],
                            value: ioc,
                            dns0: type === 'domain' && (params.tlp < 1 || indicator.dns0),
                            confidence: ['md5', 'sha1', 'sha256'].includes(type) ? 100 : 0,
                            score: ['md5', 'sha1', 'sha256'].includes(type) ? 100 : 0
                        });
                    }
                });

            } else {
                indicator.value = indicator.value.trim()
                if (!getPattern(indicator.type.label).test(indicator.value)) {
                    Sentry.captureException(`${indicator.value} is not a valid ${indicator.type.label}`);
                    console.log(`${indicator.value} is not a valid ${indicator.type.label}`);
                    params.errors[`indicators.${index}.value`] = {
                        type: "manual",
                        message: `Insert a valid ${indicator.type.label} indicators`
                    };
                } else {
                    params.indicators.push({
                        type: indicator.type.label,
                        tags: indicator.tags ? indicator.tags.map((tag) => tag.label) : [],
                        malware_families: indicator.malware_families ? indicator.malware_families.map((tag) => tag.label) : [],
                        botnets: indicator.botnets ? indicator.botnets.map((tag) => tag.label) : [],
                        campaigns: indicator.campaigns ? indicator.campaigns.map((tag) => tag.label) : [],
                        value: indicator.value,
                        dns0: indicator.type.label === 'domain' && (params.tlp < 1 || indicator.dns0),
                        confidence: ['md5', 'sha1', 'sha256'].includes(indicator.type.label) ? 100 : 0,
                        score: ['md5', 'sha1', 'sha256'].includes(indicator.type.label) ? 100 : 0
                    });
                }
            }
        });

        const duplicatedIocValArray = _.keys(_.omitBy(
            _.countBy(params.indicators, (val) => `${val.value}${val.type}`),
            count => count === 1));

        _.forEach(params.indicators, (ioc, index) => {
            if (duplicatedIocValArray.includes(`${ioc.value}${ioc.type}`)) {
                Sentry.captureException(`${ioc.value}${ioc.type} has been inserted twice`);
                console.log(`${ioc.value}${ioc.type} has been inserted twice`);
                params.errors[`indicators.${index}.value`] = {
                    type: "manual",
                    message: `Some indicators have been inserted twice`
                };
            }
        });

    } else {
        params.indicators = [];
    }

    const FILE_RELATIONSHIP_KEYS = ['md5', 'sha1', 'sha256'];
    if (data.relationships?.length > 0) {
        params.file_relations = data.relationships.map((rel, index) => {
            if (rel.type.label === 'File') {
                const {md5, sha1, sha256, filenames} = rel;
                const relationship = {};
                if (md5 && md5.value !== 0) {
                    relationship.md5 = md5.label;
                }
                if (sha1 && sha1.value !== 0) {
                    relationship.sha1 = sha1.label;
                }
                if (sha256 && sha256.value !== 0) {
                    relationship.sha256 = sha256.label;
                }
                if (filenames && filenames.length > 0) {
                    relationship.filenames = filenames.map((f) => f.label);
                    _.forEach(relationship.filenames, (filename) => {
                        if (!params.indicators.find((indicator) => indicator.value === filename && indicator.type === 'filename')) {
                            params.indicators.push({type: 'filename', tags: [], value: filename})
                        }
                    });
                }
                if (Object.keys(relationship).length < 2) {
                    _.forEach(FILE_RELATIONSHIP_KEYS.concat(['filenames']), (key) => {
                        if (!relationship[key]) {
                            params.errors[`relationships.${index}.${key}`] = {type: "manual", message: ""}
                        }
                    });

                    params.errors[`relationships.${index}.form`] = {
                        type: "manual",
                        message: "You have to select at least 2 values between filename and hashes"
                    }
                }
                return relationship;
            }
        });

        if (params.file_relations.length > 0) {
            let counts = {};
            _.forEach(FILE_RELATIONSHIP_KEYS, (type) => {
                counts = {
                    ...counts, ...(_.omitBy(
                            _.omit(_.countBy(params.file_relations, (val) => val[type]), ['undefined']),
                            (count) => count === 1)
                    )
                }
            });

            _.forEach(counts, (count, duplicateIoc) => {
                _.forEach(data.relationships, (rel, index) => {
                    _.forEach(FILE_RELATIONSHIP_KEYS, (type) => {
                        if (duplicateIoc === rel[type].label) {
                            Sentry.captureException(`${rel[type].label} insert twice in the relationship`);
                            console.log(`${rel[type].label} insert twice in the relationship`);
                            params.errors[`relationships.${index}.${type}`] = {
                                type: "manual",
                                message: `Some indicators have been inserted twice inside the relationship`
                            };
                        }
                    })
                })
            });
        }
    } else {
        params.relationships = [];
    }

    const numberActors = data.actors.length;

    if (numberActors > 0 && !(numberActors === 1 && data.actors[0].label === 'Unknown')) {
        if (data.actors.filter((opt) => opt.label === 'Unknown').length > 0 && numberActors > 1) {
            params.errors['actors'] = {
                type: "manual",
                message: 'You cannot select Unknown actor with other well known.'
            };
        } else {
            params.actors = data.actors.map((opt) => opt.value);
            params.confidence = data.confidence.value;
        }
    } else {
        params.actors = [];
        params.confidence = -1;
    }

    if (data.tags) {
        params.tags = data.tags.map((item) => item.value);
    }

    if (data.malware_families) {
        params.malware_families = data.malware_families.map((item) => item.value);
    }

    if (data.botnets) {
        params.botnets = data.botnets.map((item) => item.value);
    }

    if (data.campaigns) {
        params.campaigns = data.campaigns.map((item) => item.value);
    }

    params.rules = data.rules ? data.rules.map((rule) => ({
        tags: rule.tags ? rule.tags.map((tag) => tag.label) : [],
        malware_families: rule.malware_families ? rule.malware_families.map((tag) => tag.label) : [],
        botnets: rule.botnets ? rule.botnets.map((tag) => tag.label) : [],
        campaigns: rule.campaigns ? rule.campaigns.map((tag) => tag.label) : [],
        type: rule.type.label,
        title: rule.title,
        text: rule.text,
        techniques: rule.techniques ? rule.techniques.map((technique) => technique.value) : []
    })) : [];

    return params;
}

export const getPostParamsWithValidation = (data) => {
    const params = getCollectionParamsWithValidation(data);
    delete params.description;

    params.text = data.description;
    params.notify = !!data.notify && !env_const.is_clone;
    params.is_weekly_report = !!data.is_weekly_report && !env_const.is_clone;

    params.references = [];

    _.forEach(data.references, (reference, index) => {
            if (!getPattern('url').test(reference.url)) {
                params.errors[`references.${index}.value`] = {
                    type: "manual",
                    message: `Insert a valid URL for this reference`
                }
            } else {
                params.references.push(reference.url)
            }
        }
    )

    if (data.sectors) {
        params.sectors = data.sectors.map((item) => item.value);
    }

    if (data.countries) {
        params.targeted_countries = data.countries.map((item) => item.iso_code);
    }

    return params;
}

export const getAnalysisParamsWithValidation = (data, ttpsSelected) => {
    const params = getPostParamsWithValidation(data);
    params.title = data.title;
    params.techniques = ttpsSelected;
    params.analysis_type = data.type && data.type.value && data.type.value;

    if (data.commands.length > 0) {
        params.commands = data.commands
        params.commands.forEach(command => {
            command.techniques = command?.techniques ? command.techniques.map(technique => technique.value) : []
            command.malware_families = command?.malware_families ? command.malware_families.map(malware_families => malware_families.value) : []
        })
    }

    return params;
}


export const getPostDefaultValue = (post, dataMITRE) => {
    const indicators = {};
    post.indicators = post.indicators.filter((val) => val.type !== 'filename');
    const indicatorsTags = _.uniqBy(_.flatten(post.indicators.map((indicator) => indicator.tags)), (tag) => tag.name);

    const indicatorsCommonTags = getCommonTags(indicatorsTags).map((tag) => tag.name);
    const indicatorsMalwareFamilyTags = getMalwareFamilyTags(indicatorsTags).map((tag) => tag.name);
    const indicatorsBotnetTags = getBotnetTags(indicatorsTags).map((tag) => tag.name);
    const indicatorsCampaignsTags = getCampaignTags(indicatorsTags).map((tag) => tag.name);

    _.forEach(post.indicators, (ioc) => {
        const commonTags = ioc.tags.map((tag) => tag.name);

        if (indicators[ioc.type] && indicators[ioc.type][commonTags]?.length > 0) {
            indicators[ioc.type][commonTags].push(ioc.value);
        } else if (indicators[ioc.type]) {
            indicators[ioc.type][commonTags] = [ioc.value];
        } else {
            indicators[ioc.type] = {};
            indicators[ioc.type][commonTags] = [ioc.value];
        }
    });

    const indicatorsForm = [];

    for (const [type, __] of Object.entries(indicators)) {
        for (const [tags, values] of Object.entries(__)) {
            if (values.length > 1) {
                indicatorsForm.push({
                    type: {value: 'Bulk ' + type, label: 'Bulk ' + type},
                    tags: indicatorsCommonTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    malware_families: indicatorsMalwareFamilyTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    botnets: indicatorsBotnetTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    campaigns: indicatorsCampaignsTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    value: _.join(values, '\n')
                })
            } else {
                indicatorsForm.push({
                    type: {value: type, label: type},
                    tags: indicatorsCommonTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    malware_families: indicatorsMalwareFamilyTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    botnets: indicatorsBotnetTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    campaigns: indicatorsCampaignsTags.filter((tag) => tags.split(',').includes(tag)).map((tag) => ({
                        value: tag,
                        label: tag,
                        isFixed: true
                    })),
                    value: values[0]
                })
            }
        }
    }

    const defaultValues = {
        tlp: {value: post.tlp.id, label: post.tlp.tlp},
        confidence: {value: post.confidence, label: confidenceLabels[post.confidence]},
        actors: post.actors.length > 0 ? post.actors.map((actor) => ({value: actor.uid, label: actor.name})) : [{
            value: 0,
            label: 'Unknown'
        }],
        targeted_countries: post.targeted_countries,
        sectors: post.sectors.map(
            (s) => ({
                value: s.uid || s,
                label: s.name
            })
        ),
        // relationships: post.relationships,
        references: post.references?.map((reference) => ({url: reference})),
        tags: post.tags.filter((tag) => getCommonTags(post.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
            value: tag.name,
            label: tag.name
        })),
        malware_families: post.tags.filter((tag) => getMalwareFamilyTags(post.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
            value: tag.name,
            label: tag.name
        })),
        botnets: post.tags.filter((tag) => getBotnetTags(post.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
            value: tag.name,
            label: tag.name
        })),
        campaigns: post.tags.filter((tag) => getCampaignTags(post.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
            value: tag.name,
            label: tag.name
        })),

        indicators: indicatorsForm,

        rules: post.rules.map((rule) => ({
            text: rule.text,
            title: rule.title,
            type: {value: rule.type, label: rule.type},
            tags: rule.tags.filter((tag) => getCommonTags(rule.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
                value: tag.name,
                label: tag.name
            })),
            malware_families: rule.tags.filter((tag) => getMalwareFamilyTags(rule.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
                value: tag.name,
                label: tag.name
            })),
            techniques: rule.techniques,
            // botnets: rule.tags.filter((tag) => getBotnetTags(rule.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({value: tag.name, label: tag.name})),
            // campaigns: rule.tags.filter((tag) => getCampaignTags(rule.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({value: tag.name, label: tag.name})),
        })),

        description: post.text,
        notify: false,
        created_dt: post.created_dt,

        is_weekly_report: post.is_weekly_report
    };

    console.log(post.is_weekly_report, post)

    defaultValues.rules.forEach((rule, index) => {
        rule.techniques.forEach((technique, index2) => {
            const match = flatten(dataMITRE?.data, 'techniques').find((tech) => tech.uid === technique)

            if (match) {
                defaultValues.rules[index].techniques[index2] = {
                    value: match.uid,
                    label: `${match.mitre_code} - ${match.name}`
                }
            }
        })
    })

    return defaultValues;
}

export const getFlashAlertDefaultValue = (data) => {
    const defaultValues = getCommonDefaultValue(data);
    defaultValues.actors = data.actors.length > 0 ? data.actors.map((actor) => ({
        value: actor.uid,
        label: actor.name
    })) : [{value: 0, label: 'Unknown'}];
    defaultValues.targeted_countries = data?.targeted_countries;
    defaultValues.sectors = data?.sectors ? data.sectors.map(
        (s) => ({
            value: s.uid,
            label: s.name
        })
    ) : [];
    defaultValues.attachments = data?.attachments;
    defaultValues.report = data?.report_filename;
    defaultValues.report_id = data?.report_id;

    return defaultValues;
}

export const getUserDefaultValue = (data) => {
    return {
        display_name: data?.display_name,
        email: data?.email,
        role: {label: data?.role, value: data?.role},
        organization: {value: data?.organization?.uid, label: data?.organization?.name},
        tlp: {value: data?.tlp.id, label: data?.tlp.value},
        private_tlp: {value: data?.private_tlp.id, label: data?.private_tlp.value},
        modules: data?.modules,
        active: data?.active
    };
}

export const getApplicationDefaultValues = (data) => {
    return {
        name: data?.name,
        tlp: {value: data?.tlp.id, label: data?.tlp.value},
        private_tlp: {value: data?.private_tlp.id, label: data?.private_tlp.value},
        modules: data?.modules,
        is_demo: data?.is_demo,
        audience: data?.audience
    };
}

export const getCommonDefaultValue = (data) => {
    return {
        tlp: {value: data.tlp.id, label: data.tlp.tlp},
        tags: data.tags.filter((tag) => getCommonTags(data.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
            value: tag.name,
            label: tag.name
        })),
        malware_families: data.tags.filter((tag) => getMalwareFamilyTags(data.tags).map((t) => t.name).includes(tag.name)).map((tag) => ({
            value: tag.name,
            label: tag.name
        })),
        description: data.text,
        notify: data.notify,
        title: data.title,
        created_dt: data.created_dt
    };
}

export const getAnalysisDefaultValue = (analysis, dataMITRE) => {
    const defaultValues = getPostDefaultValue(analysis, dataMITRE);
    defaultValues.title = analysis.title;
    defaultValues.report = analysis.report_filename;
    defaultValues.report_id = analysis.report_id;
    defaultValues.techniques = analysis.techniques;
    defaultValues.analysis_type = analysis.analysis_type;
    defaultValues.attachments = analysis.attachments;
    defaultValues.commands = analysis.commands;
    defaultValues.commands.forEach((command, index) => {
        command.techniques.forEach((technique, index2) => {
            const match = flatten(dataMITRE?.data, 'techniques').find((tech) => tech.uid === technique)
            if (match) defaultValues.commands[index].techniques[index2] = {
                value: match.uid,
                label: `${match.mitre_code} - ${match.name}`
            }
        })
        command.malware_families.forEach((malware_family) => {
            malware_family.label = malware_family.name
            malware_family.value = malware_family.name
        })
    })

    return defaultValues;
}


export const getArrDateFromContexts = (contexts) => {
    const tmpDateObj = {};
    _.forEach(contexts, (ctx) => {
        if (!tmpDateObj[ctx.created_dt]) {
            tmpDateObj[ctx.created_dt] = 1;
        } else {
            tmpDateObj[ctx.created_dt] += 1;
        }
    })

    const arrDate = [];
    _.forEach(tmpDateObj, (value, created_dt) => {
        arrDate.push({created_dt: created_dt, hits: value})
    });

    return arrDate;
}


export const actorsToString = (actors) =>
    actors.length === 0 ? 'Unknown' : _.join(actors.map((actor) => actor.name), ', ')


export const getTagOptionsByTypes = (tags) => {
    const commonTags = getCommonTags(tags);
    const malwareFamilyTags = getMalwareFamilyTags(tags);
    const botnetTags = getBotnetTags(tags);
    const campaignTags = getCampaignTags(tags);


    const commonTagOptions = commonTags.map((tag) => ({value: tag.name, label: tag.name}));
    const malwareFamilyTagOptions = malwareFamilyTags.map((tag) => ({value: tag.name, label: tag.name}));
    const botnetTagOptions = botnetTags.map((tag) => ({value: tag.name, label: tag.name}));
    const campaignTagOptions = campaignTags.map((tag) => ({value: tag.name, label: tag.name}));

    return [commonTagOptions, malwareFamilyTagOptions, botnetTagOptions, campaignTagOptions];
}

export const AnalysisTypes = [
    {
        label: 'TAR',
        value: 'tar'
    },
    {
        label: 'TIR',
        value: 'tir'
    }
];

export const AnalysisArrayTypes = [
    {
        name: 'flashalert',
        value: 'flash_alert'
    }
];

export const getFormattedAnalysisName = (type) => {
    let val = type.split('_').join(' ');
    if (type === 'flash_alert' || type === 'flashalert') {
        val = 'Flash Threat Activity'
    }
    return val;
}

export const getAnalysisNameByValue = (type) => {
    return AnalysisArrayTypes.find((t) => t.value === type)?.name;
}
