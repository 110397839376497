import {Col, Row} from "reactstrap";
import MenuEntry from "../../../../shared/components/MenuEntry";
import {bytesToSizeOrNA, getElementPropertyOrDefault} from "../../../../shared/helpers";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import DownloadFileIcon from "../../../../shared/components/icons/DownloadFileIcon";
import {apiUrlInternal} from "../../../../config/api";


const IntelxNotificationContent = ({intelx_result}) => {
  return (
    <Row>
      <Col md={4}>
        <div className="custom_card__container">
          <MenuEntry title={'DATE'} value={intelx_result.date ? formatDateUTCtoYearMonthDayTime(intelx_result.date) : 'N/A'} />
          <MenuEntry title={'SIZE'} value={bytesToSizeOrNA(intelx_result.size)} />
          <MenuEntry
            title={'CATEGORY'}
            value={_.toUpper(_.split(intelx_result.bucket, '.')[0])}
          />
        </div>
      </Col>
      <Col md={7}>
        <div className="custom_card__container">
          {
            intelx_result.url ?
              <MenuEntry
                title={'URL'}
                value={getElementPropertyOrDefault(intelx_result, 'url', 'N/A')}
                valueStyle={{wordBreak: 'break-word', whiteSpace: 'normal'}}
              /> :
              <MenuEntry
                title={'FILE NAME'}
                value={getElementPropertyOrDefault(intelx_result, 'name', 'N/A')}
                valueStyle={{wordBreak: 'break-word', whiteSpace: 'normal'}}
              />
          }
          <MenuEntry title={'FILE TYPE'} value={getElementPropertyOrDefault(intelx_result, 'mediah', 'N/A')} />
        </div>
      </Col>
      <Col md={1}>
        {
          intelx_result.instore &&
            <DownloadFileIcon
              url={`${apiUrlInternal}/intel/download?uuid=${intelx_result.systemid}&source=${intelx_result.bucket}`}
              filename={intelx_result.systemid}
              style={{marginRight: '20px', float: 'right'}}
            />
        }
      </Col>
    </Row>
  )
}


IntelxNotificationContent.propTypes = {
  intelx_result: PropTypes.object.isRequired
}


export default IntelxNotificationContent;
