import React, {useEffect} from "react";
import {Col, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import {useQueryClient} from "react-query";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import DeleteIconModal from "../../modal/DeleteIconModal";
import paths, {editActivitiesPath} from "../../../../config/paths";
import {useDeleteContextTask} from "../../../../mutations/DeleteContext";


const ActivityEditsBar = ({activity}) => {
  const mutation = useDeleteContextTask();
  const h = useHistory();
  const queryClient = useQueryClient();
  const {uid, category, title} = activity

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast.loading('Deleting activity');
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          queryClient.invalidateQueries('activities', {refetchActive: true, refetchInactive: true});
          toast.success('Activity deleted successfully');
          h.push(paths.activitiesPath);
        } else if (mutation.isError) {
          toast.error('Error during the deletion of the activity');
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])


  return (
    <Row>
      <Col md={12} className={'d-flex justify-content-end mt-2'}>
        <span>
          <PencilOutlineIcon size={24} style={{fill: '#ffffff'}} onClick={(e) => {
            e.stopPropagation();
            h.push(`${editActivitiesPath.replace(':uid', uid)}`);
          }} className={'border-hover-white mr-3'}/>
          <DeleteIconModal
            message={`Do you really want to delete the ${category} "${title}"?`}
            onDelete={() => mutation.mutate(uid)}
          />
        </span>
      </Col>
    </Row>
  )
}


ActivityEditsBar.propTypes = {
  activity: PropTypes.shape().isRequired,
  toggle:PropTypes.func,
  showExpand:PropTypes.bool,
}

export default ActivityEditsBar;
