import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSandboxTaskDetailsQuery} from "../../../../queries/Sandbox/SandboxTaskDetails";
import SmallGaugeChart from "../SmallGaugeChart";
import {Card, CardBody} from "reactstrap";


const _ = require('lodash')


const TaskScoreSmallGaugeChart = ({taskId}) => {
  const taskDetailQuery = useSandboxTaskDetailsQuery(taskId);

  const getIndexFromRiskScore = (pRiskScore) => _.ceil(pRiskScore / 2.5) - 1;
  const getRiskScoreColor = (pRiskScore) => riskColors[getIndexFromRiskScore(pRiskScore)];
  const riskColors = ["#4caf50", "#ffd600", "#ff5722", "#d50000"];

  const riskScore = useMemo(() => (
      (taskDetailQuery.data && taskDetailQuery.data.data.score && !taskDetailQuery.isError)
        ? taskDetailQuery.data.data.score : 0
    ),
    [taskDetailQuery.data.data.score, taskDetailQuery.isError]
  );


  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">SCORE</h5>
        </div>

        <SmallGaugeChart
          value={riskScore * 10}
          isError={taskDetailQuery.isError}
          isLoading={taskDetailQuery.isLoading || taskDetailQuery.isIdle}
          label={`${riskScore.toFixed(2)}`}
          color={getRiskScoreColor(riskScore)}
        />
      </CardBody>
    </Card>
  )
}

TaskScoreSmallGaugeChart.propTypes = {
  taskId: PropTypes.string,
  to: PropTypes.string,
};

export default TaskScoreSmallGaugeChart;
