import {Button, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import Select from "react-select";
import React, {useState} from "react";
import PropTypes from "prop-types";


const _ = require('lodash');


const FiltersMenu = ({
  filterMenu,
  currentFilters,
  setCurrentFilters
}) => {
  const [fieldFilterIndex, setFieldFilterIndex] = useState(-1);
  const [valueFilterIndex, setValueFilterIndex] = useState(-1);

  const addFilter = () => {
    const filterSelected = filterMenu[fieldFilterIndex];
    const filters = filterMenu[fieldFilterIndex].multi ? currentFilters.slice() :
      currentFilters.filter((filter) => filter.field !== filterSelected.field);

    filters.push({
      displayField: filterSelected.displayField,
      field: filterSelected.field,
      filter_field: filterSelected.filter_field,
      value: filterSelected.values[valueFilterIndex],
      displayValue: filterSelected.displayValueFn(filterSelected.values[valueFilterIndex]),
      multi: filterSelected.multi,
      filterPredicate: filterSelected.filterPredicate
    })

    setCurrentFilters(filters);
    setFieldFilterIndex(-1);
    setValueFilterIndex(-1);
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="icon icon--right outline dropdown__toggle"
        outline
        color={'success'}
      >
        <p className={'justify-content-between'}>Filter <ChevronDownIcon/></p>
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu__custom">
        <Select
          className={"react-select mt-3"}
          classNamePrefix="react-select"
          onChange={(item) => {
            setFieldFilterIndex(item.value);
            setValueFilterIndex(0);
          }}
          options={filterMenu.map((key, index) => ({label: key.displayField, value: index}))}
          value={
            fieldFilterIndex === -1 ?
              {label: 'Select field', value: -1} :
              {label: filterMenu[fieldFilterIndex].displayField, value: fieldFilterIndex}
          }
        />
        <Select
          className={"react-select mt-3"}
          classNamePrefix="react-select"
          onChange={(item) => setValueFilterIndex(item.value)}
          options={
            fieldFilterIndex === -1 ? [] :
              filterMenu[fieldFilterIndex].values.map((val, index) => ({
                label: filterMenu[fieldFilterIndex].displayValueFn(val),
                value: index
              }))
          }
          value={
            valueFilterIndex === -1 || fieldFilterIndex === -1 ?
              {label: 'Select value', value: -1} :
              {
                label: filterMenu[fieldFilterIndex].displayValueFn(filterMenu[fieldFilterIndex].values[valueFilterIndex]),
                value: valueFilterIndex
              }
          }
          isDisabled={fieldFilterIndex === -1}
        />
        <Button outline color={'success'} className={'w-100 mt-3 mb-0'} onClick={addFilter} disabled={
          fieldFilterIndex === -1 || valueFilterIndex === -1 || _.filter(currentFilters, (filter) =>
            filter.field === filterMenu[fieldFilterIndex].field && filter.value === filterMenu[fieldFilterIndex].values[valueFilterIndex]
          ).length > 0
        }>Add</Button>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}


FiltersMenu.propTypes = {
  filterMenu: PropTypes.array.isRequired,
  currentFilters: PropTypes.array.isRequired,
  setCurrentFilters: PropTypes.func.isRequired,
}


export default FiltersMenu;