import React, {Fragment, useCallback, useState} from "react";
import _ from "lodash";
import {Badge, Col, Row, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ExpandVerticalIcon from "mdi-react/ExpandVerticalIcon";
import paths from "../../../config/paths";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import {ColorTagInlineBadgeList} from "../../../shared/components/badge/BadgeLists";
import {formatDateUTCtoYearMonthDay} from "../../../shared/helpers/date";
import TLPBadge from "../../../shared/components/badge/TLPBadge";
import {getTlpColor} from "../../../shared/helpers/tlp";
import ContentEditsBar from "../../../shared/components/card/accessories/ContentEditsBar";
import ActivityEditsBar from "../../../shared/components/card/accessories/ActivityEditsBar";


const ActivityHeader = ({
    activity, isLoadingIdle, toggle, isEditable, updateFilters = () => {}
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = useCallback(() => setTooltipOpen(!tooltipOpen), [setTooltipOpen, tooltipOpen]);

    return (
        <div className={'card-header__events no_cursor'}>
            <Row>
                <Col md={11}>
                    <Row>
                        <Col md={12}>
                            <div className={"card__title__events "}>
                                <h4 className={'bold-text'}>
                                    {activity.category}:&nbsp;{activity.title}
                                </h4>
                                <p className={'bold-text pt-1 pb-1'}>{activity.text}</p>
                                {
                                    isLoadingIdle ?
                                        <LoadingSpinner/> :
                                        <Fragment>
                                            <h5 className="subhead__events">Targeted sectors</h5>
                                            {
                                                activity.sectors?.length > 0 ?
                                                    <ColorTagInlineBadgeList
                                                        onTagClick={updateFilters}
                                                        items={activity.sectors}
                                                        color={'dark-blue'}
                                                    /> :
                                                    <h5>NO TARGETED SECTORS AVAILABLE</h5>
                                            }
                                        </Fragment>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            {
                                activity.created_dt &&
                                <p className={'subhead'}>
                                    Published on&nbsp;<b>{formatDateUTCtoYearMonthDay(activity.created_dt)}</b>
                                </p>
                            }
                            {
                                activity?.tlp?.tlp &&
                                <span className="cursor" onClick={() => updateFilters(_.upperCase(activity.tlp.tlp))}>
                                    <TLPBadge tlp={activity.tlp.tlp}/>
                                </span>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col md={1} className={'d-flex flex-column justify-content-center'}>
                    {
                        activity.report_id &&
                        <span className={'badge--event-has-rule'}>
                            <Badge
                                color={activity?.tlp?.tlp ? getTlpColor(activity.tlp.tlp) : 'danger'}
                                className={'w-100 outline badge-sm'}
                            >
                            REPORT
                            </Badge>
                        </span>
                    }
                    <Link style={{marginTop: '40px'}} to={`${paths.activitiesPath}/${activity.uid}`}>
                        <ChevronRightIcon
                            className={'float-right'}
                            size={32}
                            style={{fill: '#ffffff'}}
                            id={`content-${activity.uid}`}
                        />
                    </Link>
                    <Tooltip
                        placement="right"
                        isOpen={tooltipOpen}
                        target={`content-${activity.uid}`}
                        toggle={toggleTooltip}
                    >Go to the activity details</Tooltip>
                </Col>
            </Row>

            <Row>
                <Col md={12} className={'d-flex justify-content-end'}>
                    <ExpandVerticalIcon
                        size={24}
                        style={{fill: '#ffffff'}}
                        className={'border-hover-white'}
                        onClick={toggle}
                    />
                </Col>
            </Row>


            {isEditable &&
                <ActivityEditsBar
                toggle={toggle}
                activity={activity}
                />
            }
        </div>
    )
}

ActivityHeader.propTypes = {
    activity: PropTypes.shape().isRequired,
    isLoadingIdle: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    updateFilters: PropTypes.func,
}

export default ActivityHeader;
