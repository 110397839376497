import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import WhoisDetailsIP from "./WhoisDetailsIP";
import VirusTotalIndicatorLink from "../../../../shared/components/icons/VirusTotalIndicatorLink";
import CopyIconToClipboard from "../../../../shared/components/icons/CopyIconToClipboard";
import is_ip_private from "private-ip";


const WhoisDetailsCard = ({whois, indicator, indicatorType}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <div className="card__title">
        <h4 className="bold-text">{indicator}
          <span className={'float-right'}><CopyIconToClipboard value={indicator}/>
            {(indicatorType === 'domain' || (indicatorType === 'ipv4' && !is_ip_private(indicator))) &&
              <VirusTotalIndicatorLink value={indicator}/>}
            </span>
        </h4>
        <p className="subhead">WHOIS</p>
      </div>

      <WhoisDetailsIP whois={whois}/>
    </CardBody>
  </Card>


WhoisDetailsCard.propTypes = {
  whois: PropTypes.object.isRequired,
  indicator: PropTypes.string.isRequired,
  indicatorType: PropTypes.string.isRequired
}


export default WhoisDetailsCard;
