import {Progress} from "reactstrap";
import {getTicketEvents} from "../helper";
import {getTicketStatus, isTakeDownTicketClosed} from "../../TakeDownService/helper";
import React from "react";
import PropTypes from "prop-types";


const TakedownProgressBar = ({ticketDetail}) => {
  const classes = isTakeDownTicketClosed(ticketDetail.status) ?
      'progress-bar' :
      'progress-bar progress-bar-animated progress-bar-striped';

  return (
    <div className="progress-wrap progress-wrap--big">
      <Progress
        className={classes}
        value={getTicketEvents(ticketDetail, 'percentage')}
      >
        {getTicketStatus(ticketDetail.status)}
      </Progress>
    </div>
    )
}


TakedownProgressBar.propTypes = {
  ticketDetail: PropTypes.object
}


export default TakedownProgressBar;