import React, {Component, Fragment, useCallback, useState} from 'react';
import DetailsModal from "./components/DetailsModal";

export const WithDetailsModal = (Component) => {
    function ComponentWithDetailsModal ({isOpen, setIsDetailOpen, title,TableDetailsComponent, lightModal = false,...otherProps}) {
        const [ selectedItem, setSelectedItem ] = useState({});
        const onRowClick = useCallback((dataId, row) => {
            setSelectedItem(row.original)
            setIsDetailOpen(true);
        }, [])

        return (
            <Fragment>
                <DetailsModal showModal={isOpen} toggleModal={setIsDetailOpen} item={selectedItem} title={title} TableDetailsComponent={TableDetailsComponent} lightModal={lightModal}/>
                <Component
                    onRowClick={onRowClick}
                    {...otherProps}
                />
            </Fragment>
        );
    }

    ComponentWithDetailsModal.propTypes = {
        ...Component.propTypes,
    }

    return ComponentWithDetailsModal;
}
