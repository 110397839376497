import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Collapse from "../../../shared/components/Collapse";
import NotificationHeader from "./NotificationHeader";
import NotificationBody from "./NotificationBody";
import {useSetNotificationRead} from "../../../mutations/SetNotificationRead";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import {useQueryClient} from "react-query";


const NotificationItem = ({notification}) => {
  const [read, setRead] = useState(notification.read);
  const mutation = useSetNotificationRead();
  const queryClient = useQueryClient();


  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        queryClient.invalidateQueries(['notifications', `user/${notification.user_uid}`, null]);
        queryClient.invalidateQueries(['notifications', `user/${notification.user_uid}/unread`, null]);
        queryClient.invalidateQueries(['notifications', `user/${notification.user_uid}/unread/summary`, null]);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setRead(false);
        notification.read = false;
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])


  return (
    <Collapse
      title={<NotificationHeader notification={notification} />}
      className={"with-shadow"}
      onEnteringFn={() => {
        if (!read && !mutation.isLoading) {
          mutation.mutate({
            user_uid: notification.user_uid,
            not_uid: notification.uid,
          });
          setRead(true);
          notification.read = true;
        }
      }}
    >
      <NotificationBody notification={notification} />
    </Collapse>
  )
}


NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
}


export default NotificationItem;
