import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import ResourceMonitorResultCollapse from "./ResourceMonitorResultCollapse";
import _ from "lodash";
import {getBaseScore, CVE_RISK_COLORS} from "../../../shared/helpers/cve";


const ResourceMonitorResultCard = ({vulns, resource_monitor}) => {

  const cveRiskCounter = {}

  _.forEach(vulns, (vuln) => {
    const cve_info = vuln.nvd_info;
    const [, , baseSeverity] = getBaseScore(cve_info.impact);

    if (_.isNull(cveRiskCounter[baseSeverity]) || _.isUndefined(cveRiskCounter[baseSeverity])) {
      cveRiskCounter[baseSeverity] = 1;
    } else {
      cveRiskCounter[baseSeverity] += 1;
    }
  })

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text d-inline">{vulns.length} vulnerabilities found on <b>{resource_monitor}</b> (host: <b>{vulns[0].host}</b>)</h4>
          {cveRiskCounter["LOW"] && <span className={'mx-2 float-right'} style={{color: CVE_RISK_COLORS['low']}}>LOW RISK: {cveRiskCounter["LOW"]} </span>}
          {cveRiskCounter["MEDIUM"] && <span className={'mx-2 float-right'} style={{color: CVE_RISK_COLORS['medium']}}>MEDIUM RISK: {cveRiskCounter["MEDIUM"]} </span>}
          {cveRiskCounter["HIGH"] && <span className={'mx-2 float-right'} style={{color: CVE_RISK_COLORS['high']}}>HIGH RISK: {cveRiskCounter["HIGH"]} </span>}
          {cveRiskCounter["CRITICAL"] && <span className={'mx-2 float-right'} style={{color: CVE_RISK_COLORS['critical']}}>CRITICAL RISK: {cveRiskCounter["CRITICAL"]} </span>}
        </div>

        {
          _.orderBy(
            vulns,
            (val) => {
              const [baseScore, ,] = getBaseScore(val.nvd_info.impact);
              return baseScore;
            },
            'desc'
          ).map((vuln) =>
            <ResourceMonitorResultCollapse vuln={vuln}/>
          )
        }

      </CardBody>
    </Card>
  )
}



ResourceMonitorResultCard.propTypes = {
  resource_monitor: PropTypes.string.isRequired,
  vulns: PropTypes.arrayOf(PropTypes.object).isRequired
}


export default ResourceMonitorResultCard;
