import {Card, CardBody} from "reactstrap";
import SmallGaugeChart from "../../../../shared/components/charts/SmallGaugeChart";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {getColorByScore, getRiskLabelByScoreWithCritical} from "../../../../shared/helpers/risk_score";


const ResourceMonitorRiskGauge = ({
  totalHosts,
  hostCves
}) => {
  let score = 50;

  if (totalHosts !== 0 && _.sum(_.values(hostCves).map(v => v.size)) !== 0) {
    score += 1 * hostCves.low.size / totalHosts * 10;
    score += 2 * hostCves.medium.size / totalHosts * 10;
    score += 3 * hostCves.high.size / totalHosts * 10;
    score += 4 * hostCves.critical.size / totalHosts * 10;

    if (hostCves.critical.size >= totalHosts / 2) {
      score = 100;
    } else if (hostCves.high.size >= totalHosts / 2 && score < 90) {
      score = 90;
    }

    score = _.min([score, 100]);
  }


  return (
    <Card className={'h-auto resource_monitor-gauge-card'}>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">Risk score</h4>
          <p className="subhead">Risk score is based on CVE risks and number of hosts vulnerable</p>
        </div>
        <SmallGaugeChart
          value={score}
          color={getColorByScore(score)}
          label={getRiskLabelByScoreWithCritical(score)}
        />
      </CardBody>
    </Card>
  )
}

ResourceMonitorRiskGauge.propTypes = {
  totalHosts: PropTypes.number.isRequired,
  hostCves: PropTypes.object.isRequired
}


export default ResourceMonitorRiskGauge;
