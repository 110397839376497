import React from "react";
import {Card, CardBody} from "reactstrap";
import LoadingSpinner from "../LoadingSpinner";
import Slider from "react-slick";
import PropTypes from "prop-types";
import {ReactComponent as Arrow} from "../../img/carousel/arrow.svg";



function NextSlide(props) {
  const { onClick } = props;
  return (
    <Card className={'card--arrow-carousel-dx'} onClick={onClick}>
      <CardBody><Arrow /></CardBody>
    </Card>
  )

}

function PrevSlide(props) {
  const { onClick } = props;
  return (
    <Card className={'card--arrow-carousel-sx'} onClick={onClick}>
      <CardBody><Arrow /></CardBody>
    </Card>
  )
}


const CarouselWithData = ({
  contentsArray,
  isIdle,
  isLoading,
  isError,
  CarouselElement
}) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextSlide />,
    prevArrow: <PrevSlide />,
    responsive: [
      {
        breakpoint: 1690,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (isError) {
    return <Card><CardBody><h4>Unable to load the content</h4></CardBody></Card>;
  }

  if (isIdle || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Slider xl={12} lg={10} md={12} xs={12} {...settings}>
      {contentsArray.map((i, index) => (
        <CarouselElement
          content={i}
          key={index}
        />
      ))}
    </Slider>
  )
}


CarouselWithData.propTypes = {
  contentsArray: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  CarouselElement: PropTypes.func.isRequired,
}

export default CarouselWithData;
