import { useAssociatedMalwareQuery } from "./AssociatedMalwares";
import {useCountriesQuery} from "./Countries";
import { useMotivationsQuery } from "./Motivations";
import {useSectorsListQuery} from "./SectorsList";

export const useAddActorFormQuery = () => {
  return {
    'countries': useCountriesQuery(),
    'sectors': useSectorsListQuery(),
    'motivations': useMotivationsQuery(),
    'associateMalwares': useAssociatedMalwareQuery(),
  }
}
